import { Component } from "react";
import { Modal } from "react-bootstrap";
import RegisterBrandModal from "./RegisterBrandModal";
import vendorService from "../../services/vendor.service";
import { firebaseDate, vendorId } from "../../utils/common.utils";
import { toast } from "material-react-toastify";
import { withTranslation } from "react-i18next";


class BrandDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registerBrandModal: false,
      brands: [],
    };
    this.nextYearDate = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );
    this.vendorId = vendorId();
  }
  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    this.props.loaderChange(true);
    vendorService
      .getBrands(this.vendorId)
      .then((res) => {
        console.log(res.data());
        let brands = res.data()?.brands ? res.data()?.brands : [];
        this.setState({ brands: brands, registerBrandModal: false });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ registerBrandModal: false });
        this.props.loaderChange(false);
        const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  registerBrand = (brand) => {
    let coupons = this.state.brands;
    coupons.push(brand);
    let data = {
      brands: coupons,
    };
    brand.claimed = true;
    brand.claimedBy = this.vendorId;
    vendorService
      .registerBrand(this.vendorId, data)
      .then((res) => {
        console.log(res);
        this.updateICnoupon(brand, brand.coupon);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ registerBrandModal: false });
        const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  updateICnoupon = (data, couponId) => {
    vendorService
      .updateCoupon(data, couponId)
      .then((res) => {
        console.log(res);
        this.getData();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ registerBrandModal: false });
        const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  checkDate = (date) => {
    const validDate = date.toDate();
    return validDate > this.nextYearDate;
  };
  render() {
    const { t } = this.props;
    return (
      <div className="container">
        <section>
          <div className="my-5">
            <div className="row">
              <div className="col-12">
                <h4>Brand Details</h4>
              </div>
              <div className="col-12">
                <button
                  className="btn btn-outline-orange float-end rounded-pill mt-4"
                  onClick={() => {
                    this.setState({ registerBrandModal: true });
                  }}
                >
                  {t("registerAsBrand")}
                </button>
              </div>
            </div>
          </div>
          <div className="">
            <div className="table-responsive">
              {this.state.brands?.length ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">
                        <p className="max-width-content mb-0">{t("nameOfTheBrand")}</p>
                      </th>
                      <th scope="col">
                        <p className="max-width-content mb-0">{t("registerDateTime")}
                        </p>
                      </th>
                      <th scope="col">
                        <p className="max-width-content mb-0">{t("validTill")}</p>
                      </th>
                      <th scope="col">
                        <p className="max-width-content mb-0">{t("status")}</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.brands.map((el, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">{i + 1}</th>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.company}
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {firebaseDate(el?.listedDate)}
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {firebaseDate(el?.validTill)}
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.delivered
                                ? "Expired"
                                : this.checkDate(el?.validTill)
                                ? "in Queue"
                                : "Active"}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div>Brand not register yet</div>
              )}
            </div>
          </div>
        </section>

        <Modal
          centered
          show={this.state.registerBrandModal}
          dialogClassName="register_brand-modal"
        >
          <Modal.Body>
            <RegisterBrandModal
              vendorId={this.vendorId}
              vendorService={vendorService}
              register={this.registerBrand}
              close={() => {
                this.setState({ registerBrandModal: false });
              }}
            ></RegisterBrandModal>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(BrandDetails);
