import { Component } from "react";
import { IoEllipsisVertical } from "react-icons/io5";
import vendorService from "../../services/vendor.service";
import { userProfile, vendorId, withRouter } from "../../utils/common.utils";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import DefaultImage from "../../images/no_image.jpg";

class ChatList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatList: [],
    };
    this.userId = vendorId();
    this.currentUser = {
      image: userProfile().image,
      name: userProfile().name,
      uid: userProfile().uid,
    };
  }
  componentDidMount() {
    this.getChatList();
    // const currentUser = {
    //     image: userProfile()?.image,
    //     name: userProfile()?.name,
    //     uid: userProfile()?.uid,
    // }
    // let collectionRef = query(collection(db, "chat"), where("users", "array-contains", currentUser));
    // const q = query(collectionRef, orderBy("createdAt"));
    // onSnapshot(q, (querySnapshot) => {
    //     // querySnapshot.forEach((doc) => {
    //     //     let temp = doc.data()
    //     //     temp.id = doc.id
    //     //     data.push(temp)
    //     // });
    //     this.setState({ chatList: querySnapshot.docs.map(doc => doc.data()) })
    // });
  }
  getChatList = () => {
    this.props.loaderChange(true);
    vendorService
      .getChatList(this.userId)
      .then((res) => {
        this.setState({ chatList: res });
        console.log("ChatList - ", res);
        this.props.loaderChange(false);
      })
      .catch((error) => {
        console.log(error.message + " - " + error.code);
        const errorCode = error.code;
        const errorMessage = error.message;
        this.props.loaderChange(false);
        // ..
      });
  };
  toChat = (i, uids) => {
    let vendor = "";
    for (let el of uids) {
      if (el.uid !== this.userId) vendor = el;
    }
    this.props.router.navigate(`/vendor/chat/${i}/${vendor.uid}`);
  };
  getImg = (users) => {
    let result = "";
    for (let el of users) {
      if (el.uid !== this.currentUser.uid) {
        return el.image;
      }
    }
    return "";
  };
  getName = (users) => {
    let result = "";
    for (let el of users) {
      if (el.uid !== this.currentUser.uid) {
        return el.name;
      }
    }
    return "";
  };
  getUnreadCount = (users) => {
    let result = "";
    for (let el of users) {
      if (el.uid !== this.currentUser.uid) {
        return el.unReadCount;
      }
    }
    return "";
  };
  render() {
    return (
      <section className="main-user-chat h-100" style={{ textAlign: "left" }}>
        <div className="user-chat-list">
          <div className="chat-wrapper chat-user-list h-100">
            {/* <div className="chat-title">
                                Live Chat
                            </div> */}
            <div className="chat-view h-100">
              {this.state.chatList.map((el, i) => {
                return (
                  <div
                    key={i}
                    className="chat-item chat-border-bottom cursor-pointer"
                    onClick={() => {
                      this.toChat(el.id, el.users);
                    }}
                  >
                    <div className="container">
                      <div className="d-flex">
                        <img src={this.getImg(el.users)?this.getImg(el.users):DefaultImage} alt="" />
                        <div className="d-flex w-100">
                          <div className="d-flex align-items-center flex-grow-1">
                            <div className="d-flex sender-name ps-3 flex-grow-1">
                              <a
                                href="#"
                                className="text-decoration-none flex-grow-1"
                              >
                                {" "}
                                {this.getName(el.users)}
                              </a>
                              {this.getUnreadCount(el.users) ? (
                                <p className="text-white">
                                  {this.getUnreadCount(el.users)}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          {/* <div className="float-right">
                                                                <button type="button" className="dropdown-btn btn-tr p-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <IoEllipsisVertical />
                                                                </button>
                                                                <div className="dropdown-menu">
                                                                    <a className="dropdown-item" href="#">Report</a>
                                                                    <a className="dropdown-item" href="#">Block</a>
                                                                </div>
                                                            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default withRouter(ChatList);
