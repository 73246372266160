import { Component } from "react";
import { FcInfo } from "react-icons/fc";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import vendorService from "../../services/vendor.service";
import { userProfile, vendorId, withRouter } from "../../utils/common.utils";
import commonService from "../../services/common.service";
import { AiOutlineCloseCircle, AiOutlineUpload } from "react-icons/ai";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import DefaultImage from "../../images/no_image.jpg";
import ItalyCities from "../../file/italy_cities.csv";
import Papa from "papaparse";
import { withTranslation } from "react-i18next";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      profileData: {},
      isProfile: false,
      isAvailable: null,
      otherImages: [],
      cities: [],
      provinces: [],
      isLoader: false,
      selectedOption: null,
      selectedProvinceOption: null,
      servicesList: [],
      latitude: 0,
      longitude: 0,
      workingDays: {},
      showWorkTime: false,
      endTime: "",
      startTime: "",
      selectedDay: "",
      canEditCountry: true,
    };
    this.userId = vendorId();
  }
  componentDidMount() {
    if (!userProfile().emailVerified) {
      console.log("called - ");
      this.props.router.navigate(`/vendor/verify-email`);
    } else {
      this.getProfile(this.userId, this.updateState);
    }
  }
  updateState = (updateValues) => {
    this.setState(updateValues);
  };
  getProfile = (uid, updateState) => {
    this.props.loaderChange(true);
    let getWorkingObj = this.getWorkingObj;
    vendorService
      .getSingleProfile(uid)
      .then((userCredential) => {
        let cityObj = [];
        let province = [];
        Papa.parse(ItalyCities, {
          header: true,
          download: true,
          complete: function (results) {
            var provinceList = results.data.map((e) => e.Column1);
            provinceList = Array.from(new Set(provinceList));
            provinceList.forEach((e) => {
              let temp = {
                value: e,
                label: e,
              };
              province.push(temp);
            });
            let selectedValues = null;
            let longitude = 0;
            let latitude = 0;
            let selectedProvinceValues = null;
            if (userCredential.data()?.city) {
              let temp = {
                value: userCredential.data()?.city,
                label: userCredential.data()?.city,
              };
              selectedValues = temp;
            }
            if (userCredential.data()?.province) {
              let temp = {
                value: userCredential.data()?.province,
                label: userCredential.data()?.province,
              };
              selectedProvinceValues = temp;
            }
            if (userCredential.data()?.latitude) {
              latitude = userCredential.data()?.latitude;
            }
            if (userCredential.data()?.longitude) {
              longitude = userCredential.data()?.longitude;
            }
            var isAvailable =
              userCredential.data()?.isAvailable != null
                ? userCredential.data()?.isAvailable
                : true;
            getWorkingObj(
              userCredential.data()?.workingDays
                ? userCredential.data()?.workingDays
                : []
            );
            updateState({
              csvData: results.data,
              latitude: latitude,
              longitude: longitude,
              province: province,
              profileData: userCredential.data(),
              image: userCredential.data()?.image,
              otherImages: userCredential.data()?.otherImages,
              isProfile: userCredential.data()?.isProfile,
              cities: cityObj,
              selectedOption: selectedValues,
              selectedProvinceOption: selectedProvinceValues,
              servicesList: userCredential.data()?.servicesList ?? [],
              isAvailable,
            });
            if (userCredential.data()?.country) {
              updateState({
                canEditCountry: false,
              });
            }
          },
        });
        this.props.loaderChange(false);
      })
      .catch((error) => {
        console.log(error.message + " - " + error.code);
        this.props.loaderChange(false);
      });
  };
  onSubmit = (values) => {
    console.log(values);
    this.setState({ isLoader: true });
    vendorService
      .addProfile(values, this.userId)
      .then((userCredential) => {
        this.setState({ isProfile: true, isLoader: false });
        let storage = JSON.parse(localStorage.getItem("user"));
        values.accessToken = storage.accessToken;
        values.uid = storage.uid;
        values.isProfile = true;
        // storage.name = storage?.name;
        global.user = storage;
        localStorage.setItem("user", JSON.stringify(values));
        toast.success("Profile updated successfully");
        console.log(userCredential);
      })
      .catch((error) => {
        console.log(error.message + " - " + error.code);
        this.setState({ isLoader: false });
        // ..
      });
  };
  handleImages = (e) => {
    const file = e.target.files[0];
    console.log(file);
    commonService.uploadImage(file, this.getUrl, "vendorProfile");
  };
  handleOtherImages = (e) => {
    const file = e.target.files;
    console.log(file);
    commonService.uploadMultipleImages(file, this.getMultiUrl, "otherImages");
  };
  getUrl = (url) => {
    this.setState({ image: url });
  };
  getMultiUrl = (url, path) => {
    this.setState({ [path]: url });
  };
  setValues = (key, val) => {
    let data = this.state.profileData;
    data[key] = val;
    this.setState({ profileData: data });
  };
  removeImg = (i) => {
    let images = this.state.otherImages;
    images.splice(i, 1);
    this.setState({ otherImages: images });
  };
  validateFacebookUrl = (val) => {
    //console.log(`Facebook Input ::`, val);
    var error = "";
    if (val && !val.toLowerCase().includes("facebook")) {
      error = "Enter URL of Facebook";
    }
    return error;
  };
  validateInstagramUrl = (val) => {
    //console.log(`Instagram Input ::`, val);
    var error = "";
    if (val && !val.toLowerCase().includes("instagram")) {
      error = "Enter URL of Instagram";
    }
    return error;
  };
  validateVatNumber = (val) => {
    var error = "";
    // if (!val.toUpperCase().includes('IT')) {
    //     error = 'This is not a valid VAT Number';
    // }
    return error;
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  handleProvinceChange = (selectedProvinceOption) => {
    var cities = [];
    this.state.csvData.map((e) => {
      if (e.Column1 == selectedProvinceOption.value) {
        let temp = {
          value: e.Comune,
          label: e.Comune,
        };
        cities.push(temp);
      }
    });
    this.setState({
      cities,
      selectedProvinceOption,
    });
  };
  handleServicesList = () => {
    console.log(this.state.services);
    if (this.state.services) {
      let servicesList = this.state.servicesList;
      servicesList.push(this.state.services);
      //   let data = this.state.profileData;
      //   data.servicesList = servicesList;
      //   console.log(data);
      this.setState({ servicesList: servicesList });
    }
  };
  removeService = (i) => {
    let servicesList = this.state.servicesList;
    servicesList.splice(i, 1);
    // let data = this.state.profileData;
    // data.servicesList = servicesList;
    this.setState({ servicesList: servicesList });
  };
  getCurrentLocation = () => {
    var latitude = this.state.latitude;
    var longitude = this.state.longitude;
    var state = this.updateState;
    navigator.geolocation.getCurrentPosition(function (position) {
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      state({
        latitude,
        longitude,
      });
    });
    // this.setState();
  };
  changeAvaialbility = () => {
    console.log(this.state.isAvailable);
    var isAvailable = this.state.isAvailable;
    isAvailable = !isAvailable;
    console.log(isAvailable);
    const data = { isAvailable: isAvailable };
    console.log(data);
    vendorService
      .updateProfile(data, this.userId)
      .then((userCredential) => {
        var profileData = this.state.profileData;
        profileData.isAvailable = data.isAvailable;
        this.setState({ isAvailable, profile: profileData });
        toast.success("Availability Changed");
        console.log(userCredential);
      })
      .catch((error) => {
        console.log(error.message + " - " + error.code);
        this.setState({ isLoader: false });
        // ..
      });
  };
  onDaySelect = (e) => {
    const { value, checked } = e.target;
    if (!checked) {
      const workingDays = this.state.workingDays;
      delete workingDays[value];
      this.setState({ workingDays });
    } else {
      this.setState({ showWorkTime: true, selectedDay: value });
    }
  };
  onTimeSelect = () => {
    if (!this.state.startTime || !this.state.endTime) {
      toast.error("Please select Time");
    } else {
      const workingDays = this.state.workingDays;
      var day = {
        day: this.state.selectedDay,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
      };
      workingDays[this.state.selectedDay] = day;
      this.setState({ showWorkTime: false, workingDays });
    }
  };
  getWorkingObj = (list) => {
    let result = {};
    list.forEach((e) => {
      result[e.day] = e;
    });
    console.log(result);
    this.setState({ workingDays: result });
  };
  getWorkingList = (obj) => {
    let result = [];
    Object.keys(obj).map((key) => {
      result.push(obj[key]);
    });
    return result;
  };
  getTime = (time) => {
    const hr = time[0] + time[1];
    if (parseInt(hr) > 12) {
      return hr - 12 + ":" + time[3] + time[4] + "PM";
    }
    return hr + ":" + time[3] + time[4] + "AM";
  };
  render() {
    const { t } = this.props;
    const { selectedOption, selectedProvinceOption } = this.state;
    return (
      <div className="container mt-5">
        {/* <IoIosCloseCircleOutline className="position-absolute" style={{ right: '15px', top: '15px' }} onClick={this.props.close} /> */}
        <h4 className="text-center border-bottom border-danger">
          {t("profile")}
        </h4>
        {!this.state.isProfile ? (
          <div className="alert alert-warning" role="alert">
            Please complete profile before proceed!
          </div>
        ) : null}
        <button
          className={`btn float-end cursor-pointer ${
            this.state.isAvailable ? "btn-success" : "btn-secondary"
          }`}
          onClick={() => {
            this.changeAvaialbility();
          }}
        >
          {t(
            "setAvailabilityToRepairAssembleMaterialPurchasedELSEWHEREOrOnlineToONOFF"
          )}
          {this.state.isAvailable ? "ON" : "OFF"}
        </button>
        <Formik
          enableReinitialize
          initialValues={{
            name: this.state.profileData?.name
              ? this.state.profileData.name
              : "",
            image: this.state.image,
            otherImages: this.state.otherImages,
            description: this.state.profileData?.description
              ? this.state.profileData.description
              : "",
            addressline1: this.state.profileData?.addressline1
              ? this.state.profileData.addressline1
              : "",
            addressline2: this.state.profileData?.addressline2
              ? this.state.profileData.addressline2
              : "",
            zipCode: this.state.profileData?.zipCode
              ? this.state.profileData.zipCode
              : "",
            country: "Italy",
            province: this.state.selectedProvinceOption?.value
              ? this.state.selectedProvinceOption?.value
              : "",
            phoneNumber: this.state.profileData?.phoneNumber
              ? this.state.profileData.phoneNumber
              : "",
            whatsappNumber: this.state.profileData?.whatsappNumber
              ? this.state.profileData.whatsappNumber
              : "",
            vendorLink: this.state.profileData?.vendorLink
              ? this.state.profileData.vendorLink
              : "",
            vatNumber: this.state.profileData?.vatNumber
              ? this.state.profileData.vatNumber
              : "",
            facebook: this.state.profileData?.facebook
              ? this.state.profileData.facebook
              : "",
            instagram: this.state.profileData?.instagram
              ? this.state.profileData.instagram
              : "",
            reparationTime: this.state.profileData?.reparationTime
              ? this.state.profileData.reparationTime
              : "",
            role: "vendor",
            isProfile: true,
            email: this.state.profileData?.email
              ? this.state.profileData.email
              : "",
            city: this.state.selectedOption?.value
              ? this.state.selectedOption?.value
              : "",
            servicesList: this.state.servicesList
              ? this.state.servicesList
              : [],
            latitude: this.state.latitude ? this.state.latitude : 0,
            longitude: this.state.longitude ? this.state.longitude : 0,
            workingDays: this.state.workingDays
              ? this.getWorkingList(this.state.workingDays)
              : [],
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            // image: Yup.string().required("Vendor Image required"),
            description: Yup.string().required("Description is required"),
            addressline1: Yup.string().required("Address line1 is required"),
            addressline2: Yup.string(),
            zipCode: Yup.string()
              .matches(/^[0-9]+$/, "Must be only digits")
              .length(5, "Zip Code must be exactly 5 characters")
              .required("Zip Code is required"),
            country: Yup.string().required("Country is required"),
            province: Yup.string().required("Province is required"),
            city: Yup.string().required("City is required"),
            phoneNumber: Yup.number()
              .typeError("That doesn't look like a phone number")
              .positive("A phone number can't start with a minus")
              .integer("A phone number can't include a decimal point")
              .min(8)
              .required("A phone number is required"),
            whatsappNumber: Yup.number()
              .typeError("That doesn't look like a phone number")
              .positive("A phone number can't start with a minus")
              .integer("A phone number can't include a decimal point")
              .min(8),
            vendorLink: Yup.string().url(),
            vatNumber: Yup.string()
              .length(11, "VAT number must be exactly 11 characters")
              .required("VAT number is required"),
            facebook: Yup.string().url(),
            instagram: Yup.string().url(),
            reparationTime: "",
            servicesList: "",
            latitude: "",
            longitude: "",
            startTime: "",
            endTime: "",
            workingDays: "",
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (this.state.longitude > 0) {
              setTimeout(() => {
                this.onSubmit(values);
              }, 400);
            } else {
              toast.success("Please add co-ordinates");
            }
          }}
        >
          <Form className="mt-5" autoComplete="off">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="col-12">
                  <div className="text-left mt-2">
                    <label htmlFor="name" className="fs-16 fw-600 mb-1">
                      {t("nameOfTheShop")}
                    </label>
                    <Field
                      name="name"
                      type="text"
                      onChange={(e) => {
                        this.setValues("name", e.target.value);
                      }}
                      value={this.state.profileData.name}
                      className="w-100 ps-3 input_field-height rounded-3 border-1"
                      placeholder="Enter Vendor name"
                    />
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="name"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="text-left mt-2">
                    <label htmlFor="description" className="fs-16 fw-600 mb-1">
                      {t("descriptionOfTheVendor")}
                    </label>
                    <Field
                      name="description"
                      type="text"
                      as="textarea"
                      onChange={(e) => {
                        this.setValues("description", e.target.value);
                      }}
                      value={this.state.profileData.description}
                      rows="3"
                      className="w-100 ps-3 rounded-3 border-1"
                      placeholder="Enter Vendor Description"
                    />
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="description"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-12">
                    <label
                      htmlFor="image"
                      className="fs-16 fw-600 mb-1 float-start"
                    >
                      {t("profileImage")}
                    </label>
                  </div>
                  {this.state.image ? (
                    <div className="col-6 offset-3">
                      <div className="position-relative">
                        <AiOutlineCloseCircle
                          className="position-absolute top-0 start-100 translate-middle text-secondary"
                          onClick={() => {
                            this.setState({ image: null });
                          }}
                        />
                        <img
                          src={
                            this.state.image ? this.state.image : DefaultImage
                          }
                          alt="banner-1"
                          className="vendor_store-images rounded rounded-3"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-6 offset-3">
                      <div className="upload-btn-wrapper">
                        <AiOutlineUpload className="upload-btn" />
                        <input
                          name="image"
                          type="file"
                          onChange={this.handleImages}
                        />
                        <p>Upload Image</p>
                      </div>
                    </div>
                  )}
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="image"
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <label
                  htmlFor="microCategory"
                  className="fs-16 fw-600 mb-1 float-start"
                >
                  {t("otherImages")}
                </label>
              </div>
              <div className="col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-1 col-md-2 col-3 align-self-center">
                    <div className="upload-btn-wrapper">
                      <AiOutlineUpload className="upload-btn" />
                      <input
                        name="otherImages"
                        multiple
                        type="file"
                        onChange={this.handleOtherImages}
                        accept="/image/*"
                      />
                    </div>
                  </div>

                  {this.state.otherImages?.map((el, i) => {
                    return (
                      <div className="col-lg-1 col-md-2 col-3" key={i}>
                        <div className="position-relative">
                          <AiOutlineCloseCircle
                            className="position-absolute top-0 start-100 translate-middle text-secondary"
                            onClick={() => {
                              this.removeImg(i);
                            }}
                          />
                          <img
                            src={el}
                            alt="banner-1"
                            className="vendor_store-images"
                          />
                        </div>
                      </div>
                    );
                  })}

                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="otherImages"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12 my-4">
                <div
                  style={{
                    width: "100%",
                    height: "15px",
                    borderBottom: "1px solid grey",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      backgroundColor: "white",
                      padding: "0 10px",
                    }}
                  >
                    {t("addressOfTheVendor")}
                  </span>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left">
                  <label htmlFor="addressline1" className="fs-16 fw-600 mb-1">
                    {t("addressLine1")}
                  </label>
                  <Field
                    name="addressline1"
                    type="text"
                    onChange={(e) => {
                      this.setValues("addressline1", e.target.value);
                    }}
                    value={this.state.profileData.addressline1}
                    className="w-100 ps-3 input_field-height rounded-3 border-1"
                    placeholder="Enter Vendor name"
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="addressline1"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left mt-lg-0 mt-md-0 mt-2">
                  <label htmlFor="addressline2" className="fs-16 fw-600 mb-1">
                    {t("addressLine2")}
                  </label>
                  <Field
                    name="addressline2"
                    type="text"
                    onChange={(e) => {
                      this.setValues("addressline2", e.target.value);
                    }}
                    value={this.state.profileData.addressline2}
                    className="w-100 ps-3 input_field-height rounded-3 border-1"
                    placeholder="Enter Vendor name"
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="addressline2"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left mt-2">
                  <label htmlFor="zipCode" className="fs-16 fw-600 mb-1">
                    {t("zipCode")}
                  </label>
                  <Field
                    name="zipCode"
                    type="number"
                    onChange={(e) => {
                      this.setValues("zipCode", e.target.value);
                    }}
                    value={this.state.profileData.zipCode}
                    validate={this.validatePostalCode}
                    className="w-100 ps-3 input_field-height rounded-3 border-1"
                    placeholder="Enter Zip Code"
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="zipCode"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left mt-2">
                  <label htmlFor="country" className="fs-16 fw-600 mb-1">
                    Country
                  </label>
                  <Field
                    name="country"
                    value="Italy"
                    as="select"
                    className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                    disabled={!this.state.canEditCountry}
                  >
                    <option value="Italy">Italy</option>
                  </Field>
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="country"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left mt-2">
                  <label htmlFor="province" className="fs-16 fw-600 mb-1">
                    Province
                  </label>
                  <Select
                    value={selectedProvinceOption}
                    onChange={this.handleProvinceChange}
                    options={this.state.province}
                    className=""
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="city"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left mt-2">
                  <label htmlFor="country" className="fs-16 fw-600 mb-1">
                    City{" "}
                    <FcInfo
                      size={14}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Please contact admin if your city is not available"
                    />
                  </label>

                  <Select
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={this.state.cities}
                    className="w-100 me-2"
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="city"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12 mt-3">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-12 text-start">
                    <button
                      type="button"
                      onClick={this.getCurrentLocation}
                      className="btn btn-transparent border border-1"
                    >
                      {t("getCurrentLocation")}
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12 text-start">
                    <p className="">
                      {t("latitude")} :
                      {this.state.latitude ? this.state.latitude : ""}{" "}
                    </p>
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="latitude"
                    />
                  </div>
                  <div className="col-lg-4 col-md-4 col-12 text-start">
                    <p>
                      {t("longitude")} :
                      {this.state.longitude ? this.state.longitude : ""}{" "}
                    </p>
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="longitude"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-12 my-4">
                <div
                  style={{
                    width: "100%",
                    height: "15px",
                    borderBottom: "1px solid grey",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      backgroundColor: "white",
                      padding: "0 10px",
                    }}
                  >
                    {t("contactDetails")}
                  </span>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="d-flex align-items-center">
                  <label htmlFor="phoneNumber" className="fs-16 fw-600 mb-1">
                    {t("workingDays")}
                  </label>
                  <div className="weekDays-selector ms-4">
                    <input
                      type="checkbox"
                      id="weekday-mon"
                      className="weekday"
                      name="workingDays"
                      value="M"
                      onChange={this.onDaySelect}
                      checked={
                        this.state.workingDays.M
                          ? this.state.workingDays.M.startTime
                          : this.state.workingDays.M
                      }
                    />
                    <label htmlFor="weekday-mon">{t("M")}</label>
                    <input
                      type="checkbox"
                      id="weekday-tue"
                      className="weekday"
                      name="workingDays"
                      value="T"
                      onChange={this.onDaySelect}
                      checked={
                        this.state.workingDays.T
                          ? this.state.workingDays.T.day
                          : this.state.workingDays.T
                      }
                    />
                    <label htmlFor="weekday-tue">{t("T")}</label>
                    <input
                      type="checkbox"
                      id="weekday-wed"
                      className="weekday"
                      name="workingDays"
                      value="W"
                      onChange={this.onDaySelect}
                      checked={
                        this.state.workingDays.W
                          ? this.state.workingDays.W.day
                          : this.state.workingDays.W
                      }
                    />
                    <label htmlFor="weekday-wed">{t("W")}</label>
                    <input
                      type="checkbox"
                      id="weekday-thu"
                      className="weekday"
                      name="workingDays"
                      value="Th"
                      onChange={this.onDaySelect}
                      checked={
                        this.state.workingDays.Th
                          ? this.state.workingDays.Th.day
                          : this.state.workingDays.Th
                      }
                    />
                    <label htmlFor="weekday-thu">{t("Th")}</label>
                    <input
                      type="checkbox"
                      id="weekday-fri"
                      className="weekday"
                      name="workingDays"
                      value="F"
                      onChange={this.onDaySelect}
                      checked={
                        this.state.workingDays.F
                          ? this.state.workingDays.F.day
                          : this.state.workingDays.F
                      }
                    />
                    <label htmlFor="weekday-fri">{t("F")}</label>
                    <input
                      type="checkbox"
                      id="weekday-sat"
                      className="weekday"
                      name="workingDays"
                      value="Sa"
                      onChange={this.onDaySelect}
                      checked={
                        this.state.workingDays.Sa
                          ? this.state.workingDays.Sa.day
                          : this.state.workingDays.Sa
                      }
                    />
                    <label htmlFor="weekday-sat">{t("Sa")}</label>
                    <input
                      type="checkbox"
                      id="weekday-sun"
                      className="weekday"
                      name="workingDays"
                      value="S"
                      onChange={this.onDaySelect}
                      checked={
                        this.state.workingDays.S
                          ? this.state.workingDays.S.day
                          : this.state.workingDays.S
                      }
                    />
                    <label htmlFor="weekday-sun">{t("Sa")}</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                {this.state.showWorkTime ? (
                  <div className="d-flex align-items-center">
                    <label htmlFor="phoneNumber" className="fs-16 fw-600 mb-1">
                      Working Hours:
                    </label>
                    {/* <TimeRange
                    startMoment={this.state.startTime}
                    endMoment={this.state.endTime}
                    onChange={this.selectTime}
                  /> */}
                    <input
                      type="time"
                      value={this.state.startTime}
                      onChange={(e) => {
                        this.setState({ startTime: e.target.value });
                      }}
                    />
                    {" to "}
                    <input
                      type="time"
                      value={this.state.endTime}
                      onChange={(e) => {
                        this.setState({ endTime: e.target.value });
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-orange input_field-height ms-2"
                      onClick={this.onTimeSelect}
                    >
                      Add
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="col-12">
                <ul className="d-flex flex-wrap">
                  {this.getWorkingList(this.state.workingDays).map((e) => {
                    return (
                      <li className="ms-5">
                        <p>
                          {e.day}:{this.getTime(e.startTime)}-
                          {this.getTime(e.endTime)}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left">
                  <label htmlFor="phoneNumber" className="fs-16 fw-600 mb-1">
                    {t("phoneNo")}
                  </label>
                  <Field
                    name="phoneNumber"
                    type="number"
                    onChange={(e) => {
                      this.setValues("phoneNumber", e.target.value);
                    }}
                    value={this.state.profileData.phoneNumber}
                    className="w-100 ps-3 input_field-height rounded-3 border-1"
                    placeholder="Enter Phone No."
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="phoneNumber"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left">
                  <label htmlFor="whatsappNumber" className="fs-16 fw-600 mb-1">
                    {t("whatsppNo")}
                  </label>
                  <Field
                    name="whatsappNumber"
                    type="number"
                    onChange={(e) => {
                      this.setValues("whatsappNumber", e.target.value);
                    }}
                    value={this.state.profileData.whatsappNumber}
                    className="w-100 ps-3 input_field-height rounded-3 border-1"
                    placeholder="Enter Whatsapp No."
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="whatsappNumber"
                  />
                </div>
              </div>

              {/*  */}
              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left mt-2">
                  <label htmlFor="vendorLink" className="fs-16 fw-600 mb-1">
                    {t("linkOfVendor")}
                  </label>
                  <Field
                    name="vendorLink"
                    type="text"
                    onChange={(e) => {
                      this.setValues("vendorLink", e.target.value);
                    }}
                    value={this.state.profileData.vendorLink}
                    className="w-100 ps-3 input_field-height rounded-3 border-1"
                    placeholder="Enter Vendor link"
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="vendorLink"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left mt-2">
                  <label htmlFor="vatNumber" className="fs-16 fw-600 mb-1">
                    {t("enterVATNo")}
                  </label>
                  <Field
                    name="vatNumber"
                    type="number"
                    onChange={(e) => {
                      this.setValues("vatNumber", e.target.value);
                    }}
                    value={this.state.profileData.vatNumber}
                    validate={this.validateVatNumber}
                    className="w-100 ps-3 input_field-height rounded-3 border-1"
                    placeholder="Enter VAT No."
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="vatNumber"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left mt-2">
                  <label htmlFor="facebook" className="fs-16 fw-600 mb-1">
                    {t("facebookLink")}
                  </label>
                  <Field
                    name="facebook"
                    type="text"
                    onChange={(e) => {
                      this.setValues("facebook", e.target.value);
                    }}
                    value={this.state.profileData.facebook}
                    className="w-100 ps-3 input_field-height rounded-3 border-1"
                    validate={this.validateFacebookUrl}
                    placeholder="Enter Facebook Link"
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="facebook"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left mt-2">
                  <label htmlFor="instagram" className="fs-16 fw-600 mb-1">
                    {t("instagramLink")}
                  </label>
                  <Field
                    name="instagram"
                    type="text"
                    onChange={(e) => {
                      this.setValues("instagram", e.target.value);
                    }}
                    value={this.state.profileData.instagram}
                    validate={this.validateInstagramUrl}
                    className="w-100 ps-3 input_field-height rounded-3 border-1"
                    placeholder="Enter Instagram link"
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="instagram"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="text-left mt-2">
                  <label htmlFor="reparationTime" className="fs-16 fw-600 mb-1">
                    {t("averageReparationTime")}
                  </label>
                  <Field
                    name="reparationTime"
                    type="number"
                    onChange={(e) => {
                      this.setValues("reparationTime", e.target.value);
                    }}
                    value={this.state.profileData.reparationTime}
                    className="w-100 ps-3 input_field-height rounded-3 border-1"
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="reparationTime"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-12 mt-2 d-flex align-items-end">
                <div className="text-left">
                  <label htmlFor="servicesList" className="fs-16 fw-600 mb-1">
                    {t("enterServices")}
                  </label>
                  <Field
                    name="servicesList"
                    type="text"
                    value={this.state.services}
                    className="w-100 ps-3 input_field-height rounded-3 border-1"
                    placeholder="Enter Services"
                    onChange={(e) => {
                      this.setState({ services: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="servicesList"
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-outline-orange input_field-height ms-2"
                  onClick={this.handleServicesList}
                >
                  {t("add")}
                </button>
              </div>
              <div className="col-lg-8 col-md-2 col-3 d-flex align-self-center mt-4">
                {this.state.servicesList?.map((el, i) => {
                  return (
                    <div className="d-flex align-items-center h-100" key={i}>
                      <span className=" me-3 d-flex align-items-center btn-orange rounded-pill input_field-height p-2">
                        {el}{" "}
                        <AiOutlineCloseCircle
                          className="text-dark ms-1"
                          onClick={() => {
                            this.removeService(i);
                          }}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
              {/* <div className='col-12 mt-3'>
                                <div className="d-flex align-items-center">
                                    <Field name="termsConditions" type="checkbox" />
                                    <label htmlFor="termsConditions" className="fs-16 fw-600 ms-3">Agree Terms & Conditions</label>
                                    <ErrorMessage component="span" className="error-msg" name="termsConditions" />
                                </div>
                            </div> */}
            </div>
            <button
              type="submit"
              className="btn btn-orange d-block m-auto my-5 rounded-pill"
            >
              {this.state.isLoader ? (
                <span>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </span>
              ) : (
                `${t("submit")}`
              )}
            </button>
          </Form>
        </Formik>
      </div>
    );
  }
}
export default withRouter(withTranslation()(Profile));
