import { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import vendorService from "../../services/vendor.service";
import adminService from "../../services/admin.service";
import { vendorId, withRouter, userCountry } from "../../utils/common.utils";
import Spinner from "react-bootstrap/Spinner";
import { BsCurrencyEuro } from "react-icons/bs";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Select from "react-select";
import { withTranslation } from "react-i18next";

class CreateInventory extends Component {
  constructor(props) {
    super(props);
    this.vendorId = vendorId();
    this.country = userCountry();
    this.state = {
      brands: [],
      dropDownData: [],
      macroCategory: "",
      subCategory: "",
      miniCategory: "",
      microCategory: "",
      brand: "",
      label: "",
      colour: "",
      secondaryColour: "",
      wheelSize: "",
      frameSize: "",
      quantity: "",
      price: "",
      subCategoryArray: [],
      miniCategoryArray: [],
      microCategoryArray: [],
      colors: [],
      wheelSizeList: [],
      isLoader: false,
      selectedLabel: null,
      yearOfManufacture: "",
      retailPrice: 0,
      frameSizeList: [],
      primaryColorOption: [],
      secondaryColorOption: [],
      codeList: [],
      expired: false,
    };
  }
  componentDidMount() {
    this.getBrands();
  }
  getData = () => {
    adminService
      .getAllCategories()
      .then((res) => {
        console.log(res.data());
        this.setState({
          dropDownData: res.data().macroCategories,
          colors: res.data().colors,
          // wheelSizeList: res.data().wheelSize,
        });
        if (this.props.router?.searchParams?.id) {
          console.log(this.props.router?.searchParams?.id);
          this.getSingleData(
            this.props.router?.searchParams?.id,
            res.data().macroCategories
          );
        } else {
          this.props.loaderChange(false);
        }
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  getBrands = async () => {
    this.props.loaderChange(true);
    vendorService
      .getBrands(this.vendorId)
      .then((res) => {
        var data = res?.data().brands ? res?.data().brands : [];
        console.log(data);
        data = data.filter(function (el) {
          return !el.delivered;
        });
        data = data.map((e) => {
          return e.company;
        });
        data = [...new Set(data)];
        this.setState({ brands: data });
        this.getData();
      })
      .catch((err) => {
        this.props.loaderChange(false);
        console.log(err);
      });
  };
  onSelectBrand = async (val) => {
    this.props.loaderChange(true);
    vendorService
      .getBrandProduct(val, this.country)
      .then((res) => {
        let labelObjList = [];
        let labelObj = [];
        res.map((el) => {
          labelObjList.push(el);
          let temp = {
            value: el.label,
            label: el.label,
          };
          labelObj.push(temp);
        });
        // console.log(res);
        this.setState({
          brand: val,
          labelList: labelObj,
          labelObjList: labelObjList,
        });
        console.log(labelObjList);
        this.props.loaderChange(false);
      })
      .catch((err) => {
        this.props.loaderChange(false);
        console.log(err);
      });
  };
  changeLabel = async (selectedLabel) => {
    this.setState({
      selectedLabel,
    });
    this.props.loaderChange(true);
    console.log(this.state.labelObjList);
    var newArr = this.state.labelObjList.filter(
      (obj, i) => obj.label === selectedLabel.label
    );
    let obj = newArr[0];
    console.log(obj);
    this.setState({
      label: obj.label,
      macroCategory: obj.macroCategory,
      subCategory: obj.subCategory,
      miniCategory: obj.miniCategory,
      microCategory: obj.microCategory,
      yearOfManufacture: obj.yearOfManufacture ? obj.yearOfManufacture : "",
      codeList: obj.codeList ? obj.codeList : [],
      retailPrice: obj.retailPrice ? obj.retailPrice : 0,
      frameSizeList: obj.frameSizeList ? obj.frameSizeList : [],
      wheelSizeList: obj.wheelSizeList ? obj.wheelSizeList : [],
      primaryColorOption: obj.primaryColorOption ? obj.primaryColorOption : [],
      secondaryColorOption: obj.secondaryColorOption
        ? obj.secondaryColorOption
        : [],
    });
    this.props.loaderChange(false);
  };
  getSingleData = (id, dropdown) => {
    vendorService
      .getSingleCatelogs(id)
      .then((res) => {
        console.log(res.data());
        // this.setState({ dropDownData: res.data().macroCategories })
        this.patchValues(res.data(), dropdown);
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  patchValues = async (currentVal, dropdownVal) => {
    let subIndex = await dropdownVal
      .map((el) => {
        return el?.name;
      })
      .indexOf(currentVal?.macroCategory);
    let subCategoryArray = dropdownVal[subIndex]?.subCategory
      ? dropdownVal[subIndex]?.subCategory
      : [];
    let miniIndex = await subCategoryArray
      .map((el) => {
        return el?.name;
      })
      .indexOf(currentVal?.subCategory);
    let miniCategoryArray = subCategoryArray[miniIndex]?.miniCategory
      ? subCategoryArray[miniIndex]?.miniCategory
      : [];
    let microIndex = await miniCategoryArray
      .map((el) => {
        return el?.name;
      })
      .indexOf(currentVal?.miniCategory);
    let microCategoryArray = miniCategoryArray[microIndex]?.microategory
      ? miniCategoryArray[microIndex]?.microategory
      : [];
    this.setState({
      subCategoryArray: subCategoryArray,
      miniCategoryArray: miniCategoryArray,
      microCategoryArray: microCategoryArray,
      brand: currentVal.brand,
      label: currentVal.label,
      macroCategory: currentVal.macroCategory,
      subCategory: currentVal.subCategory,
      miniCategory: currentVal.miniCategory,
      microCategory: currentVal.microCategory,
      colour: currentVal.colour,
      secondaryColour: currentVal?.secondaryColour,
      wheelSize: currentVal.wheelSize,
      frameSize: currentVal.frameSize,
      quantity: currentVal.quantity,
      price: currentVal.price,
      yearOfManufacture: currentVal.yearOfManufacture,
      codeList: currentVal.codeList ? currentVal.codeList : [],
      retailPrice: currentVal.retailPrice,
      frameSizeList: currentVal.frameSizeList ? currentVal.frameSizeList : [],
      wheelSizeList: currentVal.wheelSizeList ? currentVal.wheelSizeList : [],
      expired: currentVal.expired ? currentVal.expired : false,
      // primaryColorOption: currentVal.primaryColorOption,
      // secondaryColorOption: currentVal.secondaryColorOption,
    });
    this.props.loaderChange(false);
  };
  onMacroChange = async (val) => {
    let i = await this.state.dropDownData
      .map((el) => {
        return el?.name;
      })
      .indexOf(val);
    let subCategory = this.state.dropDownData[i]?.subCategory
      ? this.state.dropDownData[i]?.subCategory
      : [];
    this.setState({
      macroCategory: val,
      subCategoryArray: subCategory,
      miniCategoryArray: [],
      microCategoryArray: [],
      subCategory: "",
      miniCategory: "",
      microCategory: "",
    });
    console.log(val);
  };
  onSubChange = async (val) => {
    let i = await this.state.subCategoryArray
      .map((el) => {
        return el?.name;
      })
      .indexOf(val);
    let miniCategory = this.state.subCategoryArray[i]?.miniCategory
      ? this.state.subCategoryArray[i]?.miniCategory
      : [];
    this.setState({
      subCategory: val,
      miniCategoryArray: miniCategory,
      microCategoryArray: [],
      miniCategory: "",
      microCategory: "",
    });
    console.log(val);
  };
  onMiniChange = async (val) => {
    let i = await this.state.miniCategoryArray
      .map((el) => {
        return el?.name;
      })
      .indexOf(val);
    let microCategory = this.state.miniCategoryArray[i]?.microategory
      ? this.state.miniCategoryArray[i]?.microategory
      : [];
    this.setState({
      miniCategory: val,
      microCategoryArray: microCategory,
      microCategory: "",
    });
  };
  setValues = (key, val) => {
    this.setState({ [key]: val });
  };
  onSubmit = (values) => {
    values.vendor = this.vendorId;
    console.log("vlaues - ", values);
    this.setState({ isLoader: true });
    if (this.props.router?.searchParams?.id) {
      vendorService
        .updateVendorCatlog(values, this.props.router.searchParams.id)
        .then((res) => {
          this.setState({
            isLoader: false,
          });
          toast.success("Updated Successfully successfully ");
          this.props.router.navigate("/vendor/inventory-list");
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoader: false });
          const errorMessage = err.message;
          toast.error(errorMessage);
        });
    } else {
      vendorService
        .addVendorCatlog(values)
        .then((res) => {
          this.setState({
            macroCategory: "",
            subCategory: "",
            miniCategory: "",
            microCategory: "",
            brand: "",
            label: "",
            colour: "",
            secondaryColour: "",
            wheelSize: "",
            quantity: "",
            price: "",
            isLoader: false,
            yearOfManufacture: "",
            retailPrice: 0,
            frameSize: "",
            frameSizeList: [],
            wheelSizeList: [],
            codeList: [],
          });
          toast.success("Added successfully ");
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoader: false });
          const errorMessage = err.message;
          toast.error(errorMessage);
        });
    }
  };
  render() {
    const { t } = this.props;
    return (
      <div className="container">
        <section>
          <div className="my-5">
            <div className="row">
              <div className="col-12 text-start">
                <IoArrowBackCircleOutline
                  className="cursor-pointer"
                  size={24}
                  onClick={() => {
                    this.props.router.navigate(`/vendor/inventory-list`);
                  }}
                />
              </div>
              <div className="col-12">
                <h4>{t("createAndManageInventory")}</h4>
              </div>
            </div>
          </div>
          <div className="" style={{ paddingBottom: "10px" }}>
            <Formik
              enableReinitialize
              initialValues={{
                brand: this.state.brand,
                label: this.state.label,
                macroCategory: this.state.macroCategory,
                subCategory: this.state.subCategory,
                miniCategory: this.state.miniCategory,
                microCategory: this.state.microCategory,
                colour: this.state.colour,
                wheelSize: this.state.wheelSize,
                frameSize: this.state.frameSize,
                price: this.state.price,
                quantity: this.state.quantity,
                secondaryColour: this.state.secondaryColour,
                yearOfManufacture: this.state.yearOfManufacture,
                codeList: this.state.codeList,
                frameSizeList: this.state.frameSizeList,
                wheelSizeList: this.state.wheelSizeList,
                retailPrice: this.state.retailPrice,
                expired: this.state.expired,
              }}
              validationSchema={Yup.object({
                brand: Yup.string().required("Brand Name is required"),
                label: Yup.string().required("Label is required"),
                macroCategory: Yup.string().required(
                  "Macro Category is required"
                ),
                subCategory: Yup.string(),
                miniCategory: Yup.string(),
                microCategory: Yup.string(),
                // colour: Yup.string().required("Colour is required"),
                secondaryColour: Yup.string().required("Colour is required"),
                wheelSize: Yup.string().required("Wheel Size is required"),
                price: Yup.string()
                  .matches(/^[0-9]+$/, "Must be only digits")
                  .required("Price is required"),
                quantity: Yup.string()
                  .matches(/^[0-9]+$/, "Must be only digits")
                  .required("Quantity is required"),
                frameSize: Yup.string().required("Frame Size is required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this.onSubmit(values);
                }, 400);
              }}
            >
              <Form className="mt-5" autoComplete="off">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label htmlFor="brand" className="fs-16 fw-600 mb-1">
                        {t("brand")}
                      </label>
                      <Field
                        name="brand"
                        value={this.state.brand}
                        onChange={(e) => {
                          this.onSelectBrand(e.target.value);
                          // this.setState({ brand: e.target.value });
                        }}
                        as="select"
                        className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                      >
                        <option value="">{t("selectBrand")}</option>
                        {this.state.brands.map((el, i) => {
                          return (
                            <option key={i} value={el}>
                              {el}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="brand"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label htmlFor="label" className="fs-16 fw-600 mb-1">
                        {t("model")}
                      </label>
                      <Select
                        value={this.selectedLabel}
                        onChange={this.changeLabel}
                        options={this.state.labelList}
                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                        placeholder={t("enterModel")}
                      />
                      {/* <Field
                                                name="label" type="text"
                                                className="w-100 ps-3 input_field-height rounded-3 border-1"
                                                placeholder="Enter label"
                                                onChange={(e) => { this.setValues('label', e.target.value) }}
                                                value={this.state.label} /> */}
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="label"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label
                        htmlFor="macroCategory"
                        className="fs-16 fw-600 mb-1"
                      >
                        Macro {t("category")}
                      </label>
                      <p className="w-100 ps-3 input_field-height rounded-3 category-input-field">
                        {this.state.macroCategory
                          ? this.state.macroCategory
                          : `${t("pleaseSelectModel")}`}
                      </p>
                      {/* <Field
                        name="macroCategory"
                        value={this.state.macroCategory}
                        as="select"
                        onChange={(e) => {
                          this.onMacroChange(e.target.value);
                        }}
                        className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                      >
                        <option value="">Select Macro Category</option>
                        {this.state.dropDownData.map((el, i) => {
                          return (
                            <option key={i} value={el?.name}>
                              {el?.name}
                            </option>
                          );
                        })}
                      </Field> */}
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="macroCategory"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label
                        htmlFor="subCategory"
                        className="fs-16 fw-600 mb-1"
                      >
                        Sub {t("category")}
                      </label>
                      <p className="w-100 ps-3 input_field-height rounded-3 category-input-field">
                        {this.state.subCategory
                          ? this.state.subCategory
                          : `${t("pleaseSelectModel")}`}
                      </p>
                      {/* <Field
                        name="subCategory"
                        value={this.state.subCategory}
                        as="select"
                        onChange={(e) => {
                          this.onSubChange(e.target.value);
                        }}
                        className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                      >
                        <option value="">Select Sub Category</option>
                        {this.state.subCategoryArray.map((el, i) => {
                          return (
                            <option key={i} value={el?.name}>
                              {el?.name}
                            </option>
                          );
                        })}
                      </Field> */}
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="subCategory"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label
                        htmlFor="miniCategory"
                        className="fs-16 fw-600 mb-1"
                      >
                        Mini {t("category")}
                      </label>
                      <p className="w-100 ps-3 input_field-height rounded-3 category-input-field">
                        {this.state.miniCategory
                          ? this.state.miniCategory
                          : `${t("pleaseSelectModel")}`}
                      </p>
                      {/* <Field
                        name="miniCategory"
                        value={this.state.miniCategory}
                        as="select"
                        onChange={(e) => {
                          this.onMiniChange(e.target.value);
                        }}
                        className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                      >
                        <option value="">Select Mini Category</option>
                        {this.state.miniCategoryArray.map((el, i) => {
                          return (
                            <option key={i} value={el?.name}>
                              {el?.name}
                            </option>
                          );
                        })}
                      </Field> */}
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="miniCategory"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label
                        htmlFor="microCategory"
                        className="fs-16 fw-600 mb-1"
                      >
                        Micro {t("category")}
                      </label>
                      <p className="w-100 ps-3 input_field-height rounded-3 category-input-field">
                        {this.state.microCategory
                          ? this.state.microCategory
                          : `${t("pleaseSelectModel")}`}
                      </p>
                      {/* <Field
                        name="microCategory"
                        value={this.state.microCategory}
                        as="select"
                        onChange={(e) => {
                          this.setState({ microCategory: e.target.value });
                        }}
                        className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                      >
                        <option value="">Select Micro Category</option>
                        {this.state.microCategoryArray.map((el, i) => {
                          return (
                            <option key={i} value={el}>
                              {el}
                            </option>
                          );
                        })}
                      </Field> */}
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="microCategory"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label
                        htmlFor="retailPrice"
                        className="fs-16 fw-600 mb-1"
                      >
                        {t("retailPriceWithoutVAT")}
                      </label>
                      <p className="w-100 ps-3 input_field-height rounded-3 category-input-field">
                        {this.state.retailPrice
                          ? this.state.retailPrice
                          : `${t("pleaseSelectPrice")}`}
                      </p>
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="retailPrice"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label
                        htmlFor="yearOfManufacture"
                        className="fs-16 fw-600 mb-1"
                      >
                        Year of Manufacture
                      </label>
                      <p className="w-100 ps-3 input_field-height rounded-3 category-input-field">
                        {this.state.yearOfManufacture
                          ? this.state.yearOfManufacture
                          : `${t("pleaseSelectYear")}`}
                      </p>
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="yearOfManufacture"
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label htmlFor="colour" className="fs-16 fw-600 mb-1">
                        {t("primaryColor")}
                      </label>
                      <Field
                        name="colour"
                        value={this.state.colour}
                        as="select"
                        onChange={(e) => {
                          this.setState({ colour: e.target.value });
                        }}
                        className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                      >
                        <option value="">{t("selectColor")}</option>
                        {this.state.primaryColorOption.map((el, i) => {
                          return (
                            <option key={i} value={el}>
                              {el}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="colour"
                      />
                    </div>
                  </div> */}
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label
                        htmlFor="secondaryColour"
                        className="fs-16 fw-600 mb-1"
                      >
                        {t("secondaryColor")}
                      </label>
                      <Field
                        name="secondaryColour"
                        value={this.state.secondaryColour}
                        as="select"
                        onChange={(e) => {
                          this.setState({ secondaryColour: e.target.value });
                        }}
                        className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                      >
                        <option value="">{t("selectColor")}</option>
                        {this.state.secondaryColorOption.map((el, i) => {
                          return (
                            <option key={i} value={el}>
                              {el}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="secondaryColour"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label htmlFor="wheelSize" className="fs-16 fw-600 mb-1">
                        {t("sizeOfWheel")}
                      </label>
                      <Field
                        name="wheelSize"
                        value={this.state.wheelSize}
                        as="select"
                        onChange={(e) => {
                          this.setState({ wheelSize: e.target.value });
                        }}
                        className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                      >
                        <option value="">{t("selectSize")}</option>
                        {this.state.wheelSizeList.map((el, i) => {
                          return (
                            <option key={i} value={el}>
                              {el}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="wheelSize"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label htmlFor="frameSize" className="fs-16 fw-600 mb-1">
                        {t("sizeOfFrame")}
                      </label>
                      <Field
                        name="frameSize"
                        value={this.state.frameSize}
                        as="select"
                        onChange={(e) => {
                          this.setState({ frameSize: e.target.value });
                        }}
                        className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                      >
                        <option value="">{t("selectSize")}</option>
                        {this.state.frameSizeList.map((el, i) => {
                          return (
                            <option key={i} value={el}>
                              {el}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="frameSize"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label htmlFor="price" className="fs-16 fw-600 mb-1">
                        {t("offeredPriceWithoutVAT")}
                      </label>
                      <div className="d-flex w-100">
                        <Field
                          name="price"
                          type="text"
                          className="w-100 ps-5 input_field-height rounded-3 border-1"
                          placeholder={t("enterPrice")}
                          onChange={(e) => {
                            this.setValues("price", e.target.value);
                          }}
                          value={this.state.price}
                        />
                        <BsCurrencyEuro className="position-absolute mt-3 ms-3" />
                      </div>
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="price"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="text-left mt-2">
                      <label htmlFor="quantity" className="fs-16 fw-600 mb-1">
                        {t("quantity")}
                      </label>
                      <Field
                        name="quantity"
                        type="text"
                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                        placeholder={t("enterQuantity")}
                        onChange={(e) => {
                          this.setValues("quantity", e.target.value);
                        }}
                        value={this.state.quantity}
                      />
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="quantity"
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={this.state.isLoader}
                  className="btn btn-orange d-block m-auto my-5 rounded-pill"
                >
                  {this.state.isLoader ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </span>
                  ) : (
                    `${t("submit")}`
                  )}
                </button>
              </Form>
            </Formik>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(withTranslation()(CreateInventory));
