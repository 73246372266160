import React, { Component } from 'react'
import adminService from '../../services/admin.service';
import vendorService from '../../services/vendor.service';
import Confirmation from '../common/Confirmation';
import { Modal } from 'react-bootstrap';
import { MdDelete } from "react-icons/md";
import { toast } from 'material-react-toastify';
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import Spinner from 'react-bootstrap/Spinner';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { firebaseDate } from '../../utils/common.utils';

export default class GeneratedCoupon extends Component {
    constructor(props) {
        super(props)
        this.state = {
            AllCoupons: [],
            deleteData: null,
            couponId: '',
            couponIdError: '',
            brandName: '',
            brandNameError: '',
            couponDetails: null,
            getCouponLoader: false,
            downloadLoader: false,
            downloadUrl: ''
        }
    }
    componentDidMount() {
        // this.getData()
    }

    getData = async () => {
        adminService.getAllCoupons().then(
            res => {
                console.log(res);
                // this.setState({ AllCoupons: res,deleteData:null })
            }
        ).catch(
            err => {
                console.log(err)
                const errorCode = err.code;
                const errorMessage = err.message;
                toast.error(errorMessage)
            }
        )
    }
    deleteCoupons = (id) => {
        adminService.deleteCoupons(id).then(
            res => {
                console.log(res);
                this.getData()
                toast.success("Deleted Successfully")
            }
        ).catch(
            err => {
                console.log(err)
                const errorCode = err.code;
                const errorMessage = err.message;
                toast.error(errorMessage)
            }
        )
    }
    openConfirmation = (id) => {
        let data = {
            id: id,
            msg: 'Are you sure you want to delete this coupons'
        }
        this.setState({ deleteData: data })
    }
    getSingleCoupon = () => {
        this.setState({ couponIdError: '', getCouponLoader: true })
        vendorService.getSinglCoupon(this.state.couponId).then(
            res => {
                if (res?.data()?.company) {
                    console.log("res?.data() - ", res?.data())
                    this.setState({ couponDetails: res?.data(), getCouponLoader: false, couponId: '' })
                } else {
                    this.setState({ couponIdError: 'Invalid coupon code', getCouponLoader: false })
                }
            }
        ).catch(
            err => {
                console.log(err)
                this.setState({ getCouponLoader: false })
                const errorCode = err.code;
                const errorMessage = err.message;
                toast.error(errorMessage)
            }
        )
    }
    getSingleBrandCoupons = () => {
        this.setState({ brandNameError: '', AllCoupons: [], downloadLoader: true })
        adminService.getCouponUrl(this.state.brandName).then(
            res => {
                console.log(res.data.msg)
                if (res.data.msg) {
                    this.setState({ downloadLoader: false, downloadUrl: res?.data?.msg })
                } else {
                    toast.error(`Coupon not found for ${this.state.brandName}`)
                    this.setState({ downloadLoader: false, downloadUrl: '' })
                }
                // if (res?.length) {
                //     this.setState({ AllCoupons: res.map(el => { return el.coupon }), downloadLoader: false })
                // } else {
                //     this.setState({ brandNameError: `Coupons not generated for ${this.state.brandName}`, downloadLoader: false })
                // }
            }
        ).catch(
            err => {
                console.log(err)
                this.setState({ downloadLoader: false })
                // const errorCode = err.code;
                // const errorMessage = err.message;
                toast.error(err)
            }
        )
    }

    render() {
        return (
            <section>
                <div className='container'>
                    <h4>Generate Brand Coupons</h4>
                    <div className='right-div-item mt-4'>
                        <Link className='btn btn-outline-orange rounded-pill' to="/admin/generate-coupen">Generate Coupons</Link>
                    </div>
                </div>

                <div className='container mt-4'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div className='row'>
                                <div className='col-12'>
                                    <input type='text' value={this.state.couponId} onChange={(e) => { this.setState({ couponId: e.target.value }) }} placeholder='Enter code here...' className='w-100 ps-3 input_field-height rounded-3 border-1' />
                                    {
                                        this.state.couponIdError ?
                                            <div className='text-danger'>{this.state.couponIdError}</div>
                                            : null
                                    }
                                </div>
                                <div className='col-12 mt-2'>
                                    <button disabled={!this.state?.couponId || this.state.getCouponLoader} onClick={this.getSingleCoupon} className='btn btn-outline-orange rounded-pill'>
                                        {
                                            this.state.getCouponLoader ?
                                                <span>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    Loading...
                                                </span>
                                                :
                                                'Get Coupon details'
                                        }
                                    </button>
                                </div>
                            </div>
                            {
                                this.state.couponDetails ?
                                    <div className='row coupon-details mt-4'>
                                        <div className='col-12 coupon-details-close'>
                                            <IoIosCloseCircleOutline className="" style={{ right: '15px', top: '15px' }} onClick={() => { this.setState({ couponDetails: null }) }} />
                                        </div>
                                        <div className='col-12'>
                                            Claimed : {this.state.couponDetails?.claimed ? 'YES' : 'NO'}
                                        </div>
                                        <div className='col-12'>
                                            Company : {this.state.couponDetails?.company}
                                        </div>
                                        <div className='col-12'>
                                            Coupon : {this.state.couponDetails?.coupon}
                                        </div>
                                        <div className='col-12'>
                                            Valid Till : {firebaseDate(this.state.couponDetails?.validTill)}
                                        </div>
                                    </div>
                                    : null
                            }

                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div className='row'>
                                <div className='col-12'>
                                    <input type='text' value={this.state.brandName} onChange={(e) => { this.setState({ brandName: e.target.value }) }} placeholder='Enter brand here...' className='w-100 ps-3 input_field-height rounded-3 border-1' />
                                    {
                                        this.state.brandNameError ?
                                            <div className='text-danger'>{this.state.brandNameError}</div>
                                            : null
                                    }
                                </div>
                                <div className='col-12 mt-2'>
                                    <button disabled={!this.state?.brandName || this.state.downloadLoader} onClick={this.getSingleBrandCoupons} className='btn btn-outline-orange rounded-pill'>
                                        {
                                            this.state.downloadLoader ?
                                                <span>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    Loading...
                                                </span>
                                                :
                                                'Get Coupons'
                                        }
                                    </button>
                                </div>
                                {
                                    this.state.downloadUrl ?
                                        <div className='col-12 mt-2'>
                                            <button className='btn btn-link'>  <a href={this.state.downloadUrl} target="_blank" download>Download</a></button>
                                            {/* <button className='btn btn-link'><CSVLink data={[this.state.AllCoupons]} separator={";"} filename={`${this.state.brandName}-coupons`}>Download {this.state.brandName} coupon</CSVLink></button> */}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal centered show={this.state.deleteData} dialogClassName="">
                    <Modal.Body>
                        <Confirmation data={this.state.deleteData} delete={this.deleteCoupons} close={() => { this.setState({ deleteData: null }) }}></Confirmation>
                    </Modal.Body>
                </Modal>
                {/* <Modal centered show={this.state.couponDetails} dialogClassName="">
                    <Modal.Body>

                    </Modal.Body>
                </Modal> */}
            </section>
        )
    }
}
