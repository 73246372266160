import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { Component } from "react";
import { db } from "../../firebase";
import vendorService from "../../services/vendor.service";
import {
  firebaseDate,
  userProfile,
  vendorId,
  withRouter,
} from "../../utils/common.utils";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import DefaultImage from "../../images/no_image.jpg";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      currentMsg: "",
      senderImg: "",
      senderName: "",
    };
    this.userId = vendorId();
  }
  componentDidMount() {
    document.addEventListener("keydown", (e) => {
      if (e.code === "Enter") {
        if (this.state.currentMsg) {
          this.sendMessage();
        }
      }
    });
    this.getProfile();
    let collectionRef = collection(
      db,
      "chat",
      this.props.router?.params?.id,
      "message"
    );
    const q = query(collectionRef, orderBy("createdAt"));
    onSnapshot(q, (querySnapshot) => {
      vendorService
        .updateParentChat(this.props.router?.params?.id, true)
        .then(() => {
          this.setState(
            { messages: querySnapshot.docs.map((doc) => doc.data()) },
            () => {
              this.messagesEnd.scrollIntoView({ behavior: "smooth" });
            }
          );
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }
  getProfile = () => {
    vendorService
      .getChatProfile(this.props.router?.params?.id)
      .then((res) => {
        console.log(res.data().users);
        for (let el of res.data().users) {
          if (el.uid !== this.userId) {
            return this.setState({
              senderImg: el?.image,
              senderName: el?.name,
            });
          }
        }
        // this.setState({ messages: res })
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendMessage = () => {
    let messageObj = {
      createdAt: new Date(),
      parentChatId: this.props.router?.params?.id,
      receiverId: this.props.router?.params?.vendorId,
      senderId: this.userId,
      text: this.state.currentMsg,
    };
    vendorService
      .updateChat(messageObj, this.props.router?.params?.id)
      .then((result) => {
        // let messages = this.state.messages;
        // messages.push(messageObj)
        this.setState({ currentMsg: "" });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    return (
      <div className="h-100">
        {/* Chat Box */}
        <section className="main-user-chat h-100">
          <div className="user-chat-list">
            <div className="chat-wrapper h-100">
              <div className="row h-5 h-md-5 h-sm-10 me-0">
                <div className="col-4">
                  <div className="d-flex text-start h-100 align-items-center">
                    <IoArrowBackCircleOutline
                      className="text-white"
                      size={24}
                      onClick={() => {
                        this.props.router.navigate(`/vendor/chat-list`);
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="chat-title">Live Chat</div>
                </div>
                <div className="col-4"></div>
              </div>

              <div className="chat-view h-90 h-md-90 h-sm-80">
                {this.state.messages.map((el, i) => {
                  return (
                    <div className="chat-item" key={i}>
                      <div
                        className={`d-flex ${
                          el.senderId === this.userId
                            ? "vendor-message"
                            : "user-message"
                        }`}
                      >
                        <img
                          src={
                            el.senderId === this.userId
                              ? userProfile().image
                                ? userProfile().image
                                : DefaultImage
                              : this.state.senderImg
                              ? this.state.senderImg
                              : DefaultImage
                          }
                          alt=""
                        />
                        <div className="p-1">
                          <div className="d-flex align-items-end">
                            <div className="sender-name">
                              <a href="#">
                                {el.senderId === this.userId
                                  ? "You"
                                  : this.state.senderName}
                              </a>
                            </div>
                            <div className="chat-time">
                              {firebaseDate(el.createdAt)}
                            </div>
                          </div>
                          <div className="chat-text mt-2">{el.text}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div
                  className="row"
                  style={{ height: "20px" }}
                  ref={(el) => {
                    this.messagesEnd = el;
                  }}
                ></div>
              </div>
              <div className="chat-message h-5 h-md-5 h-sm-10">
                <form action="#" className="h-100">
                  <div className="input-group align-items-center h-100">
                    <input
                      type="text"
                      value={this.state.currentMsg}
                      onChange={(e) => {
                        this.setState({ currentMsg: e.target.value });
                      }}
                      className="form-control"
                      placeholder="Type something..."
                    />
                    <div className="input-group-append">
                      <button
                        disabled={!this.state.currentMsg}
                        className=""
                        type="button"
                        onClick={this.sendMessage}
                        id="button-addon2"
                      >
                        <img
                          src="https://github.com/suryavmds/Live-chat-HTML-design--like-YT-chat-/blob/master/assets/img/send-btn.svg?raw=true"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(Chat);
