import { Component } from 'react'
import { Outlet, Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import Confirmation from '../common/Confirmation';
import authService from '../../services/auth.service';
import { toast } from 'material-react-toastify';
import { withRouter } from '../../utils/common.utils';
import logo from '../../images/logo.png';

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Row, Col, NavDropdown } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { IoShareSocialSharp } from 'react-icons/io5';
import { AiOutlineUpload } from 'react-icons/ai';
import { RiCoupon2Fill } from 'react-icons/ri'
import { MdPrecisionManufacturing } from 'react-icons/md';
import { IoIosLogOut, IoMdPerson } from 'react-icons/io';


class Admin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logoutData: null,
      activeTab: 'vendors'
    }
  }

  componentDidMount() {
  }
  openConfirmation = () => {
    let data = {
      id: 'ee',
      msg: 'Are you sure you want to logout'
    }
    this.setState({ logoutData: data })
  }
  logout = (id) => {
    authService.signOut()
      .then((userCredential) => {
        this.setState({ logoutData: null })
        localStorage.clear()
        this.props.router.navigate('/')
        toast.success('Logout Successfully')
      })
      .catch((error) => {
        console.log(error.message + ' - ' + error.code)
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
        // ..
      });
  }

  updateActiveTab = (key) => {
    this.setState({activeTab : key})
  }

  render() {
    return (
      <div className='row mx-0'>
        <div className='col-lg-2 col-12 px-0'>
          {/* <nav className="navbar navbar-expand-lg navbar-light bg-black px-3 d-lg-block admin_panel-navbar">
            <Link className="navbar-brand admin_navbar-brand" to="/admin"><img src={logo} className="admin-logo" alt="..." /></Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav d-lg-block text-start w-100">
                <Link className="nav-item nav-link border-bottom border-dark text-white" to="/admin/vendor-list">Vendors</Link>
                <Link className="nav-item nav-link border-bottom border-dark text-white" to="/admin/manufacturer-list">Manufacturer</Link>
                <Link className="nav-item nav-link border-bottom border-dark text-white" to="/admin/generated-coupon">Coupons</Link>
                <Link className="nav-item nav-link border-bottom border-dark text-white" to="/admin/upload-images">Upload Images</Link>
                <Link className="nav-item nav-link border-bottom border-dark text-white" to="/admin/social-links">Social Links</Link>

                <button className="btn btn-outline-light admin-logout-btn" type="button" onClick={this.openConfirmation}>Logout</button>
              </div>
AAA
            </div>
          </nav> */}

          <section className='navbar-section bg-black'>
            <div className='container-fluid'>
              <Navbar collapseOnSelect expand="lg" variant="dark" className='admin-navbar d-lg-block'>
                <Link className="navbar-brand admin_navbar-brand" to="/admin"><img src={logo} className="admin-logo" alt="..." /></Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto d-flex justify-content-between flex-lg-column w-100 text-start">
                    <NavDropdown.Item className=""><Link className={`NavDropdown_Item ${this.state.activeTab === 'vendors' ? 'admin-navItem' : ''  }`} to="/admin/vendor-list" onClick={()=> this.updateActiveTab('vendors')}><IoMdPerson className='me-3' />Vendors</Link></NavDropdown.Item>
                    <NavDropdown.Item className=""><Link className={`NavDropdown_Item ${this.state.activeTab === 'manufacturer' ? 'admin-navItem' : ''  }`} to="/admin/manufacturer-list" onClick={()=> this.updateActiveTab('manufacturer')}><MdPrecisionManufacturing className='me-3' />Manufacturer </Link></NavDropdown.Item>
                    <NavDropdown.Item className=""><Link className={`NavDropdown_Item ${this.state.activeTab === 'coupons' ? 'admin-navItem' : ''  }`} to="/admin/generated-coupon" onClick={()=> this.updateActiveTab('coupons')}><RiCoupon2Fill className='me-3' />Coupons</Link></NavDropdown.Item>
                    <NavDropdown.Item className=""><Link className={`NavDropdown_Item ${this.state.activeTab === 'uploadImages' ? 'admin-navItem' : ''  }`} to="/admin/upload-images" onClick={()=> this.updateActiveTab('uploadImages')}><AiOutlineUpload className='me-3' />Upload Images</Link></NavDropdown.Item>
                    <NavDropdown.Item className=""><Link className={`NavDropdown_Item ${this.state.activeTab === 'socialMedia' ? 'admin-navItem' : ''  }`} to="/admin/social-links" onClick={()=> this.updateActiveTab('socialMedia')}><IoShareSocialSharp className='me-3' />Social Links</Link></NavDropdown.Item>
                    <NavDropdown.Item className=""><button className="btn p-0 text-white" type="submit" onClick={this.openConfirmation}><IoIosLogOut className='me-3' /> Logout </button> </NavDropdown.Item>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </section>
        </div>
        <div className='col-lg-10 col-12 px-lg-0'>
          <div className='mt-5'>
            <Outlet />
          </div>

        </div>
        <Modal centered show={this.state.logoutData} dialogClassName="">
          <Modal.Body>
            <Confirmation data={this.state.logoutData} delete={this.logout} close={() => { this.setState({ logoutData: null }) }}></Confirmation>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
export default withRouter(Admin)