import { db } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  startAt,
  startAfter,
} from "firebase/firestore";
import { vendorId } from "../utils/common.utils";
const catlogCollectionRef = collection(db, "vendorCatlogs");
const requestsCollectionRef = collection(db, "requests");
let lastDoc = null;
let currentPage = 0;
let allSnaps = [];

class VendorService {
  addProfile = (data, id) => {
    const coupenRef = doc(db, "profiles", id);
    return setDoc(coupenRef, data, { capital: true }, { merge: true });
  };
  getSingleProfile = (id) => {
    const bookDoc = doc(db, "profiles", id);
    return getDoc(bookDoc);
  };
  updateProfile = (data, id) => {
    const docRef = doc(db, "profiles", id);
    return updateDoc(docRef, data);
  };
  searchVendors = async (key) => {
    const q = query(
      collection(db, "profiles"),
      where(`name`, ">=", key),
      where(`name`, "<=", `${key}\uf7ff`)
    );

    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  getAllProfiles = async (page) => {
    console.log("page - ", page);
    let q;

    if (page === 1) {
      q = query(collection(db, "profiles"), orderBy("name"), limit(10));
      allSnaps = [];
    } else {
      if (currentPage < page) {
        q = query(
          collection(db, "profiles"),
          orderBy("name"),
          startAfter(lastDoc),
          limit(10)
        );
      } else {
        q = query(
          collection(db, "profiles"),
          orderBy("name"),
          startAt(allSnaps[page - 1]),
          limit(10)
        );
      }
    }
    const querySnapshot = await getDocs(q);
    let data = [];
    let tempSnap = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
      tempSnap.push(doc);
      lastDoc = doc;
    });
    if (currentPage < page) {
      allSnaps.push(tempSnap[0]);
    } else {
      allSnaps.splice(page - 1, 1);
    }
    console.log("allSnaps - ", allSnaps);
    currentPage = page;

    return data;
  };
  getBrands = (vendorId) => {
    const bookDoc = doc(db, "vendorsBrands", vendorId);
    let data = getDoc(bookDoc);
    console.log(data);
    return data;
  };
  registerBrand = (vendorId, data) => {
    const coupenRef = doc(db, "vendorsBrands", vendorId);
    return setDoc(coupenRef, data, { capital: true }, { merge: true });
  };
  getSinglCoupon = (id) => {
    const bookDoc = doc(db, "coupons", id);
    return getDoc(bookDoc);
  };
  updateCoupon = (data, id) => {
    const coupenRef = doc(db, "coupons", id);
    return setDoc(coupenRef, data, { capital: true }, { merge: true });
  };
  addVendorCatlog = (catlog) => {
    return addDoc(catlogCollectionRef, catlog);
  };
  updateVendorCatlog = (data, id) => {
    const docRef = doc(db, "vendorCatlogs", id);
    return updateDoc(docRef, data);
  };
  getAllCatlog = async (id) => {
    const q = query(
      collection(db, "vendorCatlogs"),
      where(`vendor`, "==", id),
      where(`expired`, "==", false)
    );
    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  getBrandCatalog = async (id, brand) => {
    console.log(brand);
    const q = query(
      collection(db, "vendorCatlogs"),
      where(`vendor`, "==", id),
      where(`brand`, "==", brand)
    );
    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  getSingleCatelogs(id) {
    const bookDoc = doc(db, "vendorCatlogs", id);
    return getDoc(bookDoc);
  }
  deleteCatelog = (id) => {
    const bookDoc = doc(db, "vendorCatlogs", id);
    return deleteDoc(bookDoc);
  };
  registerVendorInBrand = (data, id) => {
    const docRef = doc(db, "brandsVendors", id);
    return updateDoc(docRef, data);
  };
  sendRequest = (data) => {
    return addDoc(requestsCollectionRef, data);
  };
  updateRequest = (data, id) => {
    const docRef = doc(db, "requests", id);
    return updateDoc(docRef, data);
  };
  getAllRequests = async (id) => {
    const q = query(
      collection(db, "requests"),
      where(`requestTo.${id}.vendorId`, "==", id)
    );

    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  getAllOutRequests = async (id) => {
    const q = query(collection(db, "requests"), where(`requestBy`, "==", id));

    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  getBrandVendor = (id) => {
    console.log(id);
    const bookDoc = doc(db, "brandsVendors", id);
    return getDoc(bookDoc);
  };
  getChats(id) {
    const bookDoc = doc(db, "chat", id);
    return getDoc(bookDoc);
  }
  addChat = (data, id) => {
    const coupenRef = doc(db, "chat", id);
    return setDoc(coupenRef, data, { capital: true }, { merge: true });
  };
  updateChat = async (message, chatId) => {
    const docRef = doc(db, "chat", chatId);
    const colRef = collection(docRef, "message");
    await this.updateParentChat(chatId, false);
    return addDoc(colRef, message);
  };
  getChatProfile(id) {
    const bookDoc = doc(db, "chat", id);
    return getDoc(bookDoc);
  }
  getMessages = async (docId) => {
    let collectionRef = collection(db, "chat", docId, "message");
    const q = query(collectionRef, orderBy("createdAt"));

    // const querySnapshot = await getDocs(q);
    // let data = []
    // await querySnapshot.forEach((doc) => {
    //     let temp = doc.data()
    //     temp.id = doc.id
    //     data.push(temp)
    // });
    // return data
    let data = [];
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let temp = doc.data();
        temp.id = doc.id;
        data.push(temp);
      });
    });
    return data;
  };
  getChatList = async (docId) => {
    const q = query(
      collection(db, "chat"),
      where("uids", "array-contains", docId)
    );

    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();

      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  updateParentChat = async (chatDocId, isCountZero) => {
    const currentUserId = vendorId();
    let chat = await (await getDoc(await doc(db, "chat", chatDocId))).data();
    let index;
    chat.users.forEach((e) => {
      if (isCountZero) {
        if (e.uid !== currentUserId) {
          index = chat.users.indexOf(e);
        }
      } else {
        if (e.uid === currentUserId) {
          index = chat.users.indexOf(e);
        }
      }
    });
    let chatUser = chat.users[index];
    chatUser.unReadCount = isCountZero ? 0 : chatUser.unReadCount + 1;
    chat.users[index] = chatUser;
    if (!isCountZero) {
      chat.updatedAt = new Date();
    }
    const docRef = doc(db, "chat", chatDocId);
    setDoc(docRef, chat, { capital: true }, { merge: true });
  };
  getBrandProduct = async (brand, country) => {
    const q = query(
      collection(db, "manufacturer"),
      where(`brand`, "==", brand ? brand : ""),
      where(`countryList`, "array-contains", country ? country : "Italy")
    );

    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  getSingleProduct = async (params) => {
    const q = query(
      collection(db, "manufacturer"),
      where(`brand`, "==", params.brand ? params.brand : ""),
      where(`label`, "==", params.label ? params.label : ""),
      where(
        `macroCategory`,
        "==",
        params.macroCategory ? params.macroCategory : ""
      ),
      where(
        `microCategory`,
        "==",
        params.microCategory ? params.microCategory : ""
      ),
      where(
        `miniCategory`,
        "==",
        params?.miniCategory ? params?.miniCategory : ""
      ),
      where(`subCategory`, "==", params?.subCategory ? params?.subCategory : "")
    );

    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  getAllProduct = async (params) => {
    const q = query(
      collection(db, "manufacturer"),
      where(
        `macroCategory`,
        "==",
        params.macroCategory ? params.macroCategory : ""
      ),
      where(
        `microCategory`,
        "==",
        params.microCategory ? params.microCategory : ""
      ),
      where(
        `miniCategory`,
        "==",
        params?.miniCategory ? params?.miniCategory : ""
      ),
      where(`subCategory`, "==", params?.subCategory ? params?.subCategory : "")
    );

    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  vendorByLabel = async (params) => {
    const q = query(
      collection(db, "vendorCatlogs"),
      where(`label`, "==", params.label),
      where(`brand`, "==", params.brand),
      where(`macroCategory`, "==", params.macroCategory),
      where(`microCategory`, "==", params.microCategory),
      where(`miniCategory`, "==", params.miniCategory),
      where(`subCategory`, "==", params.subCategory),
      where(`expired`, "==", false)
    );

    const querySnapshot = await getDocs(q);
    let array = [];
    await querySnapshot.forEach((doc) => {
      const model = doc.data();
      model.id = doc.id;
      console.log(model);
      array.push({ ...model });
    });
    console.log(array);
    return array;
  };
  getAllProfilesByUid = async (list, isAvailable) => {
    console.log(list);
    if (!list.length) {
      return [];
    }
    var q;
    if (isAvailable != null) {
      q = query(
        collection(db, "profiles"),
        where(`uid`, "in", list),
        where(`isAvailable`, "==", isAvailable)
      );
    } else {
      q = query(collection(db, "profiles"), where(`uid`, "in", list));
    }
    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  getAllProfilesByUidForProduct = async (list, isAvailable) => {
    console.log(list);
    if (!list.length) {
      return [];
    }
    let data = [];
    for (const id of list) {
      console.log(id);
      var q = query(doc(db, "profiles", id));
      const docu = await getDoc(q);
      let temp = docu.data();
      temp.id = docu.id;
      if (isAvailable == null) {
        console.log(temp);
        data.push(temp);
      } else {
        if (isAvailable == temp.isAvailable) {
          data.push(temp);
        }
      }
    }
    console.log(data);
    return data;
  };
}

export default new VendorService();
