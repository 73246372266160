import { Component } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';


export default class RequestDetailsModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <div className='container mt-1'>
                <IoIosCloseCircleOutline className="position-absolute" style={{ right: '15px', top: '15px' }} onClick={this.props.close} />
                <h4 className='text-center'>Profile</h4>
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: '',
                        addressline1: '',
                        addressline2: '',
                        zipCode: '',
                        country: 'Italy',
                        phoneNumber: '',
                        whatsappNumber: '',
                        vendorLink: '',
                        vatNumber: '',
                        email: '',
                    }}
                    validationSchema={Yup.object({

                    })}
                    onSubmit={(values, { setSubmitting }) => {

                    }}
                >
                    <Form className="mt-1" autoComplete="off">
                        <div className='row'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-lg-12 col-12'>
                                        <div className="text-left mt-2">
                                            <label htmlFor="name" className="fs-16 fw-600 mb-1">Name of Vendor</label>
                                            <Field
                                                name="name" type="text"
                                                value={this.props.data?.name}
                                                className="w-100 ps-3 input_field-height rounded-3 border-1"
                                                placeholder="Enter Vendor name" disabled={true} />
                                        </div>
                                    </div>
                                    {/* <div className='col-lg-6 col-6'>
                                        <div className="text-left mt-2">
                                            <label htmlFor="email" className="fs-16 fw-600 mb-1">Name of Vendor</label>
                                            <Field
                                                name="email" type="text"
                                                value=''
                                                className="w-100 ps-3 input_field-height rounded-3 border-1"
                                                placeholder="Enter Vendor name" disabled={true} />
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            <div className='col-lg-12 col-md-12 col-12 my-4'>
                                <div style={{ width: '100%', height: '15px', borderBottom: '1px solid grey', textAlign: 'center' }}>
                                    <span style={{ fontSize: '18px', backgroundColor: 'white', padding: '0 10px' }}>
                                        Address of Vendor
                                    </span>
                                </div>

                            </div>
                            <div className='col-12'>
                                <div className="text-left">
                                    <label htmlFor="addressline1" className="fs-16 fw-600 mb-1">Addres Line 1</label>
                                    <Field
                                        name="addressline1" type="text"
                                        value={this.props.data?.addressline1}
                                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                                        placeholder="Enter Vendor name" disabled={true} />
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="text-left mt-lg-0 mt-md-0 mt-2">
                                    <label htmlFor="addressline2" className="fs-16 fw-600 mb-1">Addres Line 2</label>
                                    <Field
                                        name="addressline2" type="text"
                                        value={this.props.data?.addressline2}
                                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                                        placeholder="Enter Vendor name" disabled={true} />
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className="text-left mt-2">
                                    <label htmlFor="zipCode" className="fs-16 fw-600 mb-1">Zip Code</label>
                                    <Field
                                        name="zipCode" type="text"
                                        value={this.props.data?.zipCode}
                                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                                        placeholder="Enter Zip Code" disabled={true} />
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className="text-left mt-2">
                                    <label htmlFor="country" className="fs-16 fw-600 mb-1">Contry</label>
                                    <Field
                                        name="zipCode" type="text"
                                        value={this.props.data?.country}
                                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                                        placeholder="Enter Zip Code" disabled={true} />
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-12 my-4'>
                                <div style={{ width: '100%', height: '15px', borderBottom: '1px solid grey', textAlign: 'center' }}>
                                    <span style={{ fontSize: '18px', backgroundColor: 'white', padding: '0 10px' }}>
                                        Contact Details
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className="text-left">
                                    <label htmlFor="phoneNumber" className="fs-16 fw-600 mb-1">Phone No.</label>
                                    <Field
                                        name="phoneNumber" type="text"
                                        value={this.props.data?.phoneNumber}
                                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                                        placeholder="Enter Phone No." disabled={true} />
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className="text-left">
                                    <label htmlFor="whatsappNumber" className="fs-16 fw-600 mb-1">Whatsapp No.</label>
                                    <Field
                                        name="whatsappNumber" type="text"
                                        value={this.props.data?.whatsappNumber}
                                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                                        placeholder="Enter Whatsapp No." disabled={true} />
                                </div>
                            </div>

                            {/*  */}
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className="text-left mt-2">
                                    <label htmlFor="vendorLink" className="fs-16 fw-600 mb-1">Link of Vendor</label>
                                    <Field
                                        name="vendorLink" type="text"
                                        value={this.props.data?.vendorLink}
                                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                                        placeholder="Enter Vendor link" disabled={true} />
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className="text-left mt-2">
                                    <label htmlFor="vatNumber" className="fs-16 fw-600 mb-1">Enter VAT No. **</label>
                                    <Field
                                        name="vatNumber" type="text"
                                        value={this.props.data?.vatNumber}
                                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                                        placeholder="Enter VAT No." disabled={true} />
                                </div>
                            </div>
                        </div>
                        <button type="button" className="btn btn-orange d-block m-auto mt-4 mb-2 rounded-pill" onClick={this.props.close}>
                            Close
                        </button>
                    </Form>
                </Formik>
            </div>
        )
    }
}
