import { Component } from 'react';
//CSS
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'material-react-toastify/dist/ReactToastify.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import './i18n/config';

import { ToastContainer } from 'material-react-toastify';
import MainRoutes from './routes/MainRoutes';
import authService from './services/auth.service';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { userProfile } from './utils/common.utils';

class App extends Component {
  constructor() {
    super()
    this.state = {
      isLoader: false,
      emailVerified: false
    }
  }
  componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      console.log("user - ", user)
      if (user) {
        let storage = userProfile()
        if (storage) {
          storage.emailVerified = user?.emailVerified
          this.setState({ emailVerified: user?.emailVerified })
          localStorage.setItem('user', JSON.stringify(storage))
        }

        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
  }
  setData = (data) => {
    console.log(data)
  }
  loaderChange = (key) => {
    this.setState({ isLoader: key })
  }

  render() {
    return (
      <div className="App">
        <MainRoutes emailVerified={this.state.emailVerified} loaderChange={this.loaderChange} />
        <div>
          <ToastContainer></ToastContainer>
        </div>
        {
          this.state.isLoader ?
            <div>
              <div className="application-loading-initial">
                {/* <img src={finvu} style={{ width: '109px', height: '60px', marginTop: '15px' }} />
            <p className="label-size" style={{ marginTop: '20px', fontSize: '19px' }}>We are checking your registration status with Finvu Account Aggregator (RBI licensed). Please wait...</p>
            <div style={{ color: '#f90e1a', textAlign: 'left', fontSize: '21px' }}><b>DO NOT hit REFRESH or BACK</b></div>
            <br /> */}
                <div className="snippet" data-title=".dot-pulse">
                  <div className="stage">
                    <div className="dot-pulse"></div>
                  </div>
                </div>
              </div>
            </div> : null
        }

      </div>
    );
  }

}

export default App;