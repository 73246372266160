import { db } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  writeBatch,
  query,
  where,
  orderBy,
  limit,
  startAt,
  startAfter,
} from "firebase/firestore";
import * as axios from "axios";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const catlogCollectionRef = collection(db, "manufacturer");
const couponCollectionRef = collection(db, "coupons");
const brandCollectionRef = collection(db, "brandsVendors");
let lastDoc = null;
let currentPage = 0;
let allSnaps = [];
const auth = getAuth();

class AdminService {
  addManufacturer = (catlog) => {
    return addDoc(catlogCollectionRef, catlog);
  };

  getAllCategories() {
    const bookDoc = doc(db, "masterData", "masterDataAdmin");
    return getDoc(bookDoc);
  }
  getAllCities() {
    const bookDoc = doc(db, "masterData", "cities");
    return getDoc(bookDoc);
  }

  getProfileCount() {
    const bookDoc = doc(db, "masterData", "profileCount");
    return getDoc(bookDoc);
  }
  getmanufactureCount() {
    const bookDoc = doc(db, "masterData", "manufactureCount");
    return getDoc(bookDoc);
  }
  getCompleteProfileCount() {
    const bookDoc = doc(db, "masterData", "completeProfileCount");
    return getDoc(bookDoc);
  }
  async getAllCatelogs(page) {
    let q;
    console.log("page - ", page);
    if (page === 1) {
      q = query(collection(db, "manufacturer"), orderBy("brand"), limit(10));
      allSnaps = [];
    } else {
      if (currentPage < page) {
        q = query(
          collection(db, "manufacturer"),
          orderBy("brand"),
          startAfter(lastDoc),
          limit(10)
        );
      } else {
        q = query(
          collection(db, "manufacturer"),
          orderBy("brand"),
          startAt(allSnaps[page - 1]),
          limit(10)
        );
      }
    }
    const querySnapshot = await getDocs(q);
    let data = [];
    let tempSnap = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
      tempSnap.push(doc);
      lastDoc = doc;
    });
    if (currentPage < page) {
      allSnaps.push(tempSnap[0]);
    } else {
      allSnaps.splice(page - 1, 1);
    }
    console.log("allSnaps - ", allSnaps);
    currentPage = page;

    return data;
  }
  async getBrandCatelogs(page, brand) {
    let q;
    console.log("page - ", page);
    console.log("brand-", brand);
    if (page === 1) {
      q = query(
        collection(db, "manufacturer"),
        where(`brand`, "==", brand ? brand : ""),
        limit(10)
      );
      allSnaps = [];
    } else {
      if (currentPage < page) {
        q = query(
          collection(db, "manufacturer"),
          where(`brand`, "==", brand ? brand : ""),
          startAfter(lastDoc),
          limit(10)
        );
      } else {
        q = query(
          collection(db, "manufacturer"),
          where(`brand`, "==", brand ? brand : ""),
          startAt(allSnaps[page - 1]),
          limit(10)
        );
      }
    }
    const querySnapshot = await getDocs(q);
    let data = [];
    let tempSnap = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
      tempSnap.push(doc);
      lastDoc = doc;
    });
    if (currentPage < page) {
      allSnaps.push(tempSnap[0]);
    } else {
      allSnaps.splice(page - 1, 1);
    }
    console.log("allSnaps - ", allSnaps);
    currentPage = page;

    return data;
  }
  async getAllBrands() {
    const querySnapshot = await getDocs(brandCollectionRef);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp = doc.id;
      data.push(temp);
    });
    return data;
  }
  async resendPassword() {
    sendPasswordResetEmail(auth, "shubhamvrox@gmail.com")
      .then(() => {
        console.log("sentttt - ");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  }

  getSingleCatelogs(id) {
    const bookDoc = doc(db, "manufacturer", id);
    return getDoc(bookDoc);
  }
  deleteCatelog(id) {
    const bookDoc = doc(db, "manufacturer", id);
    return deleteDoc(bookDoc);
  }
  updateCatelog = (id, updatedCatelog) => {
    const bookDoc = doc(db, "manufacturer", id);
    return updateDoc(bookDoc, updatedCatelog);
  };
  searchLabels = async (key) => {
    const q = query(
      collection(db, "manufacturer"),
      where(`label`, ">=", key),
      where(`label`, "<=", `${key}\uf7ff`)
    );

    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  addCoupons = (data, brand) => {
    const coupenRef = doc(db, "coupons", brand);
    return setDoc(coupenRef, data, { capital: true }, { merge: true });
  };
  brandExistTest(id) {
    const bookDoc = doc(db, "brandsVendors", id);
    return getDoc(bookDoc);
  }
  addBrandListing = (id) => {
    const docRef = doc(db, "brandsVendors", id);
    return setDoc(docRef, {});
  };
  addMultipleCoupons = async (coupons) => {
    const batch = writeBatch(db);
    for (let item of coupons) {
      const coupenRef = doc(db, "coupons", item.coupon);
      batch.set(coupenRef, item);
    }

    return batch.commit();
  };
  getBrandCoupons(id) {
    const bookDoc = doc(db, "coupons", id);
    return getDoc(bookDoc);
  }
  async getAllCoupons() {
    const querySnapshot = await getDocs(couponCollectionRef);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  }
  getSingleBrandsCoupons = async (name) => {
    const q = query(
      collection(db, "coupons"),
      where(`company`, "==", name),
      where(`claimed`, "==", false)
    );

    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  deleteCoupons(id) {
    const bookDoc = doc(db, "coupons", id);
    return deleteDoc(bookDoc);
  }
  updateMasterData = (data) => {
    const docRef = doc(db, "masterData", "masterDataAdmin");
    return updateDoc(docRef, data);
  };
  getAllVendors = async (page, isAvailable) => {
    let q;
    console.log("page - ", page);
    console.log(isAvailable);
    if (page === 1) {
      q = query(
        collection(db, "profiles"),
        where(`role`, "==", "vendor"),
        where(`isProfile`, "==", true),
        orderBy("name"),
        limit(12),
        isAvailable != null
          ? where(`isAvailable`, "==", isAvailable)
          : where(`isAvailable`, "in", [true, false])
      );
      allSnaps = [];
    } else {
      if (currentPage < page) {
        q = query(
          collection(db, "profiles"),
          where(`role`, "==", "vendor"),
          where(`isProfile`, "==", true),
          orderBy("name"),
          startAfter(lastDoc),
          limit(12),
          isAvailable != null
            ? where(`isAvailable`, "==", isAvailable)
            : where(`isAvailable`, "in", [true, false])
        );
      } else {
        q = query(
          collection(db, "profiles"),
          where(`role`, "==", "vendor"),
          where(`isProfile`, "==", true),
          orderBy("name"),
          startAt(allSnaps[page - 1]),
          limit(12),
          isAvailable != null
            ? where(`isAvailable`, "==", isAvailable)
            : where(`isAvailable`, "in", [true, false])
        );
      }
    }

    const querySnapshot = await getDocs(q);
    let data = [];
    let tempSnap = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
      tempSnap.push(doc);
      lastDoc = doc;
    });
    if (currentPage < page) {
      allSnaps.push(tempSnap[0]);
    } else {
      allSnaps.splice(page - 1, 1);
    }
    console.log("allSnaps - ", allSnaps);
    currentPage = page;

    return data;
  };
  getSingleVendor = (id) => {
    const bookDoc = doc(db, "profiles", id);
    return getDoc(bookDoc);
  };
  filterVenderByCity = async (city, isAvailable) => {
    const q = query(
      collection(db, "profiles"),
      where(`role`, "==", "vendor"),
      where(`city`, "==", city),
      where(`isProfile`, "==", true)
    );
    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  filterVenderByCityNBrand = async (city, vendors, isAvailable) => {
    const q = query(
      collection(db, "profiles"),
      where(`role`, "==", "vendor"),
      where(`city`, "==", city),
      where(`uid`, "in", vendors),
      where(`isProfile`, "==", true),
      isAvailable != null ? where(`isAvailable`, "==", isAvailable) : null
    );
    const querySnapshot = await getDocs(q);
    let data = [];
    await querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp.id = doc.id;
      data.push(temp);
    });
    return data;
  };
  getCouponUrl = (name) => {
    return axios.post(
      "https://us-central1-bikeconnect-7eb31.cloudfunctions.net/excelExports",
      {
        company: name,
      }
    );
    // .then((response) => {
    //     console.log("ffffff - ", response.data);
    //     // Handle data
    // })
    // .catch((error) => {
    //     console.log(error);
    // })
  };
}
export default new AdminService();
