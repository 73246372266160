import { Component } from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io'
export default class Confirmation extends Component {
    render() {
        return (
            <div className='p-3'>
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={this.props.close} />
                <div>
                    <p className='text-center'>{this.props?.data?.msg}</p>
                </div>
                <div className='d-flex justify-content-center'>
                    <button className='btn btn-orange rounded-pill me-2 px-4' onClick={() => { this.props.delete(this.props?.data?.id) }} >YES</button>
                    <button className='btn btn-orange rounded-pill ms-2 px-4' onClick={this.props.close}>NO</button>
                </div>
            </div>
        )
    }
}
