import { Component } from "react";
import { Modal } from "react-bootstrap";
import vendorService from "../../services/vendor.service";
import { userName, vendorId } from "../../utils/common.utils";
import CreateManageRequestModal from "./CreateManageRequestModal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "material-react-toastify";
import RequestDetailsModal from "./RequestDetailsModal";
import check from "../../images/check.png";
import { withTranslation } from "react-i18next";
class CreateManageRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createRequestModal: false,
      requestDetailsModal: false,
      brands: [],
      requests: [],
      outgoiningReq: [],
      reqType: "incommining",
      updateI: null,
      isLoader: false,
      updateStatus: "",
      vendorDetails: null,
    };
    this.vendorId = vendorId();
    this.userName = userName();
  }
  componentDidMount() {
    this.getBrands();
  }

  getBrands = async () => {
    this.props.loaderChange(true);
    vendorService
      .getBrands(this.vendorId)
      .then((res) => {
        var data = res?.data().brands ? res?.data().brands : [];
        console.log(data);
        data = data.filter(function (el) {
          return !el.delivered;
        });

        this.setState({ brands: data });
        this.getAllRequests();
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);       const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  getAllRequests = () => {
    this.props.loaderChange(true);
    vendorService
      .getAllRequests(this.vendorId)
      .then((res) => {
        console.log(res);
        let requests = res ? res : [];
        this.setState({ requests: requests, reqType: "incommining" });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);       const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  getAllOutRequests = () => {
    this.props.loaderChange(true);
    vendorService
      .getAllOutRequests(this.vendorId)
      .then((res) => {
        console.log(res);
        let requests = res ? res : [];
        this.setState({ outgoiningReq: requests, reqType: "outGoining" });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);       const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  updateReq = (status, i) => {
    this.setState({ updateI: i, isLoader: true, updateStatus: status });
    let requests = this.state.requests;
    let changeReq = this.state.requests[i];
    let reqId = changeReq?.id;
    changeReq.requestTo[this.vendorId].status = status;
    changeReq.requestTo[this.vendorId].vendorName = this.userName;
    requests[i] = changeReq;
    vendorService
      .updateRequest(changeReq, reqId)
      .then((res) => {
        console.log(res);
        this.setState({ requests: requests, isLoader: false });
        toast.success(status);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoader: false });       const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  // isAcceptedCheck = async (data) => {
  //     let acceptedArr = await Object.values(data).map(el => { return el.status })
  //     console.log(acceptedArr)
  //     if (acceptedArr.includes("rejected")) {
  //         return true
  //     }
  //     return false
  // }
  viewDetails = (id) => {
    this.setState({ detailsLoader: true });
    vendorService
      .getSingleProfile(id)
      .then((userCredential) => {
        this.setState({ vendorDetails: userCredential.data() });
        this.setState({ detailsLoader: false });
      })
      .catch((error) => {
        console.log(error.message + " - " + error.code);
        this.setState({ detailsLoader: false });
        // ..
      });
  };
  render() {
    const { t } = this.props;
    return (
      <section className="container">
        <div className="row">
          <div className="col-12 mt-5 mb-3">
            <h4 className="text-center">{t("createManageRequests")}</h4>
          </div>
          <div className="col-12">
            <div className="text-end mb-3">
              <button
                className="btn btn-outline-orange rounded-pill"
                onClick={() => {
                  this.setState({ createRequestModal: true });
                }}
              >
                {t("sendRequest")}
              </button>
            </div>
          </div>
          <div className="col-10 offset-1 mb-2">
            <div className="row request-toggle">
              <div
                className={`col-6 py-1 cursor-pointer incoming-btn ${
                  this.state.reqType === "incommining" ? "active-request" : ""
                }`}
                onClick={this.getAllRequests}
              >
                {t("incomingRequests")}
              </div>
              <div
                className={`col-6 py-1 cursor-pointer outgoing-btn ${
                  this.state.reqType === "outGoining" ? "active-request" : ""
                }`}
                onClick={this.getAllOutRequests}
              >
                {t("outgoingRequests")}
              </div>
            </div>
          </div>
          {this.state.reqType === "incommining" ? (
            <div className="col-lg-10 offset-lg-1 col-12">
              <div className="table-responsive">
                <table className="table">
                  {this.state.requests.length > 0 ? (
                    <thead></thead>
                  ) : (
                    <span>{t("noIncomingRequests")}</span>
                  )}
                  <tbody>
                    {this.state.requests.map((el, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">{i + 1}</th>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.requestedName} has requested you{" "}
                              {el?.requestData?.componentName}
                            </p>
                          </td>
                          <td>
                            {!el.requestTo[this.vendorId].status ? (
                              <div className="d-flex">
                                <button
                                  disabled={
                                    this.state.isLoader &&
                                    this.state.updateI === i
                                  }
                                  className="btn btn-outline-success rounded-pill me-3"
                                  onClick={() => {
                                    this.updateReq("accepted", i);
                                  }}
                                >
                                  {this.state.isLoader &&
                                  this.state.updateI === i &&
                                  this.state.updateStatus === "accepted" ? (
                                    <span>
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                      Loading...
                                    </span>
                                  ) : (
                                    "Accept"
                                  )}
                                </button>
                                <button
                                  disabled={
                                    this.state.isLoader &&
                                    this.state.updateI === i
                                  }
                                  className="btn btn-outline-danger rounded-pill"
                                  onClick={() => {
                                    this.updateReq("rejected", i);
                                  }}
                                >
                                  {this.state.isLoader &&
                                  this.state.updateI === i &&
                                  this.state.updateStatus === "rejected" ? (
                                    <span>
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                      Loading...
                                    </span>
                                  ) : (
                                    "Reject"
                                  )}
                                </button>
                              </div>
                            ) : (
                              <div className="d-flex">
                                {el.requestTo[this.vendorId].status ===
                                "accepted" ? (
                                  <button
                                    disabled={true}
                                    className="btn btn-outline-success rounded-pill me-3"
                                  >
                                    Accepted
                                  </button>
                                ) : (
                                  <button
                                    disabled={true}
                                    className="btn btn-outline-danger rounded-pill"
                                  >
                                    Rejected
                                  </button>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="col-lg-10 offset-lg-1 col-12">
              <div className="accordion" id="accordionExample">
                {this.state.outgoiningReq.map((el, i) => {
                  return (
                    <div className="accordion-item" key={i}>
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne${i}`}
                          aria-expanded="true"
                          aria-controls={`collapseOne${i}`}
                        >
                          Brand - {el?.requestData?.componentBrand} Component -{" "}
                          {el?.requestData?.componentName} Quantity -{" "}
                          {el?.requestData?.quantity} per unit price -{" "}
                          {el?.requestData?.pricePerUnit}
                        </button>
                      </h2>
                      <div
                        id={`collapseOne${i}`}
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          {Object.values(el.requestTo).map((res, j) => {
                            if (res.status === "rejected") {
                              return (
                                <div className="row" key={j}>
                                  <div className="col-md-8">
                                    <p className="text-start h-100 d-flex align-items-center">
                                      <img
                                        src={check}
                                        alt="img"
                                        className="mr-2"
                                        style={{
                                          width: "20px",
                                          marginRight: "4px",
                                        }}
                                      />
                                      Accepted by {res?.vendorName}
                                    </p>
                                  </div>
                                  <div className="col-md-4">
                                    <button
                                      disabled={this.state.detailsLoader}
                                      className="btn"
                                      onClick={() => {
                                        this.viewDetails(res.vendorId);
                                      }}
                                    >
                                      {this.state.detailsLoader ? (
                                        <span>
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          Loading...
                                        </span>
                                      ) : (
                                        "View Details"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              );
                            } else {
                              return "";
                            }
                          })}
                          {/* {
                                                                this.isAcceptedCheck(el.requestTo) == false ?
                                                                    <div>
                                                                        Haven't accepted by any vendor
                                                                    </div>
                                                                    :
                                                                    null
                                                            } */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <Modal centered show={this.state.createRequestModal} dialogClassName="">
          <Modal.Body>
            <CreateManageRequestModal
              userName={this.userName}
              vendorId={this.vendorId}
              brands={this.state.brands}
              vendorService={vendorService}
              close={() => {
                this.setState({ createRequestModal: false });
              }}
            ></CreateManageRequestModal>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          show={this.state.vendorDetails}
          dialogClassName="request-details-modal"
        >
          <Modal.Body>
            <RequestDetailsModal
              data={this.state.vendorDetails}
              close={() => {
                this.setState({ vendorDetails: null });
              }}
            ></RequestDetailsModal>
          </Modal.Body>
        </Modal>
      </section>
    );
  }
}

export default withTranslation()(CreateManageRequest);
