import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyCW_0jf1A1NOkZpzhg4sTGVX2k9CHpPtnA",
    authDomain: "bikeconnect-7eb31.firebaseapp.com",
    projectId: "bikeconnect-7eb31",
    storageBucket: "bikeconnect-7eb31.appspot.com",
    messagingSenderId: "1078735937464",
    appId: "1:1078735937464:web:61e552db2b6b4a1b1bb94d",
    measurementId: "G-FF4XRKKQQ5"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)

export const db = getFirestore(app);