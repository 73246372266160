import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { onSnapshot } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "material-react-toastify";


class CommonService {

    uploadImage = (image, sendUrl,path) => {
        const catlogImages = ref(storage, `${path}/${image.name + uuidv4()}`);
        uploadBytes(catlogImages, image).then(
            (Snapshot) => {
                return getDownloadURL(Snapshot.ref).then(
                    res => {
                        sendUrl(res)
                    },
                    error => {
                        console.error(error)
                        toast.error('Error while uploading image')
                    }
                )
            },
            (err) => {
                console.error(err)
                toast.error('Error while uploading image')
            }
        )
    }

    uploadMultipleImages = (images, sendUrl,path) => {
        let urls = []
        let imgLength = Object.keys(images).length
        console.log(imgLength)
        let count = 0
        for (let image of images) {
            const catlogImages = ref(storage, `${path}/${image.name + uuidv4()}`);
            uploadBytes(catlogImages, image).then(
                (Snapshot) => {
                    return getDownloadURL(Snapshot.ref).then(
                        res => {
                            urls.push(res)
                            if (imgLength === count) {
                                sendUrl(urls,path)
                                return
                            }

                        },
                        error => {
                            console.error(error)
                            toast.error('Error while uploading image')
                        }
                    )
                },
                (err) => {
                    console.error(err)
                    toast.error('Error while uploading image')
                }
            )
            count = count + 1
        }

    }

}
export default new CommonService();