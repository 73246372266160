import { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import authService from "../../services/auth.service";
import vendorService from "../../services/vendor.service";
import { withRouter } from "../../utils/common.utils";
// import Profile from './Profile';
// import { Modal } from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      cnfPassword: "",
      visiblePassword: false,
      visibleConfirmPassword: false,
      profileModal: false,
      isLoader: false,
    };
  }

  onSubmit = (values) => {
    this.setState({ isLoader: true });
    authService.signUpWithLink(values.email, values.password, this.setData);

    // authService.signUp(values.email, values.password)
    //   .then((userCredential) => {
    //     const user = userCredential.user;
    //     const data = {
    //       accessToken: user.accessToken,
    //       uid: user.uid,
    //       email: values.email
    //     }
    //     this.setProfile(user.uid, data)
    //   })
    //   .catch((error) => {
    //     console.log(error.message + ' - ' + error.code)
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     this.setState({ isLoader: false })
    //   });
  };
  setData = (error, userCredential, email) => {
    if (error) {
      const errorCode = userCredential.code;
      const errorMessage = userCredential.message;
      this.setState({ isLoader: false });
      toast.error(errorMessage);
    } else {
      const user = userCredential.user;
      const data = {
        accessToken: user.accessToken,
        uid: user.uid,
        email: email,
        emailVerified: user.emailVerified,
      };
      this.setProfile(user.uid, data);
    }
  };
  setProfile = (uid, user) => {
    let data = {
      role: "vendor",
      isProfile: false,
      email: user?.email,
      emailVerified: user.emailVerified,
    };
    user.role = "vendor";
    user.isProfile = false;
    vendorService
      .addProfile(data, uid)
      .then((userCredential) => {
        localStorage.setItem("user", JSON.stringify(user));
        this.props.authenticated("vendor");
        setTimeout(() => {
          toast.success("Sign Up successfully");
          this.props.router.navigate("/vendor/verify-email");
          this.setState({ isLoader: false });
        }, 500);
        console.log(userCredential);
      })
      .catch((error) => {
        console.log(error.message + " - " + error.code);
        const errorCode = error.code;
        const errorMessage = error.message;
        this.setState({ isLoader: false });
      });
  };
  togglePassword(key, value) {
    this.setState({
      [key]: !value,
    });
  }

  setValues = (key, val) => {
    this.setState({ [key]: val });
  };

  render() {
    return (
      <div className="p-3">
        <IoIosCloseCircleOutline
          className="close-icon position-absolute"
          style={{ right: "15px", top: "15px" }}
          onClick={this.props.close}
        />
        <h4 className="text-center">Register as a vendor</h4>
        <Formik
          enableReinitialize
          initialValues={{
            email: this.state.email,
            password: this.state.password,
            cnfPassword: this.state.cnfPassword,
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .required("Email is required")
              .email("Invalid email"),
            password: Yup.string().required("Password is required"),
            cnfPassword: Yup.string()
              .required("Confirm password is required")
              .oneOf([Yup.ref("password")], "Your passwords do not match."),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              this.onSubmit(values);
            }, 400);
          }}
        >
          <Form className="mt-3" autoComplete="off">
            <div className="row">
              <div className="col-12">
                <div className="text-left">
                  <label htmlFor="email" className="fs-16 fw-600 mb-1">
                    Email
                  </label>
                  <Field
                    name="email"
                    type="email"
                    value={this.state.email}
                    className="w-100 ps-3 input_field-height rounded-3 border-1"
                    placeholder="Enter Email"
                    onChange={(e) => {
                      this.setValues("email", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="email"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="text-left">
                  <label htmlFor="password" className="fs-16 fw-600 mb-1">
                    Password
                  </label>
                  <div className="position-relative">
                    <Field
                      name="password"
                      type={this.state.visiblePassword ? "text" : "password"}
                      value={this.state.password}
                      className="w-100 ps-3 input_field-height rounded-3 border-1"
                      placeholder="Enter Password"
                      onChange={(e) => {
                        this.setValues("password", e.target.value);
                      }}
                    />
                    {this.state.visiblePassword ? (
                      <AiOutlineEye
                        className="position-absolute top-50 end-0 translate-middle-y me-3"
                        onClick={(e) =>
                          this.togglePassword(
                            "visiblePassword",
                            this.state.visiblePassword
                          )
                        }
                      ></AiOutlineEye>
                    ) : (
                      <AiOutlineEyeInvisible
                        className="position-absolute top-50 end-0 translate-middle-y me-3"
                        onClick={(e) =>
                          this.togglePassword(
                            "visiblePassword",
                            this.state.visiblePassword
                          )
                        }
                      ></AiOutlineEyeInvisible>
                    )}
                  </div>
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="password"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="text-left">
                  <label htmlFor="cnfPassword" className="fs-16 fw-600 mb-1">
                    Confirm password
                  </label>
                  <div className="position-relative">
                    <Field
                      name="cnfPassword"
                      type={
                        this.state.visibleConfirmPassword ? "text" : "password"
                      }
                      value={this.state.cnfPassword}
                      className="w-100 ps-3 input_field-height rounded-3 border-1"
                      placeholder="Enter confirm password"
                      onChange={(e) => {
                        this.setValues("cnfPassword", e.target.value);
                      }}
                    />
                    {this.state.visibleConfirmPassword ? (
                      <AiOutlineEye
                        className="position-absolute top-50 end-0 translate-middle-y me-3"
                        onClick={(e) =>
                          this.togglePassword(
                            "visibleConfirmPassword",
                            this.state.visibleConfirmPassword
                          )
                        }
                      ></AiOutlineEye>
                    ) : (
                      <AiOutlineEyeInvisible
                        className="position-absolute top-50 end-0 translate-middle-y me-3"
                        onClick={(e) =>
                          this.togglePassword(
                            "visibleConfirmPassword",
                            this.state.visibleConfirmPassword
                          )
                        }
                      ></AiOutlineEyeInvisible>
                    )}
                  </div>
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="cnfPassword"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <button
                type="submit"
                disabled={this.state.isLoader}
                className="btn btn-orange d-block m-auto mt-3 rounded-pill"
              >
                {this.state.isLoader ? (
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </span>
                ) : (
                  "Submit"
                )}
              </button>
              {/* <button className="btn btn-outline-warning d-block py-2 px-4 m-auto mt-3 mb-2" type="button" onClick={() => { this.setState({ profileModal: true }) }}>Complete Profile</button> */}
            </div>
          </Form>
        </Formik>

        {/* <Modal centered show={this.state.profileModal} dialogClassName="">
          <Modal.Body>
            <Profile close={() => { this.setState({ profileModal: false }) }}></Profile>
          </Modal.Body>
        </Modal> */}
      </div>
    );
  }
}
export default withRouter(SignUp);
