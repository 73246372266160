import { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  firebaseDate,
  userProfile,
  vendorId,
  withRouter,
} from "../../utils/common.utils";
import vendorService from "../../services/vendor.service";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import DefaultImage from "../../images/no_image.jpg";

class ChatModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMsg: "",
      messages: [],
    };
    this.userId = vendorId();
  }
  componentDidMount() {
    // this.getMessages()
    let collectionRef = collection(db, "chat", this.props.chatId, "message");
    const q = query(collectionRef, orderBy("createdAt"));
    onSnapshot(q, (querySnapshot) => {
      // querySnapshot.forEach((doc) => {
      //     let temp = doc.data()
      //     temp.id = doc.id
      //     data.push(temp)
      // });
      this.setState(
        { messages: querySnapshot.docs.map((doc) => doc.data()) },
        () => {
          this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        }
      );
    });
  }
  getMessages = () => {
    vendorService
      .getMessages(this.props.chatId)
      .then((result) => {
        console.log(result);
        this.setState({ messages: result });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  sendMessage = () => {
    let messageObj = {
      createdAt: new Date(),
      parentChatId: this.props.chatId,
      receiverId: this.props.receiverId,
      senderId: this.props.senderId,
      text: this.state.currentMsg,
    };
    vendorService
      .updateChat(messageObj, this.props.chatId)
      .then((result) => {
        // let messages = this.state.messages;
        // messages.push(messageObj)
        this.setState({ currentMsg: "" });
        // this.getMessages()
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    return (
      <div>
        {/* User Chat */}
        <section className="main-wrapper">
          <IoIosCloseCircleOutline
            className="close-icon position-absolute text-white"
            style={{ right: "15px", top: "15px" }}
            onClick={this.props.close}
          />
          <div className="cgl-live-chat">
            <div className="chat-wrapper">
              <div className="chat-title">Live Chat</div>
              <div className="chat-view">
                {this.state.messages.map((el, i) => {
                  return (
                    <div className="chat-item" key={i}>
                      <div
                        className={`d-flex ${
                          el.senderId === this.userId
                            ? "vendor-message"
                            : "user-message"
                        }`}
                      >
                        <img
                          src={
                            el.senderId === this.userId
                              ? userProfile().image
                                ? userProfile().image
                                : DefaultImage
                              : this.props.vendor.image
                              ? this.props.vendor.image
                              : DefaultImage
                          }
                          alt=""
                        />
                        <div className="p-1">
                          <div className="d-flex align-items-end">
                            <div className="sender-name">
                              <a href="#">
                                {el.senderId === this.userId
                                  ? "You"
                                  : this.props.vendor.name}
                              </a>
                            </div>
                            <div className="chat-time">
                              {firebaseDate(el.createdAt)}
                            </div>
                          </div>
                          <div className="chat-text mt-2">{el?.text}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div
                  className="row"
                  style={{ height: "20px" }}
                  ref={(el) => {
                    this.messagesEnd = el;
                  }}
                ></div>
                {/* <div className="chat-item">
                                    <div className="d-flex vendor-message">
                                        <img src="https://image.freepik.com/free-vector/gamer-logo_43901-59.jpg" alt="" />
                                        <div className="p-1">
                                            <div className="d-flex align-items-end">
                                                <div className="sender-name"><a href="#">#Surya Prakash</a></div>
                                                <div className="chat-time">09:54 24/05/21</div>
                                            </div>
                                            <div className="chat-text mt-2">
                                                Hello guys! lets get into the party
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
              </div>
              <div className="chat-message">
                <form action="#">
                  <div className="input-group align-items-center">
                    <input
                      type="text"
                      value={this.state.currentMsg}
                      onChange={(e) => {
                        this.setState({ currentMsg: e.target.value });
                      }}
                      className="form-control"
                      placeholder="Type something..."
                    />
                    <div className="input-group-append">
                      <button
                        disabled={!this.state.currentMsg}
                        className=""
                        type="button"
                        onClick={this.sendMessage}
                        id="button-addon2"
                      >
                        <img
                          src="https://github.com/suryavmds/Live-chat-HTML-design--like-YT-chat-/blob/master/assets/img/send-btn.svg?raw=true"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(ChatModal);
