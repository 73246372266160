import { Component } from "react";
import ImageGallery from "react-image-gallery";
import { IoCallOutline } from "react-icons/io5";
import { BsWhatsapp } from "react-icons/bs";
import "react-image-gallery/styles/css/image-gallery.css";
import adminService from "../../services/admin.service";
import {
  userName,
  vendorId,
  withRouter,
  userProfile,
} from "../../utils/common.utils";
import { Modal } from "react-bootstrap";
import ChatModal from "./ChatModal";
import vendorService from "../../services/vendor.service";
import { BsCurrencyEuro } from "react-icons/bs";
import { toast } from "material-react-toastify";
import Spinner from "react-bootstrap/Spinner";
import DefaultImage from "../../images/no_image.png";
import { FcInfo } from "react-icons/fc";
import { withTranslation } from "react-i18next";

const defaultImage = [
  {
    original: DefaultImage,
    thumbnail: DefaultImage,
  },
];
class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: vendorId(),
      product: null,
      images: [],
      vendors: [],
      allVendors: [],
      activePage: 15,
      isAuthenticated: false,
      reqIndex: null,
      activePage: 1,
      vendorCount: 0,
    };
  }
  componentDidMount() {
    this.getData();
    console.log(this.props.router?.params?.id);
    if (localStorage.getItem("user")) {
      this.setState({ isAuthenticated: true });
    }
  }

  getData = () => {
    this.props.loaderChange(true);
    adminService
      .getSingleCatelogs(this.props.router?.params?.id)
      .then((res) => {
        console.log(res.data());
        let images = [];
        for (let image of res.data()?.images) {
          images.push({ original: image, thumbnail: image });
        }
        this.setState({ product: res.data(), images: images });
        this.getCatlogs(res.data());
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };

  getCatlogs = (data) => {
    vendorService
      .vendorByLabel(data)
      .then((res) => {
        console.log(res);
        // this.setState({ vendors: res })
        this.getProfiles([...res]);
        // this.props.loaderChange(false)
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  getProfiles = async (vendors) => {
    let list = await vendors.map((el) => {
      return el.vendor;
    });
    vendorService
      .getAllProfilesByUidForProduct(list, null)
      .then((res) => {
        console.log(res);
        for (let i = 0; i < res.length; i++) {
          let temp = {};
          temp.vendorUid = res[i].id;
          temp.vendorNumber = res[i].phoneNumber;
          temp.vendorWhatsapp = res[i].whatsappNumber;
          temp.vendorEmail = res[i].email;
          temp.vendorName = res[i].name;
          temp.vendorCity = res[i].city;
          temp.vendorImage = res[i].image;
          temp.vendorProvince = res[i].province;
          vendors.map((el, j) => {
            if (el.vendor === res[i].id) {
              temp.frameSize = el.frameSize;
              temp.wheelSize = el.wheelSize;
              temp.price = el.price;
            }
          });
          vendors[i] = temp;
        }
        console.log("vendor list - ", vendors);
        let winners = vendors.slice(0, 10);
        this.setState({
          vendors: winners,
          allVendors: vendors,
          vendorCount: vendors.length,
        });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  pagination = (page) => {
    console.log(page);
    let winners = this.state.allVendors.slice((page - 1) * 10, page * 10);
    this.setState({ activePage: page, vendors: winners });
  };

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }

  sendRequest = (id, name, reqIndex) => {
    const data = {
      requestBy: vendorId(),
      requestedName: userName() ? userName() : "",
      requestData: {
        componentBrand: this.state.product?.brand,
        componentName: this.state.product?.label,
        pricePerUnit: "",
        quantity: "1",
      },
      requestTo: {
        [id]: {
          status: "",
          vendorId: id,
          vendorName: name ? name : "",
        },
      },
    };
    console.log("data - ", data);
    this.setState({ isLoader: true, reqIndex: reqIndex });
    vendorService
      .sendRequest(data)
      .then((res) => {
        console.log(res);
        this.setState({ isLoader: false, reqIndex: null });
        toast.success("Request sent successfully");
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoader: false, reqIndex: null });
        const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  openChatBox = (user) => {
    let chatId;
    if (this.state.userId > user.vendorUid) {
      chatId = `${this.state.userId}_${user.vendorUid}`;
    } else {
      chatId = `${user.vendorUid}_${this.state.userId}`;
    }
    console.log("chatid - ", chatId);
    vendorService
      .getChats(chatId)
      .then((res) => {
        if (res.data()) {
          this.setState({ chatId: chatId, vendor: user });
        } else {
          let msgData = {
            createdAt: new Date(),
            updatedAt: new Date(),
            users: [
              {
                image: userProfile().image,
                name: userProfile().name,
                uid: userProfile().uid,
                unReadCount: 0,
              },
              {
                image: user.vendorImage,
                name: user.vendorName,
                uid: user.vendorUid,
                unReadCount: 0,
              },
            ],
            uids: [user.vendorUid, userProfile().uid],
            id: chatId,
          };
          vendorService
            .addChat(msgData, chatId)
            .then((result) => {
              this.setState({ chatId: chatId });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const { t } = this.props;
    return (
      <div className="">
        <section className="product-details">
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-6 col-12">
                <ImageGallery
                  items={
                    this.state.images?.length ? this.state.images : defaultImage
                  }
                  showFullscreenButton={false}
                  showBullets={true}
                />
              </div>
              <div className="col-lg-6 col-12 pt-3">
                <div className="row">
                  <div className="col-12">
                    <h2 className="text-start product-details-title">
                      {this.state.product?.label}
                    </h2>
                    <p className="text-start product-details-description">
                      {this.state.product?.brand}
                    </p>
                    {this.state.product?.codeList &&
                    this.state.product?.codeList?.length > 0
                      ? this.state.product?.codeList.map((e) => {
                          return (
                            <li className="text-start product-details-description">
                              {e}
                            </li>
                          );
                        })
                      : null}
                    <h4 className="text-start product-details-description-title">
                      {t("description")}
                    </h4>
                    <p className="text-start product-details-description">
                      {this.state.product?.discription}
                    </p>
                    <p className="text-start">
                      <a href={this.state.product?.link} target="_blank">
                        <button className="btn btn-outline-orange">
                          {t("viewWebsite")}
                        </button>
                      </a>
                    </p>
                    <p className="text-start">
                      <span className="fw-bold">
                        {t("retailPriceWithoutVAT")}
                      </span>
                      <BsCurrencyEuro />
                      {this.state.product?.retailPrice}
                    </p>
                    <p className="text-start">
                      <span className="fw-bold">{t("manufacturerYear")}:</span>{" "}
                      {this.state.product?.yearOfManufacture}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5">
                <div className="">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          {/* <th scope="col">#</th> */}
                          <th scope="col" className="text-start">
                            {t("vendor")}
                          </th>
                          <th scope="col" className="text-start">
                            {t("frameSize")}
                          </th>
                          <th scope="col" className="text-start">
                            {t("wheelSize")}
                          </th>
                          {this.state.isAuthenticated ? (
                            <th
                              scope="col"
                              className="text-start"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={t("offeredPriceWithoutVAT")}
                            >
                              {t("offeredPrice")}
                              <FcInfo
                                size={14}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={t("offeredPriceWithoutVAT")}
                              />
                            </th>
                          ) : null}

                          <th scope="col" className="text-start">
                            {t("city")}
                          </th>
                          <th scope="col" className="text-start">
                            {t("provinceCountyRegion")}
                          </th>
                          <th scope="col" className="text-start">
                            Email ID
                          </th>
                          {/* <th scope="col"></th> */}
                          <th scope="col" className="text-start">
                            {t("whatsppNo")}
                          </th>
                          <th scope="col" className="text-start">
                            {t("phoneNo")}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.vendors.length != 0 ? (
                          this.state.vendors.map((el, i) => {
                            return (
                              <tr key={i}>
                                {/* <th scope="row">1</th> */}
                                <td>
                                  <p className="max-width-content mb-0">
                                    {el?.vendorName
                                      ? el?.vendorName
                                      : "Unknown"}
                                  </p>
                                </td>
                                <td>
                                  <p className="max-width-content mb-0">
                                    {el?.frameSize
                                      ? el?.frameSize + "cm"
                                      : "Unknown"}
                                  </p>
                                </td>
                                <td>
                                  <p className="max-width-content mb-0">
                                    {el?.wheelSize
                                      ? el?.wheelSize + " inch"
                                      : "Unknown"}
                                  </p>
                                </td>
                                {this.state.isAuthenticated ? (
                                  <td>
                                    <p className="max-width-content mb-0 d-flex align-items-center">
                                      <BsCurrencyEuro />
                                      {el?.price}
                                    </p>
                                  </td>
                                ) : null}
                                <td>
                                  <p className="max-width-content mb-0">
                                    {el?.vendorCity ? el?.vendorCity : "-"}
                                  </p>
                                </td>
                                <td>
                                  <p className="max-width-content mb-0">
                                    {el?.vendorProvince
                                      ? el?.vendorProvince
                                      : "-"}
                                  </p>
                                </td>
                                <td>
                                  <p className="max-width-content mb-0">
                                    {el?.vendorEmail ? el?.vendorEmail : "-"}
                                  </p>
                                </td>

                                {/* <td><button className='btn btn-orange rounded-pill max-width-content'>Place order Request</button></td> */}
                                <td>
                                  <div className="d-flex">
                                    <BsWhatsapp className="social-icon me-2 text-success" />
                                    <p className="mb-0">
                                      {" "}
                                      {el?.vendorWhatsapp
                                        ? el?.vendorWhatsapp
                                        : "-"}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <IoCallOutline className="social-icon me-2 text-primary" />
                                    <p className="mb-0">
                                      {" "}
                                      {el?.vendorNumber
                                        ? el?.vendorNumber
                                        : "-"}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  {!this.state.userId ||
                                  this.state.userId == el?.vendorUid ? (
                                    <span></span>
                                  ) : (
                                    <button
                                      disabled={
                                        this.state.isLoader &&
                                        this.state.reqIndex === i
                                      }
                                      className="btn btn-orange rounded-pill"
                                      type="button"
                                      onClick={() => {
                                        this.sendRequest(
                                          el?.vendorUid,
                                          el?.vendorName,
                                          i
                                        );
                                      }}
                                    >
                                      {this.state.isLoader &&
                                      this.state.reqIndex === i ? (
                                        <span>
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          Loading...
                                        </span>
                                      ) : (
                                        "Request"
                                      )}
                                    </button>
                                  )}
                                </td>
                                <td>
                                  {this.state.userId &&
                                  this.state.userId != el.vendorUid ? (
                                    <button
                                      className="btn btn-orange rounded-pill"
                                      onClick={() => {
                                        this.openChatBox(el);
                                      }}
                                    >
                                      Chat
                                    </button>
                                  ) : (
                                    <span></span>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <p>{t("noVendorOnThisProduct")}</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="float-end mt-3">
                    <button
                      disabled={this.state.activePage === 1}
                      className="btn btn-orange rounded-pill"
                      type="button"
                      onClick={() => {
                        this.pagination(this.state.activePage - 1);
                      }}
                    >
                      {t("previous")}
                    </button>
                    <button
                      disabled={
                        this.state.vendorCount / this.state.activePage <= 10
                      }
                      className="btn btn-orange rounded-pill"
                      style={{ marginLeft: "4px" }}
                      type="button"
                      onClick={() => {
                        this.pagination(this.state.activePage + 1);
                      }}
                    >
                      {t("next")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end mt-5"></div>
            </div>
          </div>
        </section>{" "}
        <Modal centered show={this.state.chatId} dialogClassName="chat-modal">
          <Modal.Body>
            <ChatModal
              vendor={this.state.vendor}
              senderId={this.state.userId}
              receiverId={this.props.router?.params?.id}
              messages={this.state.messages}
              chatId={this.state.chatId}
              close={() => {
                this.setState({ chatId: "" });
              }}
            ></ChatModal>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default withRouter(withTranslation()(ProductDetails));
