import { Component } from "react";
import { withRouter, userCountry } from "../../utils/common.utils";
import noDataFoundPage from "../../images/nodatafound.png";
import algoliasearch from "algoliasearch/lite";
import vendorService from "../../services/vendor.service";
const searchClient = algoliasearch(
  "J89FYK2680",
  "ad6f144fe0b845dc40ba7d4289b1ed3d"
);
const index = searchClient.initIndex("manufacturer");
const defaultImg =
  "https://firebasestorage.googleapis.com/v0/b/bikeconnect-7eb31.appspot.com/o/catlogImages%2FLoco.pngbe4f0804-7237-495c-9fb3-4aca8d5ff9e8?alt=media&token=c2d2d967-57ac-4758-aa29-fbc7fa1bdf3a";
class ProductSearch extends Component {
  constructor(props) {
    super(props);
    this.country = userCountry();
    this.state = {
      searchVendors: [],
    };
    console.log(this.props.router?.params?.searchTerm);
  }
  componentDidMount() {
    this.searchVendor(this.props.router?.params?.searchTerm);
  }
  componentDidUpdate(newProps) {
    if (
      this.props.router?.params?.searchTerm !==
      newProps.router?.params?.searchTerm
    ) {
      this.searchVendor(this.props.router?.params?.searchTerm);
    }
  }
  searchVendor = (key) => {
    this.props.loaderChange(true);
    index
      .search(key, { facetFilters: [[`countryList:${this.country}`]] })
      .then(({ hits }) => {
        let vendors = hits?.length ? hits : [];
        this.setState({ searchVendors: vendors });
        this.props.loaderChange(false);
        console.log(hits);
      })
      .catch((err) => {
        this.props.loaderChange(false);
        console.log(err);
      });
    // adminService.searchLabels(key).then(
    //   res => {
    //     console.log("res - ", res)
    //     let vendors = res ? res : []
    //     this.setState({ searchVendors: vendors })
    //     this.props.loaderChange(false)
    //   }
    // ).catch(
    //   err => {
    //     console.log(err)
    //     this.props.loaderChange(false)
    //   }
    // )
  };
  toProducts = (el) => {
    this.props.loaderChange(true);
    console.log(el);
    vendorService
      .getSingleProduct(el)
      .then((res) => {
        console.log(res);
        // this.setState({ products: res })
        this.props.router.navigate(`/product-details/${res[0].id}`);
        this.props.loaderChange(false);
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
    // let path = `macroCategory=${el?.macroCategory}&subCategory=${el?.subCategory}&miniCategory=${el?.miniCategory}&microCategory=${el?.microCategory}`;
    // this.props.router.navigate(`/products?${path}`);
  };
  // toProducts = (el) => {
  //   console.log(el);
  //   this.props.router.navigate(`/product-details/${el.label}`, { state: el });
  // };
  render() {
    return (
      <div className="">
        <section className="product-details">
          <div className="container">
            {this.state.searchVendors.length ? (
              <div className="row mt-5">
                {this.state.searchVendors.map((el, i) => {
                  return (
                    <div className="col-lg-2 col-md-4 col-6" key={i}>
                      <div
                        className="card card-width mb-lg-4 border-0"
                        onClick={() => {
                          this.toProducts(el);
                        }}
                      >
                        <img
                          src={el?.images[0] ? el?.images[0] : defaultImg}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body">
                          <span className="text-decoration-none" to="/category">
                            <h5 className="card-title vendor-name text-black">
                              {el?.brand}
                            </h5>
                            <h4 className="card-title vendor-name text-secondary">
                              {el?.label}
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="data-not-found search-data-not-found d-flex align-items-center justify-content-center">
                <div className="">
                  <img src={noDataFoundPage} alt="img" className="w-50" />
                  <div className="data-not-found-content d-md-flex d-lg-flex justify-content-center">
                    <button
                      className="btn btn-orange rounded-pill ms-3"
                      onClick={() => {
                        this.props.router.navigate(`/`);
                      }}
                    >
                      Go to Homepage
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(ProductSearch);
