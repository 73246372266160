import React, { Component } from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { toast } from "material-react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';

class RegisterBrandModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            coupon: '',
            error: '',
            isLoader: false,
        }
    }
    componentDidMount() {
    }
    checkCoupon = () => {
        this.setState({ isLoader: true })
        this.props.vendorService.getSinglCoupon(this.state.coupon).then(
            res => {
                console.log(res.data())
                if (res?.data()?.company && (!res?.data()?.claimed)) {
                    this.setState({ error: '' })
                    let data = {
                        company: res?.data()?.company,
                        coupon: this.state.coupon,
                        listedDate: new Date(),
                        validTill: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                        claimed: res?.data()?.claimed,
                        claimedBy: res?.data()?.claimedBy,
                        delivered: res?.data()?.delivered,
                    }
                    this.registerVendorInBrand(data)
                } else {
                    this.setState({ error: 'Invalid coupon code' })
                    this.setState({ isLoader: false })
                }
            }
        ).catch(
            err => {
                console.log(err)
                toast.error(err)
                this.setState({ isLoader: false })
                const errorMessage = err.message;
                toast.error(errorMessage)
            }
        )
    }
    registerVendorInBrand = (data) => {
        this.props.vendorService.registerVendorInBrand({ [this.props.vendorId]: this.props.vendorId }, data.company).then(
            res => {
                this.props.register(data)
            }
        ).catch(
            err => {
                console.log(err)
                toast.error(err)
                this.setState({ isLoader: false })
                const errorMessage = err.message;
                toast.error(errorMessage)
            }
        )
    }
    render() {
        return (
            <div className='text-center'>
                <div >
                    <IoIosCloseCircleOutline className="position-absolute" style={{ right: '15px', top: '15px' }} onClick={this.props.close} />
                    <h6>Register a Brand</h6>
                </div>
                <div className='mt-4'>
                    <input type='text' value={this.state.coupon} onChange={(e) => { this.setState({ coupon: e.target.value }) }} placeholder='Enter code here...' className='w-100 ps-3 input_field-height rounded-3 border-1' />
                    {
                        this.state.error ?
                            <div className='text-danger'>{this.state.error}</div>
                            : null
                    }
                </div>

                <div className='mt-4'>
                    <button className='btn btn-orange rounded-pill' disabled={!this.state.coupon ||  this.state.isLoader} onClick={this.checkCoupon}>
                        {
                            this.state.isLoader ?
                                <span>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </span>
                                :
                                'Submit'
                        }
                    </button>
                </div>
            </div>
        )
    }
}

export default withTranslation()(RegisterBrandModal);

