import { Component } from "react";
import ImageGallery from "react-image-gallery";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import {
  userProfile,
  vendorId,
  withRouter,
} from "../../utils/common.utils";
import "react-image-gallery/styles/css/image-gallery.css";
import adminService from "../../services/admin.service";
import ChatModal from "./ChatModal";
import { Modal } from "react-bootstrap";
import vendorService from "../../services/vendor.service";
import { MdReviews } from "react-icons/md";
import ReviewModal from "./ReviewModal";
import DefaultImage from "../../images/no_image.png";
import { withTranslation } from "react-i18next";

const defaultImage = [
  {
    original: DefaultImage,
    thumbnail: DefaultImage,
  },
];

class SelectedCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: vendorId(),
      vendor: null,
      chatBox: false,
      reviewModal: false,
      images: [],
      chatId: "",
      messages: [],
      reviews: [],
      showReviews: [],
      pageNo: 1,
      vendorBrandsList: [],
    };
  }
  componentDidMount() {
    this.getData();
    this.getVendorBrands(this.props.router?.params?.id);
  }
  getData = () => {
    console.log(this.props.router?.params?.id);
    adminService
      .getSingleVendor(this.props.router?.params?.id)
      .then((res) => {
        console.log("vendors -", res.data());
        let images = [];
        if (res.data()?.otherImages?.length) {
          images.push({
            original: res.data()?.image,
            thumbnail: res.data()?.image,
          });
          for (let image of res.data()?.otherImages) {
            images.push({ original: image, thumbnail: image });
          }
        } else {
          if (res.data()?.image) {
            images.push({
              original: res.data()?.image,
              thumbnail: res.data()?.image,
            });
          }
        }
        let reviews = res.data()?.review ? res.data()?.review?.slice(0, 6) : [];
        this.setState({
          vendor: res.data(),
          images: images,
          reviews: res.data()?.review ? res.data()?.review : [],
          showReviews: reviews,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  openChatBox = () => {
    let chatId;
    if (this.state.userId > this.props.router?.params?.id) {
      chatId = `${this.state.userId}_${this.props.router?.params?.id}`;
    } else {
      chatId = `${this.props.router?.params?.id}_${this.state.userId}`;
    }
    console.log("chatid - ", chatId);
    vendorService
      .getChats(chatId)
      .then((res) => {
        if (res.data()) {
          this.setState({ chatId: chatId });
          // vendorService.getMessages(chatId).then(
          //   result => {
          //     console.log(result)
          //     this.setState({ messages: result, chatId: chatId })
          //   }
          // ).catch(
          //   error => {
          //     console.log(error)
          //   }
          // )
        } else {
          let msgData = {
            createdAt: new Date(),
            updatedAt: new Date(),
            users: [
              {
                image: userProfile().image,
                name: userProfile().name,
                uid: userProfile().uid,
                unReadCount: 0,
              },
              {
                image: this.state.vendor.image,
                name: this.state.vendor.name,
                uid: this.props.router?.params?.id,
                unReadCount: 0,
              },
            ],
            uids: [this.props.router?.params?.id, userProfile().uid],
            id: chatId,
          };
          vendorService
            .addChat(msgData, chatId)
            .then((result) => {
              this.setState({ chatId: chatId });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  reviewAdded = (data) => {
    this.setState({ reviews: data, reviewModal: false });
  };
  loadMore = () => {
    let pageNo = this.state.pageNo + 1;
    let reviews = this.state.reviews.slice(0, pageNo * 6);
    this.setState({ pageNo: pageNo, showReviews: reviews });
  };
  showLess = () => {
    let reviews = this.state.reviews.slice(0, 6);
    this.setState({ pageNo: 1, showReviews: reviews });
  };
  getVendorBrands = (id) => {
    this.props.loaderChange(true);
    vendorService
      .getBrands(id)
      .then((res) => {
        var data = res?.data().brands ? res?.data().brands : [];
        console.log(data);
        data = data.filter(function (el) {
          return !el.delivered;
        });
        data = data.map((e) => {
          return e.company;
        });
        data = [...new Set(data)];
        this.setState({
          vendorBrandsList: data,
        });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        this.props.loaderChange(false);
        console.log(err);
      });
  };
  getTime = (time) => {
    const hr = time[0] + time[1];
    if (parseInt(hr) > 12) {
      return hr - 12 + ":" + time[3] + time[4] + "PM";
    }
    return hr + ":" + time[3] + time[4] + "AM";
  };
  render() {
    const { t } = this.props;
    return (
      <div className="">
        <section className="vendor-details ">
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-6 col-12">
                <ImageGallery
                  items={
                    this.state.images?.length ? this.state.images : defaultImage
                  }
                  showFullscreenButton={false}
                  showBullets={true}
                />
              </div>
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-12 border-bottom">
                    <div className="d-flex">
                      <h4 className="text-start mb-0">
                        {this.state.vendor?.name}
                      </h4>
                      {this.state.vendor?.isAvailable ? (
                        <button
                          type="button"
                          className="btn btn-success rounded-pill ms-3 py-0"
                        >
                          {t(
                            "availableToRepairAssembleMaterialPurchasedOnlineOrElsewhere"
                          )}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-secondary rounded-pill ms-3 py-0"
                        >
                          {t(
                            "unAvailableToRepairAssembleMaterialPurchasedOnlineOrElsewhere"
                          )}
                        </button>
                      )}
                    </div>
                    <p className="text-start ">
                      {this.state.vendor?.description}
                    </p>
                  </div>
                  {this.state.vendor?.workingDays?.length ||
                  this.state.vendor?.startTime > 0 ? (
                    <div className="col-12 text-start border-bottom mt-3 pb-3">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              <strong>Shop timing</strong>
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <ul className="">
                                {this.state.vendor?.workingDays.length > 0 ? (
                                  this.state.vendor?.workingDays.map(
                                    (el, i) => {
                                      return (
                                        <div>
                                          <p>
                                            {el.day}:
                                            {this.getTime(el.startTime)}-
                                            {this.getTime(el.endTime)}
                                          </p>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <span></span>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {this.state.vendor?.vendorLink ? (
                    <div className="col-12">
                      <p className="text-start text-break mt-3">
                        <a href={this.state.vendor?.vendorLink} target="_blank">
                          {t("viewWebsite")}
                        </a>
                      </p>
                    </div>
                  ) : null}
                  <div className="col-12">
                    <p className="text-start">
                      <h6>Address:</h6>
                      {this.state.vendor?.addressline1}
                    </p>
                    {this.state.vendor?.addressline2 ? (
                      <p className="text-start">
                        {this.state.vendor?.addressline2}
                      </p>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="col-12">
                    <p className="text-start">
                      {t("zipCode")} - {this.state.vendor?.zipCode}
                    </p>
                  </div>
                  <div className="col-12 border-bottom">
                    <p className="text-start ">
                      {" "}
                      {t("averageReparationTime")} -{" "}
                      {this.state.vendor?.reparationTime}days
                    </p>
                  </div>
                  {this.state.vendorBrandsList.length > 0 ? (
                    <div className="col-12 text-start border-bottom mt-3 pb-3">
                      <h6>Brands List</h6>
                      <div className="row">
                        {this.state.vendorBrandsList.length > 0 ? (
                          this.state.vendorBrandsList.map((el, i) => {
                            return (
                              <div className="col-3">
                                {" "}
                                <li>{el}</li>
                              </div>
                            );
                          })
                        ) : (
                          <p>No Brands addded </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {this.state.vendor?.servicesList?.length > 0 ? (
                    <div className="col-12 text-start border-bottom mt-3 pb-3">
                      <h6>Services</h6>
                      <div className="row">
                        {this.state.vendor?.servicesList?.length > 0 ? (
                          this.state.vendor?.servicesList.map((el, i) => {
                            return (
                              <div className="col-4">
                                <li>{el}</li>{" "}
                              </div>
                            );
                          })
                        ) : (
                          <p>No services addded </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="col-7 border-end mt-3">
                    <h6 className="text-start">{t("contactDetails")}</h6>
                    <p className="text-start mb-0">
                      {t("phoneNo")} - {this.state.vendor?.phoneNumber}
                    </p>
                    <p className="text-start mb-0">
                      {t("whatsppNo")} - {this.state.vendor?.whatsappNumber}
                    </p>
                    <p className="text-start">
                      Email - {this.state.vendor?.email}
                    </p>
                  </div>
                  {this.state.userId ? (
                    this.state.userId != this.props.router?.params?.id ? (
                      <div className="col-5 align-self-center justify-content-center">
                        <button
                          className="btn btn-orange rounded-pill"
                          onClick={this.openChatBox}
                        >
                          Chat
                        </button>
                      </div>
                    ) : (
                      <span></span>
                    )
                  ) : (
                    <div className="col-5 align-self-center justify-content-center">
                      <button
                        className="btn btn-orange"
                        onClick={() => {
                          this.setState({ reviewModal: true });
                        }}
                      >
                        <MdReviews /> Add Review
                      </button>
                    </div>
                  )}
                  <div className="col-12">
                    <div className="float-start">
                      {this.state.vendor?.facebook ? (
                        <a href={this.state.vendor?.facebook} target="_blank">
                          {" "}
                          <BsFacebook className="social-icon me-3" />
                        </a>
                      ) : null}
                      {this.state.vendor?.instagram ? (
                        <a href={this.state.vendor?.instagram} target="_blank">
                          {" "}
                          <BsInstagram className="social-icon me-3" />
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {this.state.reviews?.length ? (
                <div className="col-12 mt-5">
                  <section id="">
                    <div className="row w-100 mb-4">
                      <div className="col-6 text-start">
                        <h4>Reviews</h4>
                      </div>
                    </div>
                    <div className="row">
                      {this.state.showReviews.map((el, i) => {
                        return (
                          <div className="col-lg-4 col-12" key={i}>
                            <div className="testimonial-box mb-4">
                              <div className="box-top mb-3">
                                <div className="profile d-flex align-items-center">
                                  <div
                                    className="profile-img border rounded-circle"
                                    style={{ height: "30px", width: "30px" }}
                                  >
                                    <strong>{el?.name[0]}</strong>
                                  </div>
                                  <div className="name-user d-flex flex-column">
                                    <strong>{el?.name}</strong>
                                  </div>
                                </div>
                              </div>
                              <div className="client-comment">
                                <p>{el?.review}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {this.state.reviews.length > 6 ? (
                      <div className="row mb-4">
                        <div className="col-12">
                          {this.state.showReviews?.length ===
                          this.state.reviews?.length ? (
                            <button
                              className="btn btn-orange"
                              onClick={this.showLess}
                            >
                              Show Less
                            </button>
                          ) : (
                            <button
                              className="btn btn-orange"
                              onClick={this.loadMore}
                            >
                              Load More
                            </button>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </section>
                </div>
              ) : null}
            </div>
          </div>
        </section>

        <Modal centered show={this.state.chatId} dialogClassName="chat-modal">
          <Modal.Body>
            <ChatModal
              vendor={this.state.vendor}
              senderId={this.state.userId}
              receiverId={this.props.router?.params?.id}
              messages={this.state.messages}
              chatId={this.state.chatId}
              close={() => {
                this.setState({ chatId: "" });
              }}
            ></ChatModal>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          show={this.state.reviewModal}
          dialogClassName="review-modal"
        >
          <Modal.Body>
            <ReviewModal
              reviewAdded={this.reviewAdded}
              id={this.props.router?.params?.id}
              reviews={this.state.reviews}
              close={() => {
                this.setState({ reviewModal: false });
              }}
              logout={() => {
                this.setState({ isAuthenticated: false, reviewModal: false });
              }}
            ></ReviewModal>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default withRouter(withTranslation()(SelectedCategory));
