import { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'material-react-toastify';
import {BsCurrencyEuro} from 'react-icons/bs'


export default class CreateManageRequestModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            brand: '',
            pricePerUnit: '',
            componentName: '',
            quantity: '',
            isLoader: false
        }
    }
    onSubmit = (values) => {
        console.log("values - ", values)
        this.setState({ isLoader: true })
        this.props.vendorService.getBrandVendor(values.componentBrand).then(
            res => {
                console.log("res.data() - ", res.data());
                let vendors = res.data()
                let reqVendorsObj = {}
                for (let req in vendors) {
                    if (req !== this.props.vendorId) {
                        reqVendorsObj[req] = {
                            vendorId: vendors[req],
                            status: '',
                            vendorName: ''
                        }
                    }
                }
                const data = {
                    createdAt:new Date(),
                    requestBy: this.props.vendorId,
                    requestedName: this.props?.userName,
                    requestData: values,
                    requestTo: reqVendorsObj
                }
                this.sendReq(data)
            }
        ).catch(
            err => {
                console.log(err)
                this.setState({ isLoader: false })
                const errorCode = err.code;
                const errorMessage = err.message;
                toast.error(errorMessage)
            }
        )
    }

    sendReq = (data) => {
        this.props.vendorService.sendRequest(data).then(
            res => {
                console.log(res);
                this.setState({ isLoader: false })
                this.props.close()
                toast.success('Request sent successfully')
            }
        ).catch(
            err => {
                console.log(err)
                this.setState({ isLoader: false })
                const errorCode = err.code;
                const errorMessage = err.message;
                toast.error(errorMessage)
            }
        )
    }

    render() {
        return (
            <div className='p-3'>
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={this.props.close} />
                <div className='text-center'>
                    <h6>Create Request</h6>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        componentName: this.state.componentName,
                        componentBrand: this.state.brand,
                        quantity: this.state.quantity,
                        pricePerUnit: this.state.pricePerUnit
                    }}
                    validationSchema={Yup.object({
                        componentName: Yup.string().required("Component name is required"),
                        componentBrand: Yup.string().required("Component brand is required"),
                        quantity: Yup.string().matches(/^[0-9]+$/, "Must be only digits").required("Quantity is required"),
                        pricePerUnit: Yup.string().matches(/^[0-9]+$/, "Must be only digits").required("Price per unit is required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            this.onSubmit(values)
                        }, 400);
                    }}
                >
                    <Form className="mt-3" autoComplete="off">
                        <div className='row'>
                            <div className='col-12'>
                                <div className="text-left">
                                    <label htmlFor="componentName" className="fs-16 fw-600 mb-1">Name of Component/Bicycle</label>
                                    <Field
                                        name="componentName" type="text"
                                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                                        placeholder="Enter Name of Component/Bicycle"
                                        value={this.state.componentName} onChange={(e) => { this.setState({ componentName: e.target.value }) }} />
                                    <ErrorMessage component="span" className="error-msg" name="componentName" />
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div className="text-left">
                                    <label htmlFor="componentBrand" className="fs-16 fw-600 mb-1">Brand of Component</label>

                                    <Field name="componentBrand" value={this.state.brand} onChange={(e) => { this.setState({ brand: e.target.value }) }} as="select" className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1" >
                                        <option value=''>Select Brand</option>
                                        {
                                            this.props.brands.map((el, i) => {
                                                return (
                                                    <option key={i} value={el?.company}>{el?.company}</option>
                                                )
                                            })
                                        }
                                    </Field>
                                    <ErrorMessage component="span" className="error-msg" name="componentBrand" />
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div className="text-left">
                                    <label htmlFor="quantity" className="fs-16 fw-600 mb-1">Order Quantity</label>
                                    <Field
                                        name="quantity" type="number"
                                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                                        placeholder="Enter Order Quantity"
                                        value={this.state.quantity} onChange={(e) => { this.setState({ quantity: e.target.value }) }} />
                                    <ErrorMessage component="span" className="error-msg" name="quantity" />
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div className="text-left">
                                    <label htmlFor="pricePerUnit" className="fs-16 fw-600 mb-1">Ask Price per Unit</label>
                                    <div className='d-flex w-100'>
                                    <Field
                                        name="pricePerUnit" type="number"
                                        className="w-100 ps-5 input_field-height rounded-3 border-1"
                                        placeholder="Enter Price per Unit"
                                        value={this.state.pricePerUnit} onChange={(e) => { this.setState({ pricePerUnit: e.target.value }) }} />
                                                <BsCurrencyEuro className='position-absolute mt-3 ms-3'/>
                                    
                                    </div>
                                    <ErrorMessage component="span" className="error-msg" name="pricePerUnit" />
                                </div>
                            </div>
                        </div>

                        <button type="submit" disabled={this.state.isLoader} className="btn btn-orange d-block m-auto mt-3 rounded-pill">
                            {
                                this.state.isLoader ?
                                    <span>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </span>
                                    :
                                    ' Send Request'
                            }
                        </button>
                    </Form>
                </Formik>
            </div>
        )
    }
}
