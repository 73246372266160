import React, { Component } from 'react';
import adminService from '../../services/admin.service'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from "material-react-toastify";
import { Link } from "react-router-dom";
import * as voucher_codes from 'voucher-code-generator';
import Spinner from 'react-bootstrap/Spinner';
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { withRouter } from '../../utils/common.utils';

class GenerateCoupen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      manufacturerName: '',
      noOfCoupens: '',
      country: 'Italy',
      isLoader: false,
    }
  }

  componentDidMount() {
  }

  setValues = (key, val) => {
    this.setState({ [key]: val })
  }

  generateCoupens = (data) => {
    this.setState({ isLoader: true })
    let Coupens = voucher_codes.generate({
      length: 8,
      count: data.noOfCoupens,
      charset: voucher_codes.charset("alphanumeric"),
      prefix: `${new Date().getTime()}`
    });
    let coupensArray = []
    for (let coupen of Coupens) {
      let coupenObj = {
        coupon: coupen,
        company: data.manufacturer,
        delivered: false,
        claimed: false,
        claimedBy: null
      }
      coupensArray.push(coupenObj)
    }

    adminService.addMultipleCoupons(coupensArray).then(
      result => {
        this.checkBrandExist(data.manufacturer)
      }
    ).catch(
      err => {
        console.log(err)
        const errorCode = err.code;
        const errorMessage = err.message;
        toast.error(errorMessage)
        this.setState({ isLoader: false })

      }
    )
  }
  checkBrandExist = (id) => {
    adminService.brandExistTest(id).then(
      result => {
        if (result.data()) {
          toast.success("Coupons added successfully")
          this.setState({
            manufacturerName: '',
            noOfCoupens: '',
            isLoader: false
          })
        } else {
          this.createBrandListing(id)
        }

      }
    ).catch(
      err => {
        console.log(err)
        this.setState({ isLoader: false })
        const errorCode = err.code;
        const errorMessage = err.message;
        toast.error(errorMessage)
      }
    )
  }
  createBrandListing = (id) => {
    adminService.addBrandListing(id).then(
      res => {
        toast.success("Coupons added successfully")
        this.setState({
          manufacturerName: '',
          noOfCoupens: '',
          isLoader: false
        })
      }
    ).catch(
      err => {
        console.log(err)
        const errorCode = err.code;
        const errorMessage = err.message;
        toast.error(errorMessage)
        this.setState({ isLoader: false })

      }
    )
  }
  render() {
    return (
      <section>
        <div className='container'>
          {/* <div className='right-div-item'>
            <Link className='btn btn-secondary' to="/admin/coupen-details">Details</Link>
          </div> */}
          <div className='row'>
            <div className='col-12 text-start'>
              <IoArrowBackCircleOutline className='cursor-pointer' size={24} onClick={() => { this.props.router.navigate(`/admin/generated-coupon`) }} />
            </div>
            <div className='col-12'>
              <h4>Generate Coupon</h4>
            </div>
          </div>
        </div>
        <div className='container div-center'>

          <div className='div-500'>
            <Formik
              enableReinitialize
              initialValues={{
                manufacturer: this.state.manufacturerName,
                country: this.state.country,
                noOfCoupens: this.state.noOfCoupens,
              }}
              validationSchema={Yup.object({
                manufacturer: Yup.string().required("Manufacturer name is required"),
                country: Yup.string().required("Country is required"),
                noOfCoupens: Yup.string().required("No of Coupons required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this.generateCoupens(values)
                }, 400);
              }}
            >
              <Form className="mt-4" autoComplete="off">
                <div className='row'>
                  <div className='col-12'>
                    <div className="text-left">
                      <label htmlFor="manufacturer" className="fs-16 fw-600 mb-1">Name of Manufacturer</label>
                      <Field
                        name="manufacturer" type="text"
                        value={this.state.manufacturerName}
                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                        placeholder="Enter Name of manufacturer"
                        onChange={(e) => { this.setValues('manufacturerName', e.target.value) }} />
                      <ErrorMessage component="span" className="error-msg" name="manufacturer" />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="text-left">
                      <label htmlFor="country" className="fs-16 fw-600 mb-1">Country</label>
                      <Field name="country" disabled={true} value='Italy' as="select" className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1" >
                        <option value='Italy'>Italy</option>
                      </Field>
                      <ErrorMessage component="span" className="error-msg" name="country" />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="text-left">
                      <label htmlFor="noOfCoupens" className="fs-16 fw-600 mb-1">No of codes</label>
                      <Field
                        name="noOfCoupens" type="text"
                        value={this.state.noOfCoupens}
                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                        placeholder="Enter No of Coupons"
                        onChange={(e) => { this.setValues('noOfCoupens', e.target.value) }} />
                      <ErrorMessage component="span" className="error-msg" name="noOfCoupens" />
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-orange d-block m-auto mt-3 rounded-pill">
                  {
                    this.state.isLoader ?
                      <span>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </span>
                      :
                      'Submit'
                  }
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </section>
    )
  }
}
export default withRouter(GenerateCoupen)