import { Component } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from "material-react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import authService from '../../services/auth.service';
import { userProfile } from '../../utils/common.utils';
import { withTranslation } from 'react-i18next';

class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoader: false,
        }
        this.email = userProfile().email
    }

    componentDidMount() {
    }

    onSubmit = (values) => {
        this.setState({ isLoader: true })
        authService.updatePassword(values.oldPassword, values.newPassword, this.setData)
    }
    setData = (isError, data) => {
        if (isError) {
            toast.error(data?.message)
        } else {
            console.log('data - ', data)
            toast.success('Password updated Successfully')
        }
        this.setState({ isLoader: false })

    }
    render() {
        const { t } = this.props;
        return (
            <section className='verify-email-page position-absolute w-100 h-100 top-0'>
                <div className='h-100 d-flex justify-content-center align-items-center'>
                    <div className="contact-form-wrapper d-flex justify-content-center">
                        <Formik
                            enableReinitialize
                            initialValues={{
                                oldPassword: '',
                                newPassword: '',
                            }}
                            validationSchema={Yup.object({
                                oldPassword: Yup.string().required(`${t("currentPasswordRequired")}`),
                                newPassword: Yup.string().required("New password required")
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    this.onSubmit(values)
                                }, 400);
                            }}
                        >
                            <Form className="contact-form" autoComplete="off">
                                <h5 className="title">{t("changePassword")}</h5>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="text-left">
                                            <label htmlFor="oldPassword" className="fs-16 fw-600 mb-1">{t("currentPassword")}</label>
                                            <Field
                                                name="oldPassword" type="password"
                                                className="w-100 ps-3 input_field-height rounded-3 border-1"
                                                placeholder={t("enterCurrentPassword")}
                                            />
                                            <ErrorMessage component="span" className="error-msg" name="oldPassword" />
                                        </div>
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <div className="text-left">
                                            <label htmlFor="newPassword" className="fs-16 fw-600 mb-1">{t("newPassword")}</label>
                                            <Field
                                                name="newPassword" type="password"
                                                className="w-100 ps-3 input_field-height rounded-3 border-1"
                                                placeholder="Enter new password"
                                            />
                                            <ErrorMessage component="span" className="error-msg" name="newPassword" />
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-orange d-block m-auto mt-3 rounded-pill">
                                    {
                                        this.state.isLoader ?
                                            <span>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Loading...
                                            </span>
                                            :
                                            `${t("submit")}`
                                    }
                                </button>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </section>
        )
    }
}

export default withTranslation()(ChangePassword);
