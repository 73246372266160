import { Component } from "react";
import { Outlet, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SignUp from "../vendor/SignUp";
import SignIn from "../vendor/SignIn";
import { BsFillChatSquareTextFill, BsPersonCircle } from "react-icons/bs";
import UserModal from "../vendor/UserModal";
import {
  userRole,
  withRouter,
  vendorId,
  userCountry,
} from "../../utils/common.utils";
import logo from "../../images/logo.png";
import vendorService from "../../services/vendor.service";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { withTranslation } from "react-i18next";
import ChangeCountryModal from "./ChangeCountryModal";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerModal: false,
      signInModal: false,
      countryModal: true,
      userModal: false,
      chatBox: false,
      isAuthenticated: false,
      userRole: userRole(),
      productSearchKey: "",
      profile: {},
    };
    this.userId = vendorId();
    this.country = userCountry();
  }
  componentDidMount() {
    if (localStorage.getItem("user")) {
      this.setState({ isAuthenticated: true, countryModal: false });
      this.getProfile();
    } else {
      if (this.country) {
        console.log(this.country);
        this.setState({ countryModal: false });
      }
    }
  }

  closeMenu = () => {
    this.setState({ userModal: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  };

  authenticated = (role) => {
    this.props.updateRole(role);
    this.setState({
      isAuthenticated: true,
      signInModal: false,
      registerModal: false,
      countryModal: false,
      userRole: role,
    });
  };

  searchProduct = () => {
    this.props.router.navigate(
      `/search-products/${this.state.productSearchKey}`
    );
    this.setState({ productSearchKey: "" });
  };
  getProfile = () => {
    onSnapshot(doc(db, "profiles", this.userId), (snap) => {
      this.setState({ profile: snap.data() });
    });
    vendorService
      .getSingleProfile(this.userId)
      .then((userCredential) => {
        this.setState({ profile: userCredential.data() });
      })
      .catch((error) => {
        console.log(error.message + " - " + error.code);
        this.setState({ isLoader: false });
        // ..
      });
  };
  render() {
    const { t } = this.props;
    console.log(this.state.profile);
    return (
      <div>
        <section className="h-10vh h-sm-24vh">
          <nav
            className="navbar navbar-light bg-black justify-content-between h-100"
            style={{ zIndex: 1 }}
          >
            <div className="container">
              <div className="row top-navbar">
                <div className="col-lg-4 mb-lg-0 col-md-12 mb-md-4 col-12">
                  <div className="float-lg-start">
                    <Link className="text-decoration-none text-white" to="/">
                      <img
                        src={logo}
                        className=""
                        alt="..."
                        style={{ height: "3rem" }}
                      />
                    </Link>
                    {/* <Link className='text-decoration-none text-white' to="/">BIKE CONNECT</Link> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <form className="d-flex my-3">
                    <input
                      className="form-control me-2 rounded-pill"
                      onChange={(e) => {
                        this.setState({ productSearchKey: e.target.value });
                      }}
                      type="search"
                      placeholder={t("searchBikeComponentsBrands")}
                      aria-label="Search"
                    />
                    <button
                      className="btn btn-outline-orange rounded-pill"
                      disabled={!this.state.productSearchKey}
                      type="search"
                      onClick={this.searchProduct}
                    >
                      {t("search")}
                    </button>
                  </form>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="float-lg-end float-md-end">
                    {this.state.isAuthenticated ? (
                      <div>
                        {this.state.userRole === "vendor" ? (
                          <div className="d-sm-block mt-1 mt-lg-0 mt-md-0 m-lg-0 m-md-0 m-auto">
                            <button
                              className="btn btn-orange rounded-pill"
                              type="button"
                            >
                              <Link
                                className="text-decoration-none text-black"
                                to="/vendor/manage-request"
                              >
                                Requests
                              </Link>{" "}
                            </button>

                            <Link
                              className="text-decoration-none text-black position-relative"
                              to="/vendor/chat-list"
                            >
                              <BsFillChatSquareTextFill className="text-white social-ic ms-4 cursor-pointer" />
                              {this.state.profile?.unReadCount != undefined &&
                              this.state.profile?.unReadCount ? (
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                  {this.state.profile.unReadCount}
                                  <span className="visually-hidden">
                                    unread messages
                                  </span>
                                </span>
                              ) : (
                                <span></span>
                              )}
                            </Link>
                            {this.state.profile?.image ? (
                              <img
                                src={this.state.profile.image}
                                alt="img"
                                onClick={() => {
                                  this.setState({ userModal: true });
                                }}
                                className="rounded-circle text-white social-ic ms-4 cursor-pointer"
                                height="30"
                                width="30"
                              />
                            ) : (
                              <BsPersonCircle
                                onClick={() => {
                                  this.setState({ userModal: true });
                                }}
                                className="text-white social-ic ms-4 cursor-pointer"
                              />
                            )}
                          </div>
                        ) : (
                          <div className="d-sm-block mt-1 mt-lg-0 mt-md-0 m-lg-0 m-md-0 m-auto">
                            <button
                              className="btn btn-orange rounded-pill"
                              type="button"
                            >
                              <Link
                                className="text-decoration-none text-black"
                                to="/admin"
                              >
                                Console
                              </Link>{" "}
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="d-flex">
                        <button
                          className="btn btn-orange rounded-pill"
                          style={{ marginRight: "5px" }}
                          type="button"
                          onClick={() => {
                            this.setState({ signInModal: true });
                          }}
                        >
                          Login
                        </button>
                        <button
                          className="btn btn-outline-orange rounded-pill"
                          type="button"
                          onClick={() => {
                            this.setState({ registerModal: true });
                          }}
                        >
                          {t("registerAsVendor")}
                        </button>
                        <button
                          className="btn btn-outline-orange rounded-pill"
                          type="button"
                          onClick={() => {
                            this.setState({ countryModal: true });
                          }}
                        >
                          Change Country
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </section>
        <div className="h-90vh h-sm-76vh">
          <Outlet />
        </div>
        <Modal centered show={this.state.registerModal} dialogClassName="">
          <Modal.Body>
            <SignUp
              close={() => {
                this.setState({ registerModal: false });
              }}
              authenticated={this.authenticated}
            ></SignUp>
          </Modal.Body>
        </Modal>
        <Modal centered show={this.state.signInModal} dialogClassName="">
          <Modal.Body>
            <SignIn
              close={() => {
                this.setState({ signInModal: false });
              }}
              authenticated={this.authenticated}
            ></SignIn>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          show={this.state.userModal}
          dialogClassName="user-modal"
        >
          <Modal.Body>
            <UserModal
              close={() => {
                this.setState({ userModal: false });
              }}
              logout={() => {
                this.setState({ isAuthenticated: false, userModal: false });
              }}
            ></UserModal>
          </Modal.Body>
        </Modal>
        <Modal centered show={this.state.countryModal} dialogClassName="">
          <Modal.Body>
            <ChangeCountryModal
              close={() => {
                this.setState({ countryModal: false });
              }}
              authenticated={this.authenticated}
            ></ChangeCountryModal>
          </Modal.Body>
        </Modal>

        {/* <Modal centered show={this.state.chatBox} dialogClassName="chat-modal">
                    <Modal.Body>
                        <ChatModal close={() => { this.setState({ chatBox: false }) }} logout={() => { this.setState({ isAuthenticated: false, chatBox: false }) }}></ChatModal>
                    </Modal.Body>
                </Modal> */}
      </div>
    );
  }
}
export default withRouter(withTranslation()(Home));
