import { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "react-bootstrap-submenu/dist/index.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavDropdown } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import "../../styles/common.css";
import facebook from "../../images/social-icon/facebook.png";
import whatsapp from "../../images/social-icon/whatsapp.png";
import instagram from "../../images/social-icon/instagram.png";
import linkdin from "../../images/social-icon/linkedin.png";
import youtube from "../../images/social-icon/youtube.png";
import twitter from "../../images/social-icon/twitter.png";
import { Link } from "react-router-dom";
import adminService from "../../services/admin.service";
import { withRouter } from "../../utils/common.utils";
import vendorService from "../../services/vendor.service";
import Select from "react-select";
import { toast } from "material-react-toastify";
import DefaultImage from "../../images/no_image.jpg";
import TermAndCondition from "../../file/term-and-conditions.pdf";
import ItalyCities from "../../file/italy_cities.csv";
import Papa from "papaparse";
import { withTranslation } from "react-i18next";

const vendorCarousel = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const brandCarousel = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};
class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownData: [],
      socialLinks: {},
      pramotionalImages: [],
      brandImages: [],
      vendors: [],
      searchVendors: [],
      activePage: 15,
      selectedIndex: 0,
      vendorSearchKey: "",
      selectedOption: {
        value: null,
        label: "All Cities",
      },
      selectedBrandOption: {
        value: null,
        label: "All Brands",
      },
      selectedAvailabilityOption: {
        value: null,
        label: "All",
      },
      selectedProvinceOption: {
        value: null,
        label: "All Provinces",
      },
      brands: [],
      activePage: 1,
      profileCount: 0,
      allFilterVendors: [],
      searchPagination: false,
    };
    this.style = {
      chips: {
        background: "red",
      },
      searchBox: {
        border: "none",
        "border-bottom": "1px solid blue",
        "border-radius": "0px",
      },
      multiselectContainer: {
        color: "red",
      },
    };
    this.profileCount = 0;
    this.brandsVendor = [];
    this.availableOption = [
      { value: null, label: "All" },
      { value: true, label: "Available to Repair" },
      { value: false, label: "Not Available to Repair" },
    ];
    // this.addItem = this.addItem.bind(this);
  }

  componentDidMount() {
    this.getData(this.updateState);
  }

  getData = (updateState) => {
    this.props.loaderChange(true);

    adminService
      .getAllCategories()
      .then((res) => {
        let cityObj = [];
        let province = [];
        Papa.parse(ItalyCities, {
          header: true,
          download: true,
          complete: function (results) {
            var provinceList = results.data.map((e) => e.Column1);
            provinceList = Array.from(new Set(provinceList));
            provinceList = provinceList.sort();
            provinceList.forEach((e) => {
              let temp = {
                value: e,
                label: e,
              };
              province.push(temp);
            });
            updateState({
              csvData: results.data,
              province: province,
              cities: cityObj,
              dropDownData: res.data().macroCategories,
              socialLinks: res.data().socialLinks,
              pramotionalImages: res.data().pramotionalImages,
              brandImages: res.data().brandImages,
            });
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  updateState = (updateValues) => {
    this.setState(updateValues);
    this.getAllBrands();
  };

  getAllBrands = () => {
    adminService
      .getAllBrands()
      .then((res) => {
        let brands = res ? res : [];
        let brandsList = brands.sort();
        let brandsObj = [];
        brandsObj.push({
          value: null,
          label: "All Brands",
        });
        brandsList.map((el) => {
          let temp = {
            value: el,
            label: el,
          };
          brandsObj.push(temp);
        });
        this.setState({ brands: brandsObj });
        this.getProfileCount();
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  getProfileCount = () => {
    adminService
      .getCompleteProfileCount()
      .then((res) => {
        console.log(JSON.stringify(res.data()));
        this.profileCount = res.data()?.count;
        this.setState({ profileCount: res.data()?.count });
        this.getAllVendors(
          this.state.activePage,
          this.state.selectedAvailabilityOption.value
        );
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  getAllVendors = (page, selectedAvailabilityOption) => {
    adminService
      .getAllVendors(page, selectedAvailabilityOption)
      .then((res) => {
        let vendors = res ? res : [];
        this.setState({
          vendors: vendors,
          activePage: page,
          searchPagination: false,
          profileCount: this.profileCount,
        });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  toStore = (i) => {
    this.props.router.navigate(`/category/${i}`);
  };
  toProducts = (path) => {
    this.props.router.navigate(`/products?${path}`);
  };

  toSearchVendors = () => {
    this.props.router.navigate(`/search-vendors/${this.state.vendorSearchKey}`);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.loaderChange(true);
    if (selectedOption.value) {
      if (this.state.selectedBrandOption?.value) {
        this.brandAndCityFilter(selectedOption.value, this.brandsVendor);
      } else {
        adminService
          .filterVenderByCity(
            selectedOption.value,
            this.state.selectedAvailabilityOption
          )
          .then((res) => {
            let vendors = res ? res : [];
            let vendorsFirst = vendors.slice(0, 12);
            this.setState({
              searchPagination: true,
              vendors: vendorsFirst,
              allFilterVendors: vendors,
              profileCount: vendors?.length,
            });
            this.props.loaderChange(false);
          })
          .catch((err) => {
            console.log(err);
            this.props.loaderChange(false);
          });
      }
    } else {
      if (this.state.selectedBrandOption?.value) {
        this.handleBrandChange(this.state.selectedBrandOption);
      } else {
        this.clearSearch();
      }
      this.props.loaderChange(false);
    }
  };
  handleProvinceChange = (selectedProvinceOption) => {
    var cities = [];
    this.state.csvData.map((e) => {
      if (e.Column1 == selectedProvinceOption.value) {
        let temp = {
          value: e.Comune,
          label: e.Comune,
        };
        cities.push(temp);
      }
    });
    this.setState({
      cities,
      selectedProvinceOption,
    });
  };
  handleBrandChange = (selectedBrandOption) => {
    this.setState({ selectedBrandOption });
    if (selectedBrandOption.value) {
      this.getBrandVendor(selectedBrandOption.value);
    } else {
      if (this.state.selectedOption.value) {
        setTimeout(() => this.handleChange(this.state.selectedOption), 1000);
        // setInterval(this.handleChange(this.state.selectedOption), 2000);
      } else {
        this.clearSearch();
      }
    }
  };
  handleAvaiableChange = (selectedAvailabilityOption) => {
    this.setState({ selectedAvailabilityOption });
    if (
      this.state.selectedBrandOption.value ||
      this.state.selectedOption.value
    ) {
      this.handleBrandChange(this.state.selectedBrandOption);
    } else {
      this.getAllVendors(
        this.state.activePage,
        selectedAvailabilityOption.value
      );
    }
  };
  clearSearch = () => {
    this.props.loaderChange(true);
    this.setState({
      selectedOption: {
        value: null,
        label: "All Cities",
      },
      selectedBrandOption: {
        value: null,
        label: "All Brands",
      },
      selectedProvinceOption: {
        value: null,
        label: "All Provinces",
      },
      cities: [],
      activePage: 1,
    });
    this.getAllVendors(1);
  };
  brandAndCityFilter = (city, vendors) => {
    adminService
      .filterVenderByCityNBrand(
        city,
        vendors,
        this.state.selectedAvailabilityOption.value
      )
      .then((res) => {
        let vendors = res ? res : [];
        let vendorsFirst = vendors.slice(0, 12);
        this.setState({
          searchPagination: true,
          vendors: vendorsFirst,
          allFilterVendors: vendors,
          profileCount: vendors?.length,
        });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  getBrandVendor = (brand) => {
    this.props.loaderChange(true);
    vendorService
      .getBrandVendor(brand)
      .then((res) => {
        let vendors = res.data();
        let reqVendorsObj = [];
        for (let req in vendors) {
          reqVendorsObj.push(req);
        }
        this.brandsVendor = reqVendorsObj;
        this.getAllProfilesByUid(reqVendorsObj);
      })
      .catch((err) => {
        this.props.loaderChange(false);
        const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  getAllProfilesByUid = (list) => {
    if (this.state.selectedOption?.value) {
      this.brandAndCityFilter(this.state.selectedOption.value, list);
    } else {
      vendorService
        .getAllProfilesByUid(list, this.state.selectedAvailabilityOption.value)
        .then((res) => {
          let vendors = res.slice(0, 12);
          this.setState({
            searchPagination: true,
            vendors: vendors,
            allFilterVendors: res,
            profileCount: res?.length,
          });
          this.props.loaderChange(false);
        })
        .catch((err) => {
          console.log(err);
          this.props.loaderChange(false);
        });
    }
  };
  vendorPagination = (page) => {
    if (this.state.searchPagination) {
      let reviews = this.state.allFilterVendors.slice(
        (page - 1) * 12,
        page * 12
      );
      this.setState({ activePage: page, vendors: reviews });
    } else {
      this.props.loaderChange(true);
      this.getAllVendors(page);
    }
  };

  render() {
    const { t } = this.props;
    const {
      selectedOption,
      selectedProvinceOption,
      selectedBrandOption,
      selectedAvailabilityOption,
    } = this.state;
    return (
      <div className="">
        <section className="bg-dark">
          <div className="container-fluid">
            <Navbar collapseOnSelect expand="lg" variant="dark">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto d-flex justify-content-between w-100 text-uppercase">
                  {this.state.dropDownData.map((el, i) => {
                    if (el.subCategory?.length) {
                      return (
                        <NavDropdownMenu
                          key={i}
                          title={el?.name}
                          id="collasible-nav-dropdown"
                          className="fw-bold"
                        >
                          {el?.subCategory.map((sub, j) => {
                            if (sub?.miniCategory?.length) {
                              return (
                                <DropdownSubmenu
                                  key={j}
                                  title={sub?.name}
                                  className="sub-category-dropdown text-white"
                                >
                                  {sub?.miniCategory.map((mini, k) => {
                                    if (mini?.microategory?.length) {
                                      return (
                                        <DropdownSubmenu
                                          key={k}
                                          title={mini?.name}
                                          className="mini-category-dropdown text-white"
                                        >
                                          {mini.microategory.map((micro, l) => {
                                            return (
                                              <NavDropdown.Item
                                                key={l}
                                                onClick={() => {
                                                  this.toProducts(
                                                    `macroCategory=${el?.name}&subCategory=${sub?.name}&miniCategory=${mini?.name}&microCategory=${micro}`
                                                  );
                                                }}
                                              >
                                                {micro}
                                              </NavDropdown.Item>
                                            );
                                          })}
                                        </DropdownSubmenu>
                                      );
                                    } else {
                                      return (
                                        <NavDropdown.Item
                                          key={k}
                                          onClick={() => {
                                            this.toProducts(
                                              `macroCategory=${el?.name}&subCategory=${sub?.name}&miniCategory=${mini?.name}`
                                            );
                                          }}
                                        >
                                          {mini?.name}
                                        </NavDropdown.Item>
                                      );
                                    }
                                  })}
                                </DropdownSubmenu>
                              );
                            } else {
                              return (
                                <NavDropdown.Item
                                  key={j}
                                  onClick={() => {
                                    this.toProducts(
                                      `macroCategory=${el?.name}&subCategory=${sub?.name}`
                                    );
                                  }}
                                >
                                  {sub?.name}
                                </NavDropdown.Item>
                              );
                            }
                          })}
                        </NavDropdownMenu>
                      );
                    } else {
                      return (
                        <NavDropdown.Item
                          key={i}
                          onClick={() => {
                            this.toProducts(`macroCategory=${el.name}`);
                          }}
                          style={{ color: "#fff" }}
                        >
                          {el?.name}
                        </NavDropdown.Item>
                      );
                    }
                  })}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </section>

        <section className="container-fluid p-0">
          <div className="vendor-carousel">
            <Carousel
              responsive={vendorCarousel}
              infinite={true}
              autoPlay={this.props.deviceType !== "mobile" ? true : false}
              autoPlaySpeed={2500}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              itemclassName="padding-40-px"
            >
              {this.state.pramotionalImages.map((el, i) => {
                return (
                  <div key={i}>
                    <img
                      src={el}
                      className="vendor_carousel-img"
                      alt="banner-1"
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </section>
        <section className="container">
          <div className="text-start mt-5">
            <h3>{t("popularBrands")}</h3>
          </div>
          <div className="brand-carousel">
            <Carousel
              responsive={brandCarousel}
              infinite={true}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              autoPlay={this.props.deviceType !== "mobile" ? true : false}
              autoPlaySpeed={2000}
              itemclassName="padding-40-px"
            >
              {this.state.brandImages.map((el, i) => {
                return (
                  <div key={i}>
                    <img
                      src={el}
                      className="brand_carousel-img border border-dark border-2"
                      alt="banner-1"
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
          <div className="row">
            <form className="d-flex col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 mt-5">
              <input
                className="form-control rounded-pill border-black"
                onChange={(e) => {
                  this.setState({ vendorSearchKey: e.target.value });
                }}
                type="search"
                placeholder={t("searchForVendor")}
                aria-label="Search"
              />
              <button
                className="btn btn-orange rounded-pill ms-3"
                type="button"
                disabled={!this.state.vendorSearchKey}
                onClick={this.toSearchVendors}
              >
                <span className="text-decoration-none text-black">
                  {t("search")}
                </span>{" "}
              </button>
            </form>
          </div>
        </section>
        <section className="container filter-section">
          <div className="text-start mt-5 mb-3">
            <h3>{t("filter")}</h3>
            <div className="d-flex">
              {/* <Dropdown>
                <Dropdown.Toggle variant="" className='btn-orange light-border-around text-white' id="dropdown-basic">
                  <b className='px-5'>{DROPDOWN_FILTER[this.state.selectedIndex].label}</b>

                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {
                    DROPDOWN_FILTER.map((game, i) => {
                      return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.label} </Dropdown.Item>
                    })
                  }
                </Dropdown.Menu>
              </Dropdown> */}
              <div className="d-flex mw-100">
                <Select
                  value={selectedProvinceOption}
                  onChange={this.handleProvinceChange}
                  options={this.state.province}
                  className="filter-home"
                  placeholder="Select Province"
                />
                <Select
                  value={selectedOption}
                  onChange={this.handleChange}
                  options={this.state.cities}
                  className="filter-home"
                  placeholder="Select City"
                />
                <Select
                  value={selectedBrandOption}
                  onChange={this.handleBrandChange}
                  options={this.state.brands}
                  className="filter-home ms-2"
                  placeholder="Select Brand"
                />
                <Select
                  value={selectedAvailabilityOption}
                  onChange={this.handleAvaiableChange}
                  options={this.availableOption}
                  className="filter-home ms-2"
                  placeholder="Select Availability"
                />
                {selectedOption.value ||
                selectedBrandOption.value ||
                selectedAvailabilityOption.value ? (
                  <button
                    className="btn btn-orange css-1s2u09g-control"
                    style={{ marginLeft: "4px" }}
                    type="button"
                    onClick={this.clearSearch}
                  >
                    Clear
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          {this.state.vendors?.length ? (
            <div className="row">
              {this.state.vendors.map((el, i) => {
                return (
                  <div className="col-lg-2 col-md-4 col-6" key={i}>
                    <figure className="snip1171 orange text-start">
                      <img
                        src={el?.image ? el?.image : DefaultImage}
                        className="w-100"
                        alt="sample52"
                      />
                      <figcaption className="position-relative bg-white">
                        <p
                          className="text-truncate text-start m-0"
                          to="/category"
                        >
                          {el?.name}
                        </p>
                        <p
                          className="text-truncate text-start m-0 opacity-50"
                          to="/category"
                        >
                          {el?.city ? el?.city : "-"}
                        </p>
                        <a
                          className="text-decoration-none position-absolute text-white text-uppercase bottom-0 end-0"
                          href="#"
                          onClick={() => {
                            this.toStore(el.id);
                          }}
                        >
                          {t("goToDetails")}
                        </a>
                      </figcaption>
                    </figure>
                  </div>
                );
              })}
              <div className="col-12 mb-4">
                {this.state.searchPagination ? (
                  <div className="float-end mt-3">
                    <button
                      disabled={this.state.activePage === 1}
                      className="btn btn-orange rounded-pill"
                      type="button"
                      onClick={() => {
                        this.vendorPagination(this.state.activePage - 1);
                      }}
                    >
                      Previous
                    </button>
                    <button
                      disabled={
                        this.state.profileCount / this.state.activePage <= 12
                      }
                      className="btn btn-orange rounded-pill"
                      style={{ marginLeft: "4px" }}
                      type="button"
                      onClick={() => {
                        this.vendorPagination(this.state.activePage + 1);
                      }}
                    >
                      {t("next")}
                    </button>
                  </div>
                ) : (
                  <div className="float-end mt-3">
                    <button
                      disabled={this.state.activePage === 1}
                      className="btn btn-orange rounded-pill"
                      type="button"
                      onClick={() => {
                        this.vendorPagination(this.state.activePage - 1);
                      }}
                    >
                      {t("previous")}
                    </button>
                    <button
                      disabled={
                        this.state.profileCount / this.state.activePage <= 12
                      }
                      className="btn btn-orange rounded-pill"
                      style={{ marginLeft: "4px" }}
                      type="button"
                      onClick={() => {
                        this.vendorPagination(this.state.activePage + 1);
                      }}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="text-center">Vendor not found</div>
          )}
        </section>
        <section className="bg-grey footer-section py-4">
          <div className="row mx-0">
            <div className="col-lg-5 col-12 align-self-center">
              <div className="d-flex flex-lg-row flex-column align-items-center ps-lg-5">
                <div className="d-flex">
                  <label className="nav-link text-white">
                    {t("contactUs")} :{" "}
                  </label>
                  <a
                    href="tel:+39 3517745002"
                    className="nav-link text-white text-decoration-none"
                  >
                    {" "}
                    +39 3517745002{" "}
                  </a>
                </div>
                <span className="d-lg-block text-white px-2 d-none"> | </span>
                <div className="d-flex">
                  <label className="nav-link text-white">Email : </label>
                  <a
                    href="mailto:support@bikeconnectstores.com"
                    className="nav-link text-white text-decoration-none"
                  >
                    {" "}
                    support@bikeconnectstores.com{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="d-flex align-items-center justify-content-center py-3">
                <a href={this.state.socialLinks?.Facebook} target="_blank">
                  {" "}
                  <img src={facebook} alt="img" className="mx-2" width={25} />
                </a>
                <a href={this.state.socialLinks?.Whatsapp} target="_blank">
                  <img src={whatsapp} alt="img" className="mx-2" width={25} />
                </a>
                <a href={this.state.socialLinks?.LinkdIn} target="_blank">
                  {" "}
                  <img src={linkdin} alt="img" className="mx-2" width={25} />
                </a>
                <a href={this.state.socialLinks?.Youtube} target="_blank">
                  <img src={youtube} alt="img" className="mx-2" width={25} />
                </a>
                <a href={this.state.socialLinks?.Twitter} target="_blank">
                  {" "}
                  <img src={twitter} alt="img" className="mx-2" width={25} />
                </a>
                <a href={this.state.socialLinks?.Instagram} target="_blank">
                  {" "}
                  <img src={instagram} alt="img" className="mx-2" width={25} />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-12 align-self-center">
              <div className="d-flex align-items-center justify-content-lg-end justify-content-center pe-lg-5">
                {/* <Link className="text-decoration-none text-white" to="/support">
                  <p className="nav-link text-white m-0">Support</p>
                </Link>
                <span className="text-white px-2"> | </span> */}
                <a
                  className="text-decoration-none"
                  href={TermAndCondition + "#toolbar=0"}
                >
                  <p className="nav-link text-white m-0">
                    {t("termsAndConditions")}
                  </p>
                </a>
                <span className="text-white px-2"> | </span>
                <Link
                  className="text-decoration-none text-white mb-0"
                  to="privacy-policy"
                >
                  <p className="nav-link text-white m-0">
                    {t("privacyPolicy")}
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(withTranslation()(HomePage));
