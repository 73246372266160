import { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import authService from "../../services/auth.service";
import { toast } from "material-react-toastify";
import { vendorId, withRouter } from "../../utils/common.utils";
import vendorService from "../../services/vendor.service";
import { withTranslation } from "react-i18next";

class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
    };
    this.userId = vendorId();
  }
  componentDidMount() {
    this.getProfile();
  }
  signOut = () => {
    authService
      .signOut()
      .then((userCredential) => {
        this.props.logout();
        localStorage.clear();
        this.props.router.navigate("/");
        toast.success("Logout Successfully");
      })
      .catch((error) => {
        console.log(error.message + " - " + error.code);
      });
  };
  getProfile = () => {
    vendorService
      .getSingleProfile(this.userId)
      .then((userCredential) => {
        this.setState({ profile: userCredential.data() });
      })
      .catch((error) => {
        console.log(error.message + " - " + error.code);
        this.setState({ isLoader: false });
        // ..
      });
  };

  redirect = (path) => {
    this.props.close();
    this.props.router.navigate(path);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <IoIosCloseCircleOutline
          className="close-icon position-absolute"
          style={{ right: "15px", top: "15px" }}
          onClick={this.props.close}
        />

        <div className=""></div>
        <div className="mt-3">
          <p
            className="border-bottom border-danger cursor-pointer"
            onClick={() => {
              this.redirect("/vendor/profile");
            }}
          >
            {t("updateProfile")}
          </p>
          <p
            className="border-bottom border-danger cursor-pointer"
            onClick={() => {
              this.redirect("/vendor/brand-details");
            }}
          >
            {t("registerAsBrand")}
          </p>
          <p
            className="border-bottom border-danger cursor-pointer"
            onClick={() => {
              this.redirect("/vendor/inventory-list");
            }}
          >
            {t("manageInventory")}
          </p>
          <p
            className="border-bottom border-danger cursor-pointer"
            onClick={() => {
              this.redirect("/vendor/change-password");
            }}
          >
            {t("changePassword")}
          </p>
        </div>
        <div className="mt-4 text-center">
          <button
            className="btn btn-orange rounded-pill"
            onClick={this.signOut}
          >
            {t("signOut")}
          </button>
        </div>
      </div>
    );
  }
}
export default withRouter(withTranslation()(UserModal));
