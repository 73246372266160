import { Component } from "react";
import adminService from "../../services/admin.service";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import { withRouter } from "../../utils/common.utils";
import commonService from "../../services/common.service";
import { AiOutlineCloseCircle, AiOutlineUpload } from "react-icons/ai";
import Spinner from "react-bootstrap/Spinner";
import { BsCurrencyEuro } from "react-icons/bs";
import { MultiSelect } from "react-multi-select-component";

class Manufacturer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownData: [],
      macroCategory: "",
      subCategory: "",
      miniCategory: "",
      microCategory: "",
      brand: "",
      label: "",
      link: "",
      description: "",
      subCategoryArray: [],
      miniCategoryArray: [],
      microCategoryArray: [],
      images: [],
      isLoader: false,
      retailPrice: 0,
      yearOfManufacture: "",
      yearOfManufactureArray: [],
      frameSizeList: [],
      wheelSizeList: [],
      colors: [],
      primaryColorOption: [],
      secondaryColorOption: [],
      codeList: [],
      country: "",
      countryList: ["India", "Italy"],
      priceList: [],
    };
  }

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    this.props.loaderChange(true);
    this.getYearOfManufactureList();
    adminService
      .getAllCategories()
      .then((res) => {
        let colors = [];
        res.data()?.colors.map((e) => {
          colors.push({ label: e, value: e });
        });
        this.setState({ dropDownData: res.data().macroCategories, colors });
        if (this.props.router?.searchParams?.id) {
          console.log(this.props.router?.searchParams?.id);
          this.getSingleData(
            this.props.router?.searchParams?.id,
            res.data().macroCategories
          );
        } else {
          this.props.loaderChange(false);
        }
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  getSingleData = (id, dropdown) => {
    adminService
      .getSingleCatelogs(id)
      .then((res) => {
        console.log(res.data());
        this.patchValues(res.data(), dropdown);
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  addManufacturer = (data) => {
    this.setState({ isLoader: true });
    if (this.props?.router?.searchParams?.id) {
      adminService
        .updateCatelog(this.props?.router?.searchParams?.id, data)
        .then((res) => {
          toast.success("Updated successfully ");
          this.setState({ isLoader: false });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoader: false });
          const errorMessage = err.message;
          toast.error(errorMessage);
        });
    } else {
      adminService
        .addManufacturer(data)
        .then((res) => {
          toast.success("Data added successfully ");
          this.setState({
            macroCategory: "",
            subCategory: "",
            miniCategory: "",
            microCategory: "",
            brand: "",
            label: "",
            link: "",
            description: "",
            subCategoryArray: [],
            miniCategoryArray: [],
            microCategoryArray: [],
            images: [],
            isLoader: false,
            yearOfManufacture: "",
            retailPrice: 0,
            frameSizeList: [],
            wheelSizeList: [],
            primaryColorOption: [],
            secondaryColorOption: [],
            codeList: [],
            priceList: [],
          });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoader: false });
          const errorMessage = err.message;
          toast.error(errorMessage);
        });
    }
  };
  onMacroChange = async (val) => {
    let i = await this.state.dropDownData
      .map((el) => {
        return el?.name;
      })
      .indexOf(val);
    let subCategory = this.state.dropDownData[i]?.subCategory
      ? this.state.dropDownData[i]?.subCategory
      : [];
    this.setState({
      macroCategory: val,
      subCategoryArray: subCategory,
      miniCategoryArray: [],
      microCategoryArray: [],
      subCategory: "",
      miniCategory: "",
      microCategory: "",
    });
    console.log(val);
  };
  onSubChange = async (val) => {
    let i = await this.state.subCategoryArray
      .map((el) => {
        return el?.name;
      })
      .indexOf(val);
    let miniCategory = this.state.subCategoryArray[i]?.miniCategory
      ? this.state.subCategoryArray[i]?.miniCategory
      : [];
    this.setState({
      subCategory: val,
      miniCategoryArray: miniCategory,
      microCategoryArray: [],
      miniCategory: "",
      microCategory: "",
    });
    console.log(val);
  };
  onMiniChange = async (val) => {
    let i = await this.state.miniCategoryArray
      .map((el) => {
        return el?.name;
      })
      .indexOf(val);
    let microCategory = this.state.miniCategoryArray[i]?.microategory
      ? this.state.miniCategoryArray[i]?.microategory
      : [];
    this.setState({
      miniCategory: val,
      microCategoryArray: microCategory,
      microCategory: "",
    });
  };
  setValues = (key, val) => {
    this.setState({ [key]: val });
  };
  patchValues = async (currentVal, dropdownVal) => {
    let subIndex = await dropdownVal
      .map((el) => {
        return el?.name;
      })
      .indexOf(currentVal?.macroCategory);
    let subCategoryArray = dropdownVal[subIndex]?.subCategory
      ? dropdownVal[subIndex]?.subCategory
      : [];
    let miniIndex = await subCategoryArray
      .map((el) => {
        return el?.name;
      })
      .indexOf(currentVal?.subCategory);
    let miniCategoryArray = subCategoryArray[miniIndex]?.miniCategory
      ? subCategoryArray[miniIndex]?.miniCategory
      : [];
    let microIndex = await miniCategoryArray
      .map((el) => {
        return el?.name;
      })
      .indexOf(currentVal?.miniCategory);
    let microCategoryArray = miniCategoryArray[microIndex]?.microategory
      ? miniCategoryArray[microIndex]?.microategory
      : [];
    this.setState({
      subCategoryArray: subCategoryArray,
      miniCategoryArray: miniCategoryArray,
      microCategoryArray: microCategoryArray,
      brand: currentVal.brand,
      label: currentVal.label,
      link: currentVal.link,
      description: currentVal.discription,
      macroCategory: currentVal.macroCategory,
      subCategory: currentVal.subCategory,
      miniCategory: currentVal.miniCategory,
      microCategory: currentVal.microCategory,
      images: currentVal.images,
      yearOfManufacture: currentVal.yearOfManufacture,
      retailPrice: currentVal.retailPrice,
      frameSizeList: currentVal.frameSizeList,
      wheelSizeList: currentVal.wheelSizeList,
      priceList:currentVal.priceList,
      codeList:currentVal.codeList
    });
    this.props.loaderChange(false);
  };
  handleImages = (e) => {
    commonService.uploadMultipleImages(
      e.target.files,
      this.getUrl,
      "catlogImages"
    );
  };
  getUrl = (url) => {
    let images = this.state.images;
    for (let img of url) {
      if (!images.includes(img)) {
        images.push(img);
      }
    }
    console.log(url);
    console.log("img 0 - ", images);
    this.setState({ images: images });
  };

  removeImg = (i) => {
    let images = this.state.images;
    images.splice(i, 1);
    this.setState({ images: images });
  };
  getYearOfManufactureList = () => {
    var currentTime = new Date();
    var currentYear = currentTime.getFullYear();
    let yearOfManufactureArray = [];
    for (let i = 2022; i <= currentYear; i++) {
      yearOfManufactureArray.push(i);
    }
    console.log(yearOfManufactureArray);
    this.setState({ yearOfManufactureArray: yearOfManufactureArray });
  };
  handleFrameSizeList = () => {
    if (this.state.frameSize) {
      let frameSizeList = this.state.frameSizeList
        ? this.state.frameSizeList
        : [];
      frameSizeList.push(this.state.frameSize);
      console.log(frameSizeList);
      this.setState({ frameSize: "" });
      this.setState({ frameSizeList });
    }
  };
  removeFrame = (i) => {
    let frameSizeList = this.state.frameSizeList;
    frameSizeList.splice(i, 1);
    this.setState({ frameSizeList: frameSizeList });
  };
  handleWheelSizeList = () => {
    if (this.state.wheelSize) {
      let wheelSizeList = this.state.wheelSizeList
        ? this.state.wheelSizeList
        : [];
      wheelSizeList.push(this.state.wheelSize);
      console.log(wheelSizeList);
      this.setState({ wheelSize: "" });
      this.setState({ wheelSizeList });
    }
  };
  removeWheel = (i) => {
    let wheelSizeList = this.state.wheelSizeList;
    wheelSizeList.splice(i, 1);
    this.setState({ wheelSizeList: wheelSizeList });
  };
  handlesecondaryColorOptionList = () => {
    if (this.state.secondaryColor) {
      let secondaryColorOption = this.state.secondaryColorOption
        ? this.state.secondaryColorOption
        : [];
      secondaryColorOption.push(this.state.secondaryColor);
      console.log(secondaryColorOption);
      this.setState({ secondaryColor: "" });
      this.setState({ secondaryColorOption });
    }
  };
  removesecondaryColorOption = (i) => {
    let secondaryColorOption = this.state.secondaryColorOption;
    secondaryColorOption.splice(i, 1);
    this.setState({ secondaryColorOption: secondaryColorOption });
  };
  handleCodeList = () => {
    if (this.state.code) {
      let codeList = this.state.codeList ? this.state.codeList : [];
      codeList.push(this.state.code);
      console.log(codeList);
      this.setState({ code: "" });
      this.setState({ codeList });
    }
  };
  removeCodeOption = (i) => {
    let codeList = this.state.codeList;
    codeList.splice(i, 1);
    this.setState({ codeList: codeList });
  };
  handlePriceList = () => {
    if (this.state.country && this.state.price) {
      let priceList = this.state.priceList ? this.state.priceList : [];
      priceList.push({
        country: this.state.country,
        currency: "",
        price: this.state.price,
      });
      console.log(priceList);
      this.setState({ country: "", price: 0 });
      this.setState({ priceList });
    }
  };
  removePriceOption = (i) => {
    let priceList = this.state.priceList;
    priceList.splice(i, 1);
    this.setState({ priceList: priceList });
  };
  selectColor = (e) => {
    let selectedColor = [];
    e.map((e) => {
      selectedColor.push(e.value);
    });
    return selectedColor;
  };
  onCountryChange = (e) => {
    this.setState({ country: e });
  };
  render() {
    return (
      <section>
        <div className="container">
          <div>
            <h4>Manufacturer CRUD</h4>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              brand: this.state.brand,
              label: this.state.label,
              link: this.state.link ?? "",
              macroCategory: this.state.macroCategory,
              subCategory: this.state.subCategory,
              miniCategory: this.state.miniCategory,
              microCategory: this.state.microCategory,
              discription: this.state.description,
              images: this.state.images,
              retailPrice: this.state.retailPrice ?? "",
              yearOfManufacture: this.state.yearOfManufacture ?? "",
              frameSizeList: this.state.frameSizeList,
              wheelSizeList: this.state.wheelSizeList,
              secondaryColorOption: this.state.secondaryColorOption,
              primaryColorOption: this.selectColor(
                this.state.primaryColorOption
              ),
              codeList: this.state.codeList,
              priceList: this.state.priceList,
              countryList: this.state.priceList.map((e) => {
                return e.country;
              }),
            }}
            validationSchema={Yup.object({
              brand: Yup.string().required("Brand name is required"),
              label: Yup.string().required("Label is required"),
              link: Yup.string().url().required("Link is required"),
              macroCategory: Yup.string().required(
                "Macro category is required"
              ),
              subCategory: "",
              miniCategory: "",
              microCategory: "",
              discription: Yup.string().required("Description is required"),
              yearOfManufacture: Yup.string().required(
                "Year of Manufacture is Required"
              ),
              retailPrice: Yup.number()
                .required("Retail Price is required")
                .test(
                  "Is positive?",
                  "Retail Price must be greater than 0!",
                  (value) => value > 0
                ),
              frameSizeList: "",
              wheelSizeList: "",
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                this.addManufacturer(values);
              }, 400);
            }}
          >
            <Form className="mt-5" autoComplete="off">
              <div className="row">
                <div className="col-4">
                  <div className="col-md-12 col-sm-12 mt-2">
                    <div className="text-left">
                      <label htmlFor="brand" className="fs-16 fw-600 mb-1">
                        Brand
                      </label>
                      <Field
                        name="brand"
                        type="text"
                        value={this.state.brand}
                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                        placeholder="Enter Brand"
                        onChange={(e) => {
                          this.setValues("brand", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="brand"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 mt-2">
                    <div className="text-left">
                      <label htmlFor="label" className="fs-16 fw-600 mb-1">
                        Model
                      </label>
                      <Field
                        name="label"
                        type="text"
                        value={this.state.label}
                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                        placeholder="Enter Model"
                        onChange={(e) => {
                          this.setValues("label", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="label"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-12 mt-3">
                  <div className="text-left">
                    <label htmlFor="discription" className="fs-16 fw-600 mb-1">
                      Description
                    </label>
                    <Field
                      name="discription"
                      type="text"
                      as="textarea"
                      value={this.state.description}
                      maxLength={250}
                      rows="4"
                      className="w-100 ps-3"
                      placeholder="Enter Description"
                      onChange={(e) => {
                        this.setValues("description", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="discription"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 mt-2 d-flex align-items-end">
                  <div className="row">
                    <div className="col-12 d-flex align-self-center mt-4">
                      <div className="text-left">
                        <label htmlFor="codeList" className="fs-16 fw-600 mb-1">
                          Enter Product Codes
                        </label>
                        <Field
                          name="codeList"
                          type="text"
                          value={this.state.code}
                          className="w-100 ps-3 input_field-height rounded-3 border-1"
                          placeholder="Enter Product Code"
                          onChange={(e) => {
                            this.setValues("code", e.target.value);
                          }}
                        />
                        <ErrorMessage
                          component="span"
                          className="error-msg"
                          codeList
                          name="codeList"
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-outline-orange input_field-height ms-2"
                        onClick={this.handleCodeList}
                      >
                        Add
                      </button>
                    </div>
                    <div className="col-12 d-flex align-self-center mt-4">
                      {this.state.codeList
                        ? this.state.codeList.map((el, i) => {
                            return (
                              <div
                                className="d-flex align-items-center h-100"
                                key={i}
                              >
                                <span className=" me-3 d-flex align-items-center btn-orange rounded-pill input_field-height p-2">
                                  {el}
                                  <AiOutlineCloseCircle
                                    className="text-dark ms-1"
                                    onClick={() => {
                                      this.removeCodeOption(i);
                                    }}
                                  />
                                </span>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 mt-2">
                  <div className="text-left">
                    <label htmlFor="country" className="fs-16 fw-600 mb-1">
                      Country
                    </label>
                    <Field
                      name="macroCategory"
                      value={this.state.country}
                      as="select"
                      onChange={(e) => {
                        this.onCountryChange(e.target.value);
                      }}
                      className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                    >
                      <option value="">Select Country</option>
                      {this.state.countryList.map((el, i) => {
                        return (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="country"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mt-2 d-flex align-items-end">
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex">
                        <div className="text-left">
                          <label htmlFor="price" className="fs-16 fw-600 mb-1">
                            Enter Price
                          </label>
                          <Field
                            name="price"
                            type="text"
                            value={this.state.price}
                            className="w-100 ps-3 input_field-height rounded-3 border-1"
                            placeholder="Enter Price for selected Country"
                            onChange={(e) => {
                              this.setValues("price", e.target.value);
                            }}
                          />
                          <ErrorMessage
                            component="span"
                            className="error-msg"
                            name="price"
                          />
                        </div>
                        <button
                          type="button"
                          disabled={!this.state.price && !this.state.country}
                          className="btn btn-outline-orange input_field-height ms-2"
                          onClick={this.handlePriceList}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="col-lg-8 col-md-2 col-3 d-flex align-self-center mt-4">
                        {this.state.priceList
                          ? this.state.priceList.map((el, i) => {
                              return (
                                <div
                                  className="d-flex align-items-center h-100"
                                  key={i}
                                >
                                  <span className=" me-3 d-flex align-items-center btn-orange rounded-pill input_field-height p-2">
                                    {el.country}:{el.price}
                                    <AiOutlineCloseCircle
                                      className="text-dark ms-1"
                                      onClick={() => {
                                        this.removeCodeOption(i);
                                      }}
                                    />
                                  </span>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-sm-12 mt-2">
                  <div className="text-left">
                    <label htmlFor="link" className="fs-16 fw-600 mb-1">
                      Link
                    </label>
                    <Field
                      name="link"
                      type="text"
                      value={this.state.link}
                      className="w-100 ps-3 input_field-height rounded-3 border-1"
                      placeholder="Enter Link"
                      onChange={(e) => {
                        this.setValues("link", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="link"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 mt-2">
                  <div className="text-left">
                    <label
                      htmlFor="macroCategory"
                      className="fs-16 fw-600 mb-1"
                    >
                      Macro Category
                    </label>
                    <Field
                      name="macroCategory"
                      value={this.state.macroCategory}
                      as="select"
                      onChange={(e) => {
                        this.onMacroChange(e.target.value);
                      }}
                      className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                    >
                      <option value="">Select Macro Category</option>
                      {this.state.dropDownData.map((el, i) => {
                        return (
                          <option key={i} value={el?.name}>
                            {el?.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="macroCategory"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mt-2">
                  <div className="text-left">
                    <label htmlFor="subCategory" className="fs-16 fw-600 mb-1">
                      Sub Category
                    </label>
                    <Field
                      name="subCategory"
                      value={this.state.subCategory}
                      as="select"
                      onChange={(e) => {
                        this.onSubChange(e.target.value);
                      }}
                      className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                    >
                      <option value="">Select Sub Category</option>
                      {this.state.subCategoryArray.map((el, i) => {
                        return (
                          <option key={i} value={el?.name}>
                            {el?.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="subCategory"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mt-2">
                  <div className="text-left">
                    <label htmlFor="miniCategory" className="fs-16 fw-600 mb-1">
                      Mini Category
                    </label>
                    <Field
                      name="miniCategory"
                      value={this.state.miniCategory}
                      as="select"
                      onChange={(e) => {
                        this.onMiniChange(e.target.value);
                      }}
                      className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                    >
                      <option value="">Select Mini Category</option>
                      {this.state.miniCategoryArray.map((el, i) => {
                        return (
                          <option key={i} value={el?.name}>
                            {el?.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="miniCategory"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mt-2">
                  <div className="text-left">
                    <label
                      htmlFor="microCategory"
                      className="fs-16 fw-600 mb-1"
                    >
                      Micro Category
                    </label>
                    <Field
                      name="microCategory"
                      value={this.state.microCategory}
                      as="select"
                      onChange={(e) => {
                        this.setState({ microCategory: e.target.value });
                      }}
                      className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                    >
                      <option value="">Select Micro Category</option>
                      {this.state.microCategoryArray.map((el, i) => {
                        return (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="microCategory"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mt-2">
                  <div className="text-left">
                    <label htmlFor="retailPrice" className="fs-16 fw-600 mb-1">
                      Retail Price
                    </label>
                    <div className="d-flex w-100">
                      <Field
                        name="retailPrice"
                        type="text"
                        value={this.state.retailPrice}
                        className="w-100 ps-5 input_field-height rounded-3 border-1"
                        placeholder="Enter Retail Price"
                        onChange={(e) => {
                          this.setValues("retailPrice", e.target.value);
                        }}
                      />
                      <BsCurrencyEuro className="position-absolute mt-3 ms-3" />
                    </div>
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="retailPrice"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mt-2">
                  <div className="text-left">
                    <label
                      htmlFor="yearOfManufacture"
                      className="fs-16 fw-600 mb-1"
                    >
                      Year of Manufacture
                    </label>
                    <Field
                      name="yearOfManufacture"
                      value={this.state.yearOfManufacture}
                      as="select"
                      onChange={(e) => {
                        this.setValues("yearOfManufacture", e.target.value);
                      }}
                      className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                    >
                      <option value="">Select Year of Manufacture</option>
                      {this.state.yearOfManufactureArray.map((el, i) => {
                        return (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="yearOfManufacture"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mt-2">
                  <div className="text-left">
                    <label
                      htmlFor="yearOfManufacture"
                      className="fs-16 fw-600 mb-1"
                    >
                      Primary Color
                    </label>
                    <MultiSelect
                      className="text-dark"
                      options={this.state.colors}
                      value={this.state.primaryColorOption}
                      onChange={(e) => this.setState({ primaryColorOption: e })}
                      labelledBy="Prize Money Collectors"
                    />
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 mt-2 d-flex align-items-end">
                  <div className="text-left">
                    <label
                      htmlFor="secondaryColorOption"
                      className="fs-16 fw-600 mb-1"
                    >
                      Enter Colors
                    </label>
                    <Field
                      name="secondaryColorOption"
                      type="text"
                      value={this.state.secondaryColor}
                      className="w-100 ps-3 input_field-height rounded-3 border-1"
                      placeholder="Enter Colors"
                      onChange={(e) => {
                        this.setValues("secondaryColor", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      secondaryColorOption
                      name="secondaryColorOption"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-orange input_field-height ms-2"
                    onClick={this.handlesecondaryColorOptionList}
                  >
                    Add
                  </button>
                </div>
                <div className="col-md-4 d-flex align-self-center mt-4">
                  {this.state.secondaryColorOption
                    ? this.state.secondaryColorOption.map((el, i) => {
                        return (
                          <div
                            className="d-flex align-items-center h-100"
                            key={i}
                          >
                            <span className=" me-3 d-flex align-items-center btn-orange rounded-pill input_field-height p-2">
                              {el}{" "}
                              <AiOutlineCloseCircle
                                className="text-dark ms-1"
                                onClick={() => {
                                  this.removesecondaryColorOption(i);
                                }}
                              />
                            </span>
                          </div>
                        );
                      })
                    : null}
                </div>
                <div className="col-md-12 col-sm-12 mt-2 d-flex align-items-end">
                  <div className="text-left">
                    <label
                      htmlFor="frameSizeList"
                      className="fs-16 fw-600 mb-1"
                    >
                      Frame Size List(in cms)
                    </label>
                    <Field
                      name="frameSizeList"
                      type="text"
                      value={this.state.frameSize}
                      className="w-100 ps-3 input_field-height rounded-3 border-1"
                      placeholder="Enter Frame Sizes"
                      onChange={(e) => {
                        this.setValues("frameSize", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="frameSizeList"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-orange input_field-height ms-2"
                    onClick={this.handleFrameSizeList}
                  >
                    Add
                  </button>
                </div>
                <div className="col-lg-8 col-md-2 col-3 d-flex align-self-center mt-4">
                  {this.state.frameSizeList
                    ? this.state.frameSizeList.map((el, i) => {
                        return (
                          <div
                            className="d-flex align-items-center h-100"
                            key={i}
                          >
                            <span className=" me-3 d-flex align-items-center btn-orange rounded-pill input_field-height p-2">
                              {el}{" "}
                              <AiOutlineCloseCircle
                                className="text-dark ms-1"
                                onClick={() => {
                                  this.removeFrame(i);
                                }}
                              />
                            </span>
                          </div>
                        );
                      })
                    : null}
                </div>
                <div className="col-md-12 col-sm-12 mt-2 d-flex align-items-end">
                  <div className="text-left">
                    <label
                      htmlFor="wheelSizeList"
                      className="fs-16 fw-600 mb-1"
                    >
                      Wheel Size List(in cms)
                    </label>
                    <Field
                      name="wheelSizeList"
                      type="text"
                      value={this.state.wheelSize}
                      className="w-100 ps-3 input_field-height rounded-3 border-1"
                      placeholder="Enter Wheel Size"
                      onChange={(e) => {
                        this.setValues("wheelSize", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      component="span"
                      className="error-msg"
                      name="wheelSizeList"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-orange input_field-height ms-2"
                    onClick={this.handleWheelSizeList}
                  >
                    Add
                  </button>
                </div>
                <div className="col-lg-8 col-md-2 col-3 d-flex align-self-center mt-4">
                  {this.state.wheelSizeList
                    ? this.state.wheelSizeList.map((el, i) => {
                        return (
                          <div
                            className="d-flex align-items-center h-100"
                            key={i}
                          >
                            <span className=" me-3 d-flex align-items-center btn-orange rounded-pill input_field-height p-2">
                              {el}{" "}
                              <AiOutlineCloseCircle
                                className="text-dark ms-1"
                                onClick={() => {
                                  this.removeWheel(i);
                                }}
                              />
                            </span>
                          </div>
                        );
                      })
                    : null}
                </div>
                <div className="col-lg-12 col-md-4 col-6 mt-lg-3 mt-md-3 text-start">
                  <label htmlFor="microCategory" className="fs-16 fw-600 mb-1 ">
                    Images
                  </label>
                  <div className="row">
                    {/* {
                        this.state.images.length ?
                            null
                            : */}

                    <div className="col-lg-3 col-md-2 col-3 align-self-center">
                      <div className="upload-btn-wrapper">
                        <AiOutlineUpload className="upload-btn" />
                        <input
                          name="images"
                          type="file"
                          multiple
                          className=""
                          onChange={this.handleImages}
                          accept="/image/*"
                        />
                      </div>
                    </div>
                    {/* } */}
                    {this.state.images.map((el, i) => {
                      return (
                        <div className="col-lg-3 col-md-2 col-3" key={i}>
                          <div className="border h-100">
                            <div className="position-relative h-100">
                              <AiOutlineCloseCircle
                                className="position-absolute top-0 start-100 translate-middle text-secondary"
                                onClick={() => {
                                  this.removeImg(i);
                                }}
                              />
                              {this.state.images.length === 0 ? (
                                <img
                                  src={el}
                                  className="promotional-images h-100"
                                  alt="banner-1"
                                />
                              ) : (
                                <img
                                  src={el}
                                  className="promotional-images h-100"
                                  alt="banner-1"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-orange d-block m-auto mt-3 rounded-pill"
              >
                {this.state.isLoader ? (
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </span>
                ) : this.props?.router?.searchParams?.id ? (
                  "Update"
                ) : (
                  "Submit"
                )}
              </button>
            </Form>
          </Formik>
        </div>
      </section>
    );
  }
}
export default withRouter(Manufacturer);
