import { Component } from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import vendorService from '../../services/vendor.service';
export default class ReviewModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }

    }
    onSubmit = (values) => {
        console.log(values)
        let reviews = this.props.reviews;
        reviews.push(values)
        let data = {
            review: reviews
        }
        vendorService.updateProfile(data, this.props.id).then(
            res => {
                this.props.reviewAdded(reviews)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }
    render() {
        return (
            <div className='px-4 pb-4'>
                <IoIosCloseCircleOutline className="position-absolute" style={{ right: '15px', top: '15px' }} onClick={this.props.close} />
                <div className='row'>
                    <div className='text-center mb-3'>
                        <h4>Add Review</h4>
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            name: '',
                            review: '',
                        }}
                        validationSchema={Yup.object({
                            name: Yup.string().required("Name is required"),
                            review: Yup.string().required("Review is required"),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                this.onSubmit(values)
                            }, 400);
                        }}
                    >
                        <Form className="mt-1" autoComplete="off">
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-12 mb-2'>
                                        <label>Name</label>
                                        <Field name="name" type="text" className='w-100 ps-3 input_field-height rounded-3 border-1' placeholder='Enter Name here...' />
                                        <ErrorMessage component="span" className="error-msg" name="name" />
                                    </div>
                                    <div className='col-12'>
                                        <label>Write Review</label>
                                        <Field  as="textarea" rows="3" name="review" type="text" className='w-100 ps-3 rounded-3 border-1' placeholder='Write Review here...' />
                                        <ErrorMessage component="span" className="error-msg" name="review" />
                                    </div>
                                    <div className='col-12 text-center mt-4'>
                                        <button type='submit' className='btn btn-orange'>Add</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        )
    }
}
