import { Component } from 'react'
import Admin from '../components/admin/Admin';
import {
    Routes,
    Route,
    Navigate,
    Outlet
} from "react-router-dom";
import NotFound from '../components/common/NotFound';
import VendorList from '../components/admin/VendorList';
import Home from '../components/common/Home';
import Vendor from '../components/vendor/Vendor';
import ManufacturerList from '../components/admin/ManufacturerList';
import GenerateCoupen from '../components/admin/GenerateCoupen';
import CodeDetails from '../components/admin/CodeDetails';
import Manufacturer from '../components/admin/Manufacturer';
import HomePage from '../components/common/HomePage';
import SelectedCategory from '../components/common/SelectedCategory';
import CreateManageRequest from '../components/vendor/CreateManageRequest';
import Inventory from '../components/vendor/Inventory';
import BrandDetails from '../components/vendor/BrandDetails';
import Profile from '../components/vendor/Profile';
import { isProfileompleted,  userRole } from '../utils/common.utils';
import CreateInventory from '../components/vendor/CreateInventory';
import GeneratedCoupon from '../components/admin/GeneratedCoupon';
import UploadImages from '../components/admin/UploadImages';
import SocialLinks from '../components/admin/SocialLinks';
import ChatList from '../components/vendor/ChatList';
import Products from '../components/common/Products';
import Chat from '../components/vendor/Chat';
import ProductDetails from '../components/common/ProductDetails';
import VendorSearch from '../components/common/VendorSearch';
import ProductSearch from '../components/common/ProductSearch';
import ChangePassword from '../components/vendor/ChangePassword';
import VerifyEmail from '../components/vendor/VerifyEmail';
import PrivacyAndPolicy from '../components/vendor/PrivacyAndPolicy';
import TermsAndConditions from '../components/vendor/TermsAndConditions';

const AdminProtectedRoute = ({ user }) => {
    if (user !== 'admin') {
        return <Navigate id="RouterNavLink" style={{}} to={'/'} replace />;
    }

    return <Outlet />;
};
const VendorProtectedRoute = ({ user }) => {
    if (user !== 'vendor') {
        return <Navigate id="RouterNavLink1" style={{}} to={'/'} replace />;
    }

    return <Outlet />;
};
const ProfileProtectedRoute = ({ children }) => {
    if (!isProfileompleted()) {
        return <Navigate id="RouterNavLink2" style={{}} to={'/vendor/profile'} replace />;
    }

    return children;
};
export default class MainRoutes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userRole: userRole(),
            isEmailVerified: false
        }
    }

    updateRole = (role) => {
        this.setState({ userRole: role })
    }

    render() {
        return (
            <div>
                <Routes>
                    <Route path="/" element={<Home loaderChange={this.props.loaderChange} updateRole={this.updateRole} />} >
                        <Route index element={<HomePage loaderChange={this.props.loaderChange} />} />
                        <Route path="privacy-policy" element={<PrivacyAndPolicy loaderChange={this.props.loaderChange} />} />
                                <Route path="term-and-conditions" element={<TermsAndConditions loaderChange={this.props.loaderChange} />} />
                        <Route element={<VendorProtectedRoute user={this.state.userRole} />}>
                            <Route path="/vendor" element={<Vendor />}>
                                <Route index element={<Profile loaderChange={this.props.loaderChange} />} />
                                <Route path="profile" element={<Profile loaderChange={this.props.loaderChange} />} />
                                <Route path="change-password" element={<ChangePassword loaderChange={this.props.loaderChange} />} />
                                <Route path="verify-email" element={<VerifyEmail loaderChange={this.props.loaderChange} />} />
                                {/* <Route path="chat-list" element={<ChatList loaderChange={this.props.loaderChange} />} /> */}
                                <Route path="chat/:id/:vendorId" element={<Chat loaderChange={this.props.loaderChange} />} />
                                
                                <Route path="chat-list" element={
                                    <ProfileProtectedRoute>
                                        <ChatList loaderChange={this.props.loaderChange} />
                                    </ProfileProtectedRoute>
                                } />
                                <Route path="inventory-list" element={
                                    <ProfileProtectedRoute>
                                        <Inventory loaderChange={this.props.loaderChange} />
                                    </ProfileProtectedRoute>
                                } />
                                <Route path="inventory-crud" element={
                                    <ProfileProtectedRoute>
                                        <CreateInventory loaderChange={this.props.loaderChange} />
                                    </ProfileProtectedRoute>
                                } />
                                <Route path="brand-details" element={
                                    <ProfileProtectedRoute>
                                        <BrandDetails loaderChange={this.props.loaderChange} />
                                    </ProfileProtectedRoute>
                                } />
                                <Route path="manage-request" element={
                                    <ProfileProtectedRoute>
                                        <CreateManageRequest loaderChange={this.props.loaderChange} />
                                    </ProfileProtectedRoute>
                                } />
                            </Route>
                        </Route>
                        <Route path="category/:id" element={<SelectedCategory loaderChange={this.props.loaderChange} />} />
                        <Route path="products" hhhh="yyy" element={<Products loaderChange={this.props.loaderChange} />} />
                        <Route path="product-details/:id" element={<ProductDetails loaderChange={this.props.loaderChange} />} />
                        <Route path="search-vendors/:searchTerm" element={<VendorSearch loaderChange={this.props.loaderChange} />} />
                        <Route path="search-products/:searchTerm" element={<ProductSearch loaderChange={this.props.loaderChange} />} />
                    </Route>
                    <Route element={<AdminProtectedRoute user={this.state.userRole} />}>
                        <Route path="/admin" element={<Admin />}>
                            <Route index element={<VendorList loaderChange={this.props.loaderChange} />} />
                            <Route index path="vendor-list" element={<VendorList loaderChange={this.props.loaderChange} />} />
                            <Route path="manufacturer-list" element={<ManufacturerList loaderChange={this.props.loaderChange} />} />
                            <Route path="generate-coupen" element={<GenerateCoupen loaderChange={this.props.loaderChange} />} />
                            <Route path="coupen-details" element={<CodeDetails loaderChange={this.props.loaderChange} />} />
                            <Route path="manufacturer-crud" element={<Manufacturer loaderChange={this.props.loaderChange} />} />
                            <Route path="generated-coupon" element={<GeneratedCoupon loaderChange={this.props.loaderChange} />} />
                            <Route path="upload-images" element={<UploadImages loaderChange={this.props.loaderChange} />} />
                            <Route path="social-links" element={<SocialLinks loaderChange={this.props.loaderChange} />} />
                        </Route>
                    </Route>
                    {/* <Route path="admin-login" element={<AdminLogin />} /> */}

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        )
    }
}
