import { Component } from 'react'
import { Outlet } from "react-router-dom";
import { userProfile, withRouter } from '../../utils/common.utils';
import VerifyEmail from './VerifyEmail';

class Vendor extends Component {
 
  render() {
    return (
      <div className='h-100'>
        {
        userProfile().emailVerified?
        <Outlet />
        :
        <VerifyEmail />
        }
      </div>
    )
  }
}
export default withRouter(Vendor)