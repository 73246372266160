import { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { userCountry, withRouter } from "../../utils/common.utils";
import { ErrorMessage, Form, Formik } from "formik";
import { Spinner } from "react-bootstrap";
import * as Yup from "yup";
import Select from "react-select";

class ChangeCountryModal extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoader: false, country: null, countries: [] };
  }
  componentDidMount() {
    const country = userCountry();
    if (country) {
      let temp = {
        value: country,
        label: country,
      };
      this.setState({ country: temp });
    }
    const countriesList = ["India", "Italy"];
    const countries = [];
    countriesList.forEach((e) => {
      let temp = {
        value: e,
        label: e,
      };
      countries.push(temp);
    });
    this.setState({ countries: countries });
  }
  handleCountryChange = (country) => {
    console.log(country);
    this.setState({
      country,
    });
  };
  submitForm = (country) => {
    console.log(country);
    localStorage.setItem("country", JSON.stringify({ country: country }));
    this.props.close();
  };
  render() {
    const { country } = this.state;
    return (
      <div>
        <section className="">
          {this.state.country ? (
            <IoIosCloseCircleOutline
              className="close-icon position-absolute "
              style={{ right: "15px", top: "15px", color: "black" }}
              onClick={this.props.close}
            />
          ) : null}
          <Formik
            enableReinitialize
            initialValues={{
              country: this.state.country ? this.state.country.value : "",
            }}
            validationSchema={Yup.object({
              country: Yup.string().required("Country is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              this.submitForm(values.country);
            }}
          >
            <Form>
              <div className="text-left mt-2">
                <label htmlFor="country" className="">
                  Country
                </label>
                <Select
                  name="country"
                  value={country}
                  onChange={this.handleCountryChange}
                  options={this.state.countries}
                  className="dropdown-basic fs-14 py-2 px-3 w-100 input_field-height rounded-3 border-1"
                ></Select>
                <ErrorMessage
                  component="span"
                  className="error-msg"
                  name="country"
                />
              </div>
              <button
                type="submit"
                className="btn btn-orange d-block m-auto my-5 rounded-pill"
              >
                {this.state.isLoader ? (
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </span>
                ) : (
                  "Submit"
                )}
              </button>
            </Form>
          </Formik>
        </section>
      </div>
    );
  }
}

export default withRouter(ChangeCountryModal);
