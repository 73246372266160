import { Component } from 'react';
import facebook from '../../images/social-icon/facebook.png';
import whatsapp from '../../images/social-icon/whatsapp.png';
import instagram from '../../images/social-icon/instagram.png';
import linkdin from '../../images/social-icon/linkedin.png';
import youtube from '../../images/social-icon/youtube.png';
import twitter from '../../images/social-icon/twitter.png';
import adminService from '../../services/admin.service';
import { MdMode } from "react-icons/md";
import { Modal } from 'react-bootstrap';
import LinkModal from './LinkModal';
import { toast } from 'material-react-toastify';


const socialData = [
    { name: 'Facebook', img: facebook, link: '' },
    { name: 'Whatsapp', img: whatsapp, link: '' },
    { name: 'LinkdIn', img: linkdin, link: '' },
    { name: 'Youtube', img: youtube, link: '' },
    { name: 'Twitter', img: twitter, link: '' },
    { name: 'Instagram', img: instagram, link: '' },
]

export default class SocialLinks extends Component {
    constructor(props) {
        super(props)

        this.state = {
            socialLinks: socialData,
            editData: null,
        }

    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        this.props.loaderChange(true)
        adminService.getAllCategories().then(
            res => {
                console.log(res.data());
                socialData.map((el, i) => {
                    socialData[i].link = res.data().socialLinks[socialData[i].name]
                })
                this.setState({ socialLinks: socialData })
                this.props.loaderChange(false)
            }
        ).catch(
            err => {
                console.log(err)
                this.props.loaderChange(false)
            }
        )
    }

    onEdit = (el, i) => {
        const data = {
            index: i,
            data: el
        }
        this.setState({ editData: { ...data } })
    }
    onLinkChange = (data) => {
        let allLinks = this.state.socialLinks;
        allLinks[data?.index] = data?.data;
        let socialLinks = {};
        allLinks.map(el => {
            socialLinks[el.name] = el.link
        })
        let sendData = {
            socialLinks: socialLinks
        }
        adminService.updateMasterData(sendData).then(
            res => {
                console.log(res)
                toast.success('Link updated')
                this.setState({ socialLinks: allLinks, editData: null });
            }
        ).catch(
            err => {
                console.log(err)
                this.setState({ editData: null })
                const errorCode = err.code;
                const errorMessage = err.message;
                toast.error(errorMessage)
            }
        )
    }

    render() {
        return (
            <section>
                <h4>Social Links</h4>
                <div className='container mt-5'>
                    <div className="table-responsive">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th scope="col"><p className='max-width-content mb-0'>Sr. No.</p></th>
                                    <th scope="col"><p className='max-width-content mb-0'>Social Platform</p></th>
                                    <th scope="col" ><p className='max-width-content mb-0 d-flex m-auto'>Image</p></th>
                                    <th scope="col"><p className='max-width-content mb-0 d-flex m-auto'>Link</p></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.socialLinks.map((el, i) => {
                                        return (
                                            <tr className='' key={i}>
                                                <td><p className='max-width-content mb-0'>{i + 1}.</p></td>
                                                <td><p className='max-width-content mb-0'>{el.name}</p></td>
                                                <td><img src={el.img} className='' width={25} /></td>
                                                <td><a href={el.link} target='_blank'>{el?.link ? el.link : '-'}</a></td>
                                                <td>
                                                    <div className='d-flex'>
                                                        <span className="action-button" onClick={() => { this.onEdit(el, i) }}><MdMode></MdMode></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <Modal centered show={this.state.editData} dialogClassName="register_brand-modal">
                    <Modal.Body>
                        <LinkModal editData={this.state.editData} submit={this.onLinkChange} close={() => { this.setState({ editData: null }) }}></LinkModal>
                    </Modal.Body>
                </Modal>
            </section>
        )
    }
}
