import { Component } from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import Spinner from 'react-bootstrap/Spinner';

export default class LinkModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            link: props.editData?.data?.link,
            isLoader: false,
        }

    }
    onLinkChange = () => {
        this.setState({ isLoader: true })
        let data = this.props.editData
        data.data.link = this.state.link;
        this.props.submit(data)
    }
    render() {
        return (
            <div className='text-center'>
                <div className=''>
                    <IoIosCloseCircleOutline className="position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => { this.props.close() }} />
                    <h6>{this.props.editData?.data?.name}</h6>
                </div>
                <div className='mt-4'>
                    <input type='text' value={this.state.link} onChange={(e) => { this.setState({ link: e.target.value }) }} placeholder='Enter link here...' className='w-100 ps-3 input_field-height rounded-3 border-1' />
                    {/* {
                this.state.error ?
                    <div className='text-danger'>{this.state.error}</div>
                    : null
            } */}
                </div>

                <div className='mt-4'>
                    <button className='btn btn-orange rounded-pill' disabled={!this.state.link || this.state.isLoader} onClick={this.onLinkChange}>
                        {
                            this.state.isLoader ?
                                <span>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </span>
                                :
                                'Submit'
                        }
                    </button>
                </div>
            </div>
        )
    }
}
