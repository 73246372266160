import { db } from "../firebase";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { collection } from "firebase/firestore";
const auth = getAuth();
const user = auth.currentUser;
const catlogCollectionRef = collection(db, "manufacturer");

class AuthService {
  signUp = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };
  signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };
  updatePassword = (currentPassword, newPassword, callback) => {
    const tempUser = auth.currentUser;
    const { currentUser } = auth;
    const { email } = currentUser;
    const credential = EmailAuthProvider.credential(email, currentPassword);
    reauthenticateWithCredential(tempUser, credential)
      .then((res) => {
        updatePassword(tempUser, newPassword)
          .then((data) => {
            callback(false, data);
          })
          .catch((err) => {
            console.log("err - ", err);
            callback(true, err);
          });
      })
      .catch((error) => {
        console.log("error - ", error);

        callback(true, error);
      });
  };
  signUpWithLink = (email, password, callback) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((data) => {
        sendEmailVerification(data.user)
          .then((innerData) => {
            callback(false, data, email);
          })
          .catch((err) => {
            callback(true, err, email);
            console.log(err);
          });
      })
      .catch((error) => {
        callback(true, error, email);
        console.log(error);
      });
  };
  sendEmailVerificationLink = () => {
    return sendEmailVerification(auth.currentUser);
  };
  signOut = () => {
    return signOut(auth);
  };
  checkSignInUSer = (callback) => {
    callback(user);
  };
  forgetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };
}
export default new AuthService();
