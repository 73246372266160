import { Component } from "react";
import { Link } from "react-router-dom";
import adminService from "../../services/admin.service";
import { MdDelete, MdMode } from "react-icons/md";
import { limitToLast } from "firebase/firestore";
import { toast } from "material-react-toastify";
import { withRouter } from "../../utils/common.utils";
import Confirmation from "../common/Confirmation";
import { Modal } from "react-bootstrap";
import { BsCurrencyEuro } from "react-icons/bs";
import Select from "react-select";
import vendorService from "../../services/vendor.service";

class ManufacturerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catlogs: [],
      deleteData: null,
      activePage: 1,
      dataCount: 0,
    };
  }

  componentDidMount() {
    this.getmanufactureCount();
  }

  getmanufactureCount = () => {
    this.props.loaderChange(true);
    adminService
      .getmanufactureCount()
      .then((res) => {
        this.setState({ dataCount: res.data()?.count });
        this.getAllBrands();
        this.getData(this.state.activePage);
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };

  getData = async (page) => {
    this.props.loaderChange(true);
    adminService
      .getAllCatelogs(page)
      .then((res) => {
        console.log(res);
        this.setState({ catlogs: res, deleteData: null, activePage: page });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };

  openConfirmation = (id) => {
    let data = {
      id: id,
      msg: "Are you sure you want to delete this Catalogue",
    };
    this.setState({ deleteData: data });
  };

  deleteCatlog = (id) => {
    adminService
      .deleteCatelog(id)
      .then((res) => {
        console.log(res);
        this.getData(1);
        toast.success("Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
        const errorCode = err.code;
        const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  onEdit = (id) => {
    this.props.router.navigate(`/admin/manufacturer-crud?id=${id}`);
    // ('/admin/manufacturer-crud')
  };

  getAllBrands = () => {
    adminService
      .getAllBrands()
      .then((res) => {
        let brands = res ? res : [];
        let cities = brands.sort();
        let cityObj = [];
        cities.map((el) => {
          let temp = {
            value: el,
            label: el,
          };
          cityObj.push(temp);
        });
        this.setState({ brands: cityObj });
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };

  handleBrandChange = (selectedBrandOption) => {
    this.setState({ selectedBrandOption });
    this.getBrandsManufacture(this.state.activePage, selectedBrandOption);
  };

  getBrandsManufacture = (page, val) => {
    this.props.loaderChange(true);
    adminService
      .getBrandCatelogs(page, val.value)
      .then((res) => {
        console.log(res);
        this.setState({ catlogs: res, deleteData: null, activePage: page });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        this.props.loaderChange(false);
        console.log(err);
      });
  };
  clearSearch = () => {
    this.props.loaderChange(true);
    this.setState({
      selectedBrandOption: null,
    });
    this.getmanufactureCount();
  };

  render() {
    const { selectedBrandOption } = this.state;
    return (
      <section>
        <div className="container">
          <h4>Manufacturer List</h4>
          <div className="right-div-item d-flex align-items-center justify-content-end my-5">
            <Select
              value={selectedBrandOption}
              onChange={this.handleBrandChange}
              options={this.state.brands}
              className="filter-home ms-2"
              placeholder="Filter by Brand"
            />
            {selectedBrandOption ? (
              <button
                className="btn btn-orange css-1s2u09g-control"
                style={{ marginLeft: "4px" }}
                type="button"
                onClick={this.clearSearch}
              >
                Clear
              </button>
            ) : null}
            <Link
              className="btn btn-outline-orange rounded-pill ms-3"
              to="/admin/manufacturer-crud"
            >
              Add Manufacturer
            </Link>
          </div>
        </div>

        <div className="container mt-4">
          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">
                    <p className="max-width-content mb-0">BRAND</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">LABEL</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">Macro Category</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">Sub Category</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">Mini Category</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">Micro Category</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">RETAIL PRICE</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">
                      YEAR OF MANUFACTURE
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.catlogs.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <p className="max-width-content mb-0">{el?.brand}</p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">{el?.label}</p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">
                          {el?.macroCategory}
                        </p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">
                          {el?.subCategory}
                        </p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">
                          {el?.miniCategory ? el?.miniCategory : "-"}
                        </p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">
                          {el?.microCategory ? el?.microCategory : "-"}
                        </p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">
                          <BsCurrencyEuro />
                          {el?.retailPrice ? el?.retailPrice : "-"}
                        </p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">
                          {el?.yearOfManufacture ? el?.yearOfManufacture : "-"}
                        </p>
                      </td>
                      <td>
                        <div className="d-flex">
                          <span
                            className="action-button"
                            onClick={() => {
                              this.onEdit(el.id);
                            }}
                          >
                            <MdMode></MdMode>
                          </span>
                          <span
                            className="action-button"
                            onClick={() => {
                              this.openConfirmation(el.id);
                            }}
                          >
                            <MdDelete></MdDelete>
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="float-end mt-3">
            <button
              disabled={this.state.activePage === 1}
              className="btn btn-orange rounded-pill"
              type="button"
              onClick={() => {
                this.getData(this.state.activePage - 1);
              }}
            >
              Previous
            </button>
            <button
              disabled={this.state.dataCount / this.state.activePage <= 10}
              className="btn btn-orange rounded-pill"
              style={{ marginLeft: "4px" }}
              type="button"
              onClick={() => {
                this.getData(this.state.activePage + 1);
              }}
            >
              Next
            </button>
          </div>
        </div>
        <Modal centered show={this.state.deleteData} dialogClassName="">
          <Modal.Body>
            <Confirmation
              data={this.state.deleteData}
              delete={this.deleteCatlog}
              close={() => {
                this.setState({ deleteData: null });
              }}
            ></Confirmation>
          </Modal.Body>
        </Modal>
      </section>
    );
  }
}
export default withRouter(ManufacturerList);
