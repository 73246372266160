import { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import authService from "../../services/auth.service";
import vendorService from "../../services/vendor.service";
import { withRouter } from "../../utils/common.utils";
import Spinner from "react-bootstrap/Spinner";
import { IoFileTray } from "react-icons/io5";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isLoader: false,
      loginModal: true,
      visiblePassword: false,
    };
  }

  onSubmit = (values) => {
    this.setState({ isLoader: true });
    if (this.state.loginModal) {
      authService
        .signIn(values.email, values.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          const data = {
            accessToken: user.accessToken,
            uid: user.uid,
            emailVerified: user.emailVerified,
          };
          this.getProfile(user.uid, data);
          console.log(user);
          // ...
        })
        .catch((error) => {
          toast.error(error?.message);
          console.log(error.message + " - " + error.code);
          const errorCode = error.code;
          const errorMessage = error.message;
          this.setState({ isLoader: false });

          // ..
        });
    } else {
      authService
        .forgetPassword(values.email)
        .then((userCredential) => {
          toast.success("Email Sent!");
          this.setState({ isLoader: false });
          // ...
        })
        .catch((error) => {
          toast.error(error?.message);
          console.log(error.message + " - " + error.code);
          const errorCode = error.code;
          const errorMessage = error.message;
          this.setState({ isLoader: false });

          // ..
        });
    }
  };
  getProfile = (uid, user) => {
    vendorService
      .getSingleProfile(uid)
      .then((userCredential) => {
        let result = userCredential.data();
        result.accessToken = user.accessToken;
        result.uid = user.uid;
        result.emailVerified = user.emailVerified;
        this.props.authenticated(result.role, user.emailVerified);
        console.log(result);
        // user.role = result.role;
        // user.isProfile = result?.isProfile;
        // user.name = result?.name;
        global.user = result;
        localStorage.setItem("user", JSON.stringify(result));
        setTimeout(() => {
          if (result.role === "admin") {
            this.props.router.navigate("/admin");
          } else if (!result?.isProfile && result.role === "vendor") {
            if (user.emailVerified) {
              this.props.router.navigate("/vendor/profile");
            } else {
              this.props.router.navigate("/vendor/verify-email");
            }
          }
          this.setState({ isLoader: false });
          toast.success("Sign In successfully");
        }, 500);
      })
      .catch((error) => {
        toast.error(error?.message);
        console.log(error.message + " - " + error.code);
        const errorCode = error.code;
        const errorMessage = error.message;
        this.setState({ isLoader: false });
        // ..
      });
  };
  setValues = (key, val) => {
    this.setState({ [key]: val });
  };

  toggleLoginModal() {
    this.setState({
      loginModal: !this.state.loginModal,
    });
  }

  togglePassword() {
    console.log(this.state.visiblePassword);
    this.setState({
      visiblePassword: !this.state.visiblePassword,
    });
  }

  render() {
    return (
      <div className="p-3">
        <IoIosCloseCircleOutline
          className="close-icon position-absolute"
          style={{ right: "15px", top: "15px" }}
          onClick={this.props.close}
        />

        {this.state.loginModal ? (
          <div>
            <h4 className="text-center">Vendor sign in</h4>
            <Formik
              enableReinitialize
              initialValues={{
                email: this.state.email,
                password: this.state.password,
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .required("Email is required")
                  .email("Invalid email"),
                password: Yup.string().required("Password is required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this.onSubmit(values);
                }, 400);
              }}
            >
              <Form className="mt-3" autoComplete="off">
                <div className="row">
                  <div className="col-12">
                    <div className="text-left">
                      <label htmlFor="email" className="fs-16 fw-600 mb-1">
                        Email
                      </label>
                      <Field
                        name="email"
                        type="email"
                        value={this.state.email}
                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                        placeholder="Enter Email"
                        onChange={(e) => {
                          this.setValues("email", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="text-left mt-2">
                      <label htmlFor="password" className="fs-16 fw-600 mb-1">
                        Password
                      </label>

                      <div className="position-relative">
                        <Field
                          name="password"
                          type={
                            this.state.visiblePassword ? "text" : "password"
                          }
                          value={this.state.password}
                          className="w-100 ps-3 input_field-height rounded-3 border-1"
                          placeholder="Enter Password"
                          onChange={(e) => {
                            this.setValues("password", e.target.value);
                          }}
                        />
                        {this.state.visiblePassword ? (
                          <AiOutlineEye
                            className="position-absolute top-50 end-0 translate-middle-y me-3"
                            onClick={(e) => this.togglePassword()}
                          ></AiOutlineEye>
                        ) : (
                          <AiOutlineEyeInvisible
                            className="position-absolute top-50 end-0 translate-middle-y me-3"
                            onClick={(e) => this.togglePassword()}
                          ></AiOutlineEyeInvisible>
                        )}
                      </div>
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="password"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <p className="float-end my-2">
                      <a
                        onClick={(e) => {
                          this.toggleLoginModal();
                        }}
                      >
                        Forget Password ?
                      </a>
                    </p>
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={this.state.isLoader}
                  className="btn btn-orange d-block m-auto rounded-pill"
                >
                  {this.state.isLoader ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </Form>
            </Formik>
          </div>
        ) : (
          <div>
            <h4 className="text-center">Forget Password</h4>
            <Formik
              enableReinitialize
              initialValues={{
                email: this.state.email,
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .required("Email is required")
                  .email("Invalid email"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this.onSubmit(values);
                }, 400);
              }}
            >
              <Form className="mt-3" autoComplete="off">
                <div className="row">
                  <div className="col-12">
                    <div className="text-left">
                      <label htmlFor="email" className="fs-16 fw-600 mb-1">
                        Email
                      </label>
                      <Field
                        name="email"
                        type="email"
                        value={this.state.email}
                        className="w-100 ps-3 input_field-height rounded-3 border-1"
                        placeholder="Enter Email"
                        onChange={(e) => {
                          this.setValues("email", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        component="span"
                        className="error-msg"
                        name="email"
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={this.state.isLoader}
                  className="btn btn-orange d-block m-auto mt-3 rounded-pill"
                >
                  {this.state.isLoader ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </span>
                  ) : (
                    "Reset"
                  )}
                </button>
              </Form>
            </Formik>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(SignIn);
