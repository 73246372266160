import { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Cities } from "../../utils/common.utils";
import Pagination from "react-js-pagination";
import adminService from "../../services/admin.service";
import vendorService from "../../services/vendor.service";
import Select from "react-select";
import { toast } from "material-react-toastify";
import algoliasearch from "algoliasearch/lite";
import { Modal } from "react-bootstrap";
import ItalyCities from "../../file/italy_cities.csv";
import Papa from "papaparse";
const searchClient = algoliasearch(
  "J89FYK2680",
  "ad6f144fe0b845dc40ba7d4289b1ed3d"
);
const index = searchClient.initIndex("profiles");

export default class VendorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendors: [],
      activePage: 1,
      profileCount: 0,
      vendorSearchKey: "",
      brandModal: false,
      vendorBrandsList: [],
    };
  }
  componentDidMount() {
    this.getProfileCount();
  }
  updateState = (updateValues) => {
    this.setState(updateValues);
  };

  getData = (page, updateState) => {
    this.props.loaderChange(true);
    let cityObj = [];
    vendorService
      .getAllProfiles(page)
      .then((userCredential) => {
        let province = [];
        Papa.parse(ItalyCities, {
          header: true,
          download: true,
          complete: function (results) {
            var provinceList = results.data.map((e) => e.Column1);
            provinceList = Array.from(new Set(provinceList));
            provinceList.forEach((e) => {
              let temp = {
                value: e,
                label: e,
              };
              province.push(temp);
            });
            updateState({
              csvData: results.data,
              province: province,
              cities: cityObj,
              vendors: userCredential ? userCredential : [],
              activePage: page,
            });
          },
        });
        this.props.loaderChange(false);
      })
      .catch((error) => {
        console.log(error.message + " - " + error.code);
        const errorCode = error.code;
        const errorMessage = error.message;
        this.props.loaderChange(false);
      });
  };
  getAllBrands = () => {
    adminService
      .getAllBrands()
      .then((res) => {
        let brands = res ? res : [];
        let cities = brands.sort();
        let cityObj = [];
        cities.map((el) => {
          let temp = {
            value: el,
            label: el,
          };
          cityObj.push(temp);
        });
        this.setState({ brands: cityObj });
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.loaderChange(true);
    if (this.state.selectedBrandOption?.value) {
      this.brandAndCityFilter(selectedOption.value, this.brandsVendor);
    } else {
      adminService
        .filterVenderByCity(selectedOption.value)
        .then((res) => {
          let vendors = res ? res : [];
          let vendorsFirst = vendors.slice(0, 12);
          this.setState({
            activePage: 1,
            searchPagination: true,
            vendors: vendorsFirst,
            allFilterVendors: vendors,
            profileCount: vendors?.length,
          });
          this.props.loaderChange(false);
        })
        .catch((err) => {
          console.log(err);
          this.props.loaderChange(false);
        });
    }
  };
  handleProvinceChange = (selectedProvinceOption) => {
    var cities = [];
    this.state.csvData.map((e) => {
      if (e.Column1 == selectedProvinceOption.value) {
        let temp = {
          value: e.Comune,
          label: e.Comune,
        };
        cities.push(temp);
      }
    });
    this.setState({
      cities,
      selectedProvinceOption,
    });
  };
  handleBrandChange = (selectedBrandOption) => {
    this.setState({ selectedBrandOption });
    this.getBrandVendor(selectedBrandOption.value);
  };
  getBrandVendor = (brand) => {
    this.props.loaderChange(true);
    vendorService
      .getBrandVendor(brand)
      .then((res) => {
        let vendors = res.data();
        let reqVendorsObj = [];
        for (let req in vendors) {
          reqVendorsObj.push(req);
        }
        this.brandsVendor = reqVendorsObj;
        this.getAllProfilesByUid(reqVendorsObj);
      })
      .catch((err) => {
        this.props.loaderChange(false);
        console.log(err);
        const errorCode = err.code;
        const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  getAllProfilesByUid = (list) => {
    if (this.state.selectedOption?.value) {
      this.brandAndCityFilter(this.state.selectedOption.value, list);
    } else {
      vendorService
        .getAllProfilesByUid(list)
        .then((res) => {
          console.log("vendor list - ", res);
          let vendors = res.slice(0, 12);
          this.setState({
            activePage: 1,
            searchPagination: true,
            vendors: vendors,
            allFilterVendors: res,
            profileCount: res?.length,
          });
          this.props.loaderChange(false);
        })
        .catch((err) => {
          console.log(err);
          this.props.loaderChange(false);
        });
    }
  };
  brandAndCityFilter = (city, vendors) => {
    adminService
      .filterVenderByCityNBrand(city, vendors)
      .then((res) => {
        let vendors = res ? res : [];
        let vendorsFirst = vendors.slice(0, 12);
        this.setState({
          activePage: 1,
          searchPagination: true,
          vendors: vendorsFirst,
          allFilterVendors: vendors,
          profileCount: vendors?.length,
        });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };
  clearSearch = () => {
    this.props.loaderChange(true);
    this.setState({
      selectedOption: null,
      selectedBrandOption: null,
      selectedProvinceOption: null,
      vendorSearchKey: null,
      activePage: 1,
    });
    this.getProfileCount();
  };
  getProfileCount = () => {
    this.props.loaderChange(true);
    adminService
      .getProfileCount()
      .then((res) => {
        console.log(JSON.stringify(res.data()));
        this.setState({ profileCount: res.data()?.count });
        this.getData(this.state.activePage, this.updateState);
        this.getAllBrands();
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };

  searchVendor = () => {
    this.props.loaderChange(true);
    index
      .search(this.state.vendorSearchKey)
      .then(({ hits }) => {
        console.log("vendor list - ", hits);
        let vendors = hits?.length ? hits : [];
        this.setState({
          searchPagination: true,
          vendors: vendors,
          allFilterVendors: hits,
          profileCount: hits?.length,
        });
        this.props.loaderChange(false);
        console.log(hits);
      })
      .catch((err) => {
        this.props.loaderChange(false);
        console.log(err);
      });
  };
  getVendorBrands = (id) => {
    this.props.loaderChange(true);
    this.setState({ brandModal: true });
    console.log(id);
    vendorService
      .getBrands(id)
      .then((res) => {
        var data = res?.data().brands ? res?.data().brands : [];
        console.log(data);
        data = data.filter(function (el) {
          return !el.delivered;
        });
        this.setState({
          vendorBrandsList: data,
        });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        this.props.loaderChange(false);
        console.log(err);
      });
  };

  alterModalStatus = (key, val) => {
    this.setState({ [key]: val });
  };

  render() {
    const { selectedOption, selectedProvinceOption, selectedBrandOption } =
      this.state;
    return (
      <section>
        <h4>Vendor List</h4>
        <div className="container d-flex justify-content-end align-items-center my-5">
          <Select
            value={selectedProvinceOption}
            onChange={this.handleProvinceChange}
            options={this.state.province}
            className="filter-home"
            placeholder="Filter by Province"
          />
          <Select
            value={selectedOption}
            onChange={this.handleChange}
            options={this.state.cities}
            className="filter-home"
            placeholder="Filter by City"
          />
          <Select
            value={selectedBrandOption}
            onChange={this.handleBrandChange}
            options={this.state.brands}
            className="filter-home ms-2"
            placeholder="Filter by Brand"
          />
          <div className="row ms-2">
            <form className="d-flex">
              <input
                className="form-control rounded-pill border-black"
                onChange={(e) => {
                  this.setState({ vendorSearchKey: e.target.value });
                }}
                type="search"
                placeholder="Search for Vendor"
                aria-label="Search"
              />
              <button
                className="btn btn-orange rounded-pill ms-3"
                type="button"
                disabled={!this.state.vendorSearchKey}
                onClick={this.searchVendor}
              >
                <span className="text-decoration-none text-black">Search</span>{" "}
              </button>
            </form>
          </div>
          <button
            className="btn btn-orange css-1s2u09g-control"
            style={{ marginLeft: "4px" }}
            type="button"
            onClick={this.clearSearch}
          >
            Reset
          </button>
        </div>
        <div className="container mt-5">
          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">
                    <p className="max-width-content mb-0">Email</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">Vendor</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">Phone No.</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">Province</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">City</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">ZIP Code</p>
                  </th>
                  <th scope="col">
                    <p className="max-width-content mb-0">VAT No.</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.vendors?.map((el, i) => {
                  return (
                    <tr
                      key={i}
                      className={`${el.role === "admin" ? "d-none" : ""}`}
                    >
                      <td>
                        <p className="max-width-content mb-0">{el?.email}</p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">
                          {el?.name ? el?.name : "-"}
                        </p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">
                          {el?.phoneNumber ? el?.phoneNumber : "-"}
                        </p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">
                          {el?.province ? el?.province : "-"}
                        </p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">
                          {el?.city ? el?.city : "-"}
                        </p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">
                          {el?.zipCode ? el?.zipCode : "-"}
                        </p>
                      </td>
                      <td>
                        <p className="max-width-content mb-0">
                          {el?.vatNumber ? el?.vatNumber : "-"}
                        </p>
                      </td>
                      <td>
                        <button
                          className="btn btn-orange rounded-pill"
                          type="button"
                          onClick={() => {
                            this.getVendorBrands(el?.id ? el?.id : el?.uid);
                          }}
                        >
                          View Brand
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="float-end mt-3">
            <button
              disabled={this.state.activePage === 1}
              className="btn btn-orange rounded-pill"
              type="button"
              onClick={() => {
                this.getData(this.state.activePage - 1, this.updateState);
              }}
            >
              Previous
            </button>
            <button
              disabled={this.state.profileCount / this.state.activePage <= 10}
              className="btn btn-orange rounded-pill"
              style={{ marginLeft: "4px" }}
              type="button"
              onClick={() => {
                this.getData(this.state.activePage + 1, this.updateState);
              }}
            >
              Next
            </button>
          </div>
        </div>
        <Modal centered show={this.state.brandModal} dialogClassName="">
          <Modal.Body>
            <div>
              <IoIosCloseCircleOutline
                className="close-icon position-absolute"
                style={{ right: "15px", top: "15px" }}
                onClick={() => this.alterModalStatus("brandModal", false)}
              />
              <h4>Brands List</h4>
              {this.state.vendorBrandsList.length > 0 ? (
                this.state.vendorBrandsList.map((el, i) => {
                  return <p style={{ fontWeight: "bold" }}>{el.company}</p>;
                })
              ) : (
                <p>No Brands addded </p>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </section>
    );
  }
}
