import React, { Component } from "react";
import vendorService from "../../services/vendor.service";
import { vendorId, withRouter } from "../../utils/common.utils";
import DataNotFound from "../../images/404-data.png";
import DefaultImage from "../../images/no_image.jpg";

const defaultImage = DefaultImage;

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  componentDidMount() {
    this.getData();
    console.log(this.props.router?.searchParams);
  }

  getData = () => {
    this.props.loaderChange(true);
    vendorService
      .getAllProduct(this.props.router?.searchParams)
      .then((res) => {
        console.log(res);
        this.setState({ products: res });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        console.log(err);
        this.props.loaderChange(false);
      });
  };

  toProduct = (i) => {
    this.props.router.navigate(`/product-details/${i}`);
  };
  render() {
    return (
      <div className="container mt-4">
        {this.state.products?.length ? (
          <div className="row">
            {this.state.products.map((el, i) => {
              return (
                <div className="col-lg-2 col-md-4 col-6" key={i}>
                  <div
                    className="card card-width mb-lg-2 border-0"
                    onClick={() => {
                      this.toProduct(el.id);
                    }}
                  >
                    <img
                      src={el?.images[0] ? el?.images[0] : defaultImage}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <span className="text-decoration-none" to="/category">
                        <h5 className="card-title vendor-name text-black">
                          {el?.brand}
                        </h5>
                        <h4 className="card-title vendor-name text-secondary">
                          {el?.label}
                        </h4>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="data-not-found d-flex align-items-center">
            <div className="">
              <img src={DataNotFound} className="w-50" />
              <div className="data-not-found-content d-md-flex d-lg-flex justify-content-center">
                <h1 className="data-not-found-title">Data Not found</h1>
                <button
                  className="btn btn-orange rounded-pill ms-3"
                  onClick={() => {
                    this.props.router.navigate(`/`);
                  }}
                >
                  Go to Homepage
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(Products);
