import { Component } from 'react'
import adminService from '../../services/admin.service';
import commonService from '../../services/common.service';
import { AiOutlineCloseCircle, AiOutlineUpload } from "react-icons/ai";
import { toast } from 'material-react-toastify';

export default class UploadImages extends Component {

    constructor(props) {
        super(props)
        this.state = {
            brandImages: [],
            pramotionalImages: []
        }
    }
    componentDidMount() {
        this.getData()
    }
    getData = () => {
        this.props.loaderChange(true)
        adminService.getAllCategories().then(
            res => {
                this.setState({ brandImages: res.data().brandImages, pramotionalImages: res.data().pramotionalImages })
                this.props.loaderChange(false)
            }
        ).catch(
            err => {
                console.log(err)
                this.props.loaderChange(false)
                const errorCode = err.code;
                const errorMessage = err.message;
                toast.error(errorMessage)
            }
        )
    }
    handleImages = (e, path) => {
        commonService.uploadMultipleImages(e.target.files, this.getUrl, path)
    }
    getUrl = (url, path) => {
        let images = this.state[path]
        for (let img of url) {
            if (!images.includes(img)) {
                images.push(img)
            }

        }
        this.upload(path,images)
        // this.setState({ [path]: images })
    }
    removeImg = (i, path) => {
        let images = this.state[path];
        images.splice(i, 1)
        this.upload(path,images)
    }
    upload =  (path,images) => {
        let sendData = {
            [path]: this.state[path]
        }
        console.log(sendData)
        adminService.updateMasterData(sendData).then(
            res => {
                this.setState({ [path]: images })
                toast.success('Images uploaded')
            }
        ).catch(
            err => {
                console.log(err)
                const errorCode = err.code;
                const errorMessage = err.message;
                toast.error(errorMessage)
            }
        )
    }
    render() {
        return (
            <section className='mx-lg-5'>
                <h4>Upload Images</h4>
                <div className='row mt-lg-5 mt-3'>
                    <div className='col-12'>
                        <h6 className='text-start'>Promotional Images</h6>
                    </div>

                    <div className='col-12 mt-3'>
                        <div className='row'>
                            <div className='col-lg-1 col-md-2 col-3 align-self-center'>
                                <div className="upload-btn-wrapper" style={{cursor:'pointer'}}>
                                    <AiOutlineUpload className='upload-btn' />
                                    <input name="image" multiple type="file" onChange={(e) => { this.handleImages(e, 'pramotionalImages') }} className="" />
                                </div>
                                {/* {
                                    this.state.pramotionalImages?.length ?

                                        <button className='btn btn-outline-secondary img_upload-btn w-100' onClick={() => { this.upload('pramotionalImages') }}>Upload</button>
                                        : null
                                } */}
                            </div>

                            {
                                this.state.pramotionalImages.map((el, i) => {
                                    return (

                                        <div className='col-lg-1 col-md-2 col-3' key={i}>
                                            <div className='border h-100'>
                                                <div className='position-relative h-100'>
                                                    <AiOutlineCloseCircle className='position-absolute top-0 start-100 translate-middle text-secondary' onClick={() => { this.removeImg(i, 'pramotionalImages') }} />
                                                    <img src={el} className='promotional-images h-100' alt='banner-1' />
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
                <div className='row mt-5'>
                    <div className='col-12'>
                        <h6 className='text-start'>Brand Images</h6>
                    </div>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-lg-1 col-md-2 col-3 align-self-center'>
                                <div className="upload-btn-wrapper" style={{cursor:'pointer'}}>
                                    <AiOutlineUpload className='upload-btn' />
                                    <input name="image" multiple type="file" onChange={(e) => { this.handleImages(e, 'brandImages') }} className="" />
                                </div>
                                {/* {
                                    this.state.brandImages?.length ?
                                        <button className='btn btn-outline-secondary img_upload-btn w-100' onClick={() => { this.upload('brandImages') }}>Upload</button>
                                        : null
                                } */}
                            </div>

                            {
                                this.state.brandImages.map((el, i) => {
                                    return (

                                        <div className='col-lg-1 col-md-2 col-3' key={i}>
                                            <div className='border h-100'>
                                            <div className='position-relative h-100'>
                                                <AiOutlineCloseCircle className='position-absolute top-0 start-100 translate-middle text-secondary' onClick={() => { this.removeImg(i, 'brandImages') }} />
                                                <img src={el} className='promotional-images h-100' alt='banner-1' />
                                            </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
