import { Component } from 'react'
import { Link } from 'react-router-dom';
import pageNotFound from '../../images/404.png';

export default class NotFound extends Component {
  render() {
    return (
      <section className='page-not-found' >
          <img src={pageNotFound} className="page-not-found-img" alt="..." />
          <Link className="navbar-brand admin_navbar-brand" to="/admin"><button className='btn btn-orange rounded-pill'>Back to Homepage</button></Link>
      </section>
    )
  }
}
