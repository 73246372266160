import { Component } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { toast } from "material-react-toastify";
import { Link } from "react-router-dom";
import vendorService from "../../services/vendor.service";
import { vendorId, withRouter } from "../../utils/common.utils";
import Confirmation from "../common/Confirmation";
import { Modal } from "react-bootstrap";
import { BsCurrencyEuro } from "react-icons/bs";
import Select from "react-select";
import { withTranslation } from "react-i18next";

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.vendorId = vendorId();
    this.state = {
      catlogs: [],
      allCatlogs: [],
      deleteData: null,
      activePage: 1,
      dataCount: 0,
    };
  }
  componentDidMount() {
    this.getBrands();
  }

  getData = () => {
    this.props.loaderChange(true);
    let id = vendorId();
    vendorService
      .getAllCatlog(id)
      .then((res) => {
        console.log(res);
        let winners = res.slice(0, 10);
        this.setState({
          catlogs: winners,
          allCatlogs: res,
          dataCount: res?.length,
          deleteData: null,
        });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        this.props.loaderChange(false);
        const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  pagination = (page) => {
    console.log(page);
    let winners = this.state.allCatlogs.slice((page - 1) * 10, page * 10);
    this.setState({ activePage: page, catlogs: winners });
  };
  openConfirmation = (id) => {
    let data = {
      id: id,
      msg: "Are you sure you want to delete this Catalogue",
    };
    this.setState({ deleteData: data });
  };
  deleteCatlog = (id) => {
    vendorService
      .deleteCatelog(id)
      .then((res) => {
        console.log(res);
        this.getData();
        toast.success("Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  onEdit = (id) => {
    this.props.router.navigate(`/vendor/inventory-crud?id=${id}`);
    // ('/admin/manufacturer-crud')
  };
  getBrands = async () => {
    this.props.loaderChange(true);
    vendorService
      .getBrands(this.vendorId)
      .then((res) => {
        var brands = res?.data().brands ? res?.data().brands : [];
        console.log(brands);
        brands = brands.filter(function (el) {
          return !el.delivered;
        });
        console.log(res.data().brands);
        // let brands = res.data()?.brands ? res.data()?.brands : [];
        // this.setState({ brands: brands });
        let brandsList = brands.sort();
        let brandsObj = [];
        brandsList.map((el) => {
          let temp = {
            value: el.company,
            label: el.company,
          };
          brandsObj.push(temp);
        });
        this.setState({ brands: brandsObj });
        this.getData();
      })
      .catch((err) => {
        this.props.loaderChange(false);
        console.log(err);
      });
  };
  // getFilteredData = (brand) => {};
  handleBrandChange = (selectedBrandOption) => {
    this.props.loaderChange(true);
    this.setState({ selectedBrandOption });
    let id = vendorId();
    vendorService
      .getBrandCatalog(id, selectedBrandOption.value)
      .then((res) => {
        console.log(res);
        let winners = res.slice(0, 10);
        this.setState({
          catlogs: winners,
          allCatlogs: res,
          dataCount: res?.length,
          deleteData: null,
        });
        this.props.loaderChange(false);
      })
      .catch((err) => {
        this.props.loaderChange(false);
        const errorMessage = err.message;
        toast.error(errorMessage);
      });
  };
  clearSearch = () => {
    this.props.loaderChange(true);
    this.setState({
      selectedBrandOption: null,
    });
    this.getData();
  };
  render() {
    const { selectedBrandOption } = this.state;
    const { t } = this.props;
    return (
      <div className="container">
        <section>
          <div className="my-5">
            <div className="row">
              <div className="col-12">
                <h4>{t("inventory")}</h4>
              </div>

              <div className="col-12 d-flex mt-5 align-items-center justify-content-end">
                <Select
                  value={selectedBrandOption}
                  onChange={this.handleBrandChange}
                  options={this.state.brands}
                  className="filter-home ms-2"
                  placeholder={t("filterByBrand")}
                />
                {selectedBrandOption ? (
                  <button
                    className="btn btn-orange css-1s2u09g-control ms-3"
                    style={{ marginLeft: "4px" }}
                    type="button"
                    onClick={this.clearSearch}
                  >
                    Clear
                  </button>
                ) : null}
                <button className="btn btn-outline-orange float-end rounded-pill ms-3">
                  {" "}
                  <Link
                    to="/vendor/inventory-crud"
                    className="text-decoration-none text-black"
                  >
                    {t("createInventory")}
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div className="">
            <div className="table-responsive">
              {this.state.catlogs?.length ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <p className="max-width-content mb-0">{t("model")}</p>
                      </th>
                      <th scope="col">
                        <p className="max-width-content mb-0">{t("brand")}</p>
                      </th>
                      <th scope="col">
                        <p className="max-width-content mb-0">
                          Macro {t("category")}
                        </p>
                      </th>
                      <th scope="col">
                        <p className="max-width-content mb-0">
                          Sub {t("category")}
                        </p>
                      </th>
                      <th scope="col">
                        <p className="max-width-content mb-0">
                          Mini {t("category")}
                        </p>
                      </th>
                      <th scope="col">
                        <p className="max-width-content mb-0">
                          Micro {t("category")}
                        </p>
                      </th>
                      {/* <th scope="col">
                        <p className="max-width-content mb-0">Primary Color</p>
                      </th> */}
                      <th scope="col">
                        <p className="max-width-content mb-0">Color</p>
                      </th>
                      <th scope="col">
                        <p className="max-width-content mb-0">Wheel Size</p>
                      </th>
                      <th scope="col">
                        <p className="max-width-content mb-0">Frame Size</p>
                      </th>
                      <th scope="col">
                        <p className="max-width-content mb-0">Offered Price</p>
                      </th>
                      <th scope="col">
                        <p className="max-width-content mb-0">Quantity</p>
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.catlogs.map((el, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.label}
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.brand}
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.macroCategory}
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.subCategory}
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.miniCategory ? el?.miniCategory : "-"}
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.microCategory ? el?.microCategory : "-"}
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.secondaryColour
                                ? el?.secondaryColour
                                : el?.secondaryColor
                                ? el?.secondaryColor
                                : "-"}
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.wheelSize
                                ? el?.wheelSize + " inch"
                                : "Unknown"}
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.frameSize ? el?.frameSize + "cm" : "Unknown"}
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.price ? el?.price : "-"} <BsCurrencyEuro />
                            </p>
                          </td>
                          <td>
                            <p className="max-width-content mb-0">
                              {el?.quantity ? el?.quantity : "-"}
                            </p>
                          </td>
                          <td>
                            <div className="d-flex">
                              <MdEdit
                                className="social-icon"
                                onClick={() => {
                                  this.onEdit(el.id);
                                }}
                              />
                              <MdDelete
                                className="social-icon ms-3"
                                onClick={() => {
                                  this.openConfirmation(el.id);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div>Inventory not added yet</div>
              )}
            </div>
            {this.state.catlogs?.length ? (
              <div className="float-end mt-3">
                <button
                  disabled={this.state.activePage === 1}
                  className="btn btn-orange rounded-pill"
                  type="button"
                  onClick={() => {
                    this.pagination(this.state.activePage - 1);
                  }}
                >
                  {t("previous")}
                </button>
                <button
                  disabled={this.state.dataCount / this.state.activePage <= 10}
                  className="btn btn-orange rounded-pill"
                  style={{ marginLeft: "4px" }}
                  type="button"
                  onClick={() => {
                    this.pagination(this.state.activePage + 1);
                  }}
                >
                  {t("next")}
                </button>
              </div>
            ) : null}
          </div>
        </section>
        <Modal centered show={this.state.deleteData} dialogClassName="">
          <Modal.Body>
            <Confirmation
              data={this.state.deleteData}
              delete={this.deleteCatlog}
              close={() => {
                this.setState({ deleteData: null });
              }}
            ></Confirmation>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default withRouter(withTranslation()(Inventory));
