import { Component } from "react";
import authService from "../../services/auth.service";
import { toast } from "material-react-toastify";
import Spinner from "react-bootstrap/Spinner";

export default class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
    };
  }
  componentDidMount() {
    //  this.emailLink()
  }
  emailLink = () => {
    this.setState({ isLoader: true });
    authService
      .sendEmailVerificationLink()
      .then((res) => {
        toast.success("Email verifcation link sent to your email");
        this.setState({ isLoader: false });
      })
      .catch((error) => {
        toast.error(error?.message);
        console.log(error.message + " - " + error.code);
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage);
        this.setState({ isLoader: false });
      });
  };
  render() {
    return (
      <section className="verify-email-page position-absolute w-100 h-100 top-0">
        <div className="h-100 d-flex justify-content-center align-items-center">
          <div className="contact-form-wrapper d-flex justify-content-center">
            <form action="#" className="contact-form">
              <h5 className="title">Email Verifcation</h5>
              <p className="description">
                Email verification link sent to your email, Please go to your
                register email account to get verified.(Please check spam)
              </p>
              <p className="description">
                In case you are not able to get verifiation mail or link is
                expired please click on Resend link button
              </p>
              <div className="submit-button-wrapper">
                <button
                  type="button"
                  onClick={this.emailLink}
                  disabled={this.state.isLoader}
                  className="btn btn-orange d-block m-auto mt-3 rounded-pill"
                >
                  {this.state.isLoader ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </span>
                  ) : (
                    "Resend Link"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}
